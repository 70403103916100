import { stop, stopAll, type StopHandle, taggedLogger } from '@/util';
import { useDebounceFn, watchImmediate } from '@vueuse/core';
import { type CollisionCoverageResults, FaceAreaMetric } from '@/planner/cupCoverage/types';
import {
    BufferGeometry,
    DoubleSide,
    Float32BufferAttribute,
    type Matrix4,
    Mesh,
    MeshBasicMaterial,
} from 'three';
import { flatten, repeat } from 'ramda';
import { AbortCalculationError } from '@/planner/cupCoverage/abortCalculationError';
import type { PlannerNodes } from '@/planner/scene/plannerNodes';
import type { PlannerStore } from '@/planner/plannerStore';
import { useAppErrorStore } from '@/app/appErrorStore';
import { useCupOverlayStore } from '@/planner/cupOverlayStore';
import { asyncWatchImmediateUntil } from '@/util/asyncWatchUntil';
import {
    calculateComponentCoverage,
    cupCoverageStrategy,
} from '@/planner/cupCoverage/ComponentCoverageUtil';
import { applyMatrix4ToGeometry } from '@/formus/geometry/mesh';
import { verify } from '@/lib/verify';
import { errorMessage } from '@/util/errorMessage';
import { setLocalTransform } from '@/planner/3d/object3d';
import { formatElapsedSeconds } from '@/lib/format/formatElapsedSeconds';
import { formatArea, formatFloat } from '@/formus/geometry/formatMath';
import type { MeshNode } from '@/planner/3d/mesh';
import type { GroupNode } from '@/planner/3d/group';

const log = taggedLogger('cup-coverage');

export function updateCupCoverage(store: PlannerStore, nodes: PlannerNodes): StopHandle {
    let calculation: StopHandle | null = null;
    let nextId = 1;

    const recalculate = useDebounceFn(() => {
        if (store.template) {
            const id = nextId++;
            log.info('Start calculation %d', id);

            const aborter = new AbortController();
            calculation = () => aborter.abort(new AbortCalculationError());

            useAppErrorStore().catchErrors(() => _calculateCupCoverage(id, nodes, aborter.signal));
        }
    }, 500);

    return stopAll(
        watchImmediate(
            () => [
                nodes.cupGroup.transform,
                nodes.operativeHemipelvis.geometry,
                nodes.operativeHemipelvis.transform,
                nodes.cupCollisionSurface.geometry,
                nodes.cupCollisionSurface.transform,
            ],
            async () => {
                // Stop the previous calculation
                stop(calculation);
                store.nodes.cupCoverage.geometry = null;
                await recalculate();
            },
            { deep: true },
        ),
        () => stop(calculation),
    );
}

/**
 * Function is called when cup coverage needs to be updated
 *
 * This method uses the component coverage controller to initiate cup coverage update
 */
export async function _calculateCupCoverage(id: number, nodes: PlannerNodes, signal: AbortSignal) {
    const cupOverlayStore = useCupOverlayStore();

    try {
        cupOverlayStore.coverage = 'calculating';

        // Wait until we have the collision surface loaded
        await asyncWatchImmediateUntil(() => nodes.cupCollisionSurface.geometry !== null, {
            signal,
        });

        const cupCollision = _makeCollisionMask(nodes.cupGroup, nodes.cupCollisionSurface);
        const hemiPelvis = _makeHemiPelvis(nodes.operativeHemipelvis);

        const start = new Date().getTime();

        const results = await calculateComponentCoverage(cupCollision, hemiPelvis, signal);

        log.info(
            [
                `Calculation ${id} complete in ${formatElapsedSeconds(start)}:`,
                `${results.faces} of ${results.totalFaces} total faces covered`,
                `${formatArea(results.area, { precision: 2 })} of ` +
                    `${formatArea(results.totalArea, { precision: 2 })} total area covered`,
                `coverage ${formatFloat(100 * results.coverage, { precision: 1 })}%`,
            ].join('\n  '),
        );

        cupOverlayStore.coverage = results.coverage;

        const coverageMesh = _makeCoverageMesh(results);
        coverageMesh.renderOrder = cupCoverageStrategy().coverageMeshRenderOrder;

        // World = cup-group * cup-coverage * geometry-vert
        const cupCoverageTransform = nodes.cupGroup.transform
            .clone()
            .multiply(nodes.cupCoverage.transform);
        nodes.cupCoverage.geometry = applyMatrix4ToGeometry(
            verify(coverageMesh.geometry, 'no cup coverage geometry'),
            cupCoverageTransform.invert(),
        );
    } catch (error: unknown) {
        if (signal.aborted) {
            log.debug('Cup-coverage calculation %d aborted', id);
        } else {
            log.error('Unexpected error during coverage calculation %s', errorMessage(error));
            cupOverlayStore.coverage = null;
            throw error;
        }
    }
}

/**
 * Make the coverage mesh for visualization purposes using the results of the coverage calculation.
 */
function _makeCoverageMesh(calculatedCoverage: CollisionCoverageResults): Mesh {
    const geometry = _makeCoverageGeometry(calculatedCoverage.faceAreaMetrics);
    const material = new MeshBasicMaterial({
        // Defines whether vertex coloring is used
        vertexColors: true,
        transparent: true,
        side: DoubleSide,
    });
    return new Mesh(geometry, material);
}

// Color of a covered face
const INSIDE_FACE_COLOR = [1, 0, 0, 0.7];

/**
 * Make a non indexed buffer geometry using the results of the coverage calculation.
 *
 * Note:
 * 1. The Buffer Geometry is a non-indexed to make it setting the position/color buffer simpler.
 * 2. The implementation is not intended to be performant at this stage. If iterating the faces twice
 * to generate the position and color buffer has a penalty, it can be changed to an accumulation approach
 * instead of a `map`.
 *
 * @param faceAreaMetrics the metrics of the coverage calculation process.
 */
function _makeCoverageGeometry(faceAreaMetrics: FaceAreaMetric[]): BufferGeometry {
    const geometry = new BufferGeometry()
        .setAttribute(
            'position',
            new Float32BufferAttribute(
                faceAreaMetrics.flatMap((metric): number[] =>
                    metric.intersection.inside ? metric.positions.flatMap((p) => p.toArray()) : [],
                ),
                3,
            ),
        )
        .setAttribute(
            'color',
            new Float32BufferAttribute(
                flatten(repeat(INSIDE_FACE_COLOR, faceAreaMetrics.length)),
                4,
            ),
        );
    geometry.computeBoundingSphere();
    geometry.computeVertexNormals();

    return geometry;
}

function _makeMesh(geometry: BufferGeometry, transform: Matrix4): Mesh {
    const result = new Mesh(geometry);
    result.matrixAutoUpdate = false;
    setLocalTransform(result, transform);
    return result;
}

function _makeCollisionMask(cupGroup: GroupNode, cupCollisionSurface: MeshNode): Mesh {
    const geometry = verify(cupCollisionSurface.geometry, 'No collision surface geometry');
    const transform = cupGroup.transform.clone().multiply(cupCollisionSurface.transform);
    return _makeMesh(geometry, transform);
}

function _makeHemiPelvis(operativeHemipelvis: MeshNode): Mesh {
    const geometry = verify(operativeHemipelvis.geometry, 'No hemi pelvis geometry');
    const transform = operativeHemipelvis.transform.clone();
    return _makeMesh(geometry, transform);
}
