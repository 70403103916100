<template>
    <v-layout>
        <app-sidebar>
            <router-link :to="{ name: ROUTES.HOME }">
                <app-link icon="mdi-arrow-left">Back to your Cases</app-link>
            </router-link>
        </app-sidebar>

        <app-main-view>
            <app-form-title>Your Surgical Preferences</app-form-title>
            <app-form-subtitle
                >These preferences will be applied to each new Formus Case you create. You can also
                override any of these settings per-Case after it has been created, and before it has
                been processed.
            </app-form-subtitle>

            <app-main-card v-if="form.state.isReady">
                <app-notification
                    v-if="form.state.message"
                    :title="form.state.message"
                    :variant="form.state.hasError ? 'error' : 'success'"
                />

                <app-form-section-title ref="title">Stem</app-form-section-title>
                <app-form-subtitle> Only one stem system can be selected.</app-form-subtitle>
                <app-form-group label="Preferred System">
                    <app-toggle
                        v-model="form.state.preferredSystem"
                        :error-messages="
                            form.hasError('preferredSystem') ? 'Please select an option' : ''
                        "
                        :options="
                            stemSystems.map((s) => {
                                return {
                                    label: s.label,
                                    value: s.key,
                                };
                            })
                        "
                    >
                    </app-toggle>
                </app-form-group>

                <app-form-group label="Types">
                    <app-chip-group
                        column
                        multiple
                        v-model="form.state.stems"
                        :error-messages="form.hasError('stems') ? 'Please select at least one option' : ''"
                    >
                        <v-chip
                            role="checkbox"
                            class="text-subtitle-1 font-weight-bold"
                            variant="flat"
                            color="primary"
                            v-for="item in availableStems"
                            :value="item.id"
                            :filter="true"
                            :key="item.id"
                            :text="`${item.type} - ${item.offset}`"
                        />
                    </app-chip-group>
                </app-form-group>

                <app-form-section-title>Cup</app-form-section-title>

                <app-form-group label="Inclination Angle" input-id="inclination-angle-input">
                    <app-text-input
                        v-model="form.state.cup.inclinationAngle"
                        suffix="deg"
                        data-test-id="inclination-angle-input"
                        input-id="inclination-angle-input"
                        :error-messages="
                            form.hasError('cupInclinationAngle')
                                ? 'Please provide a number between 0 and 60'
                                : ''
                        "
                    />
                </app-form-group>

                <app-form-group label="Fitting mode">
                    <app-toggle
                        v-model="form.state.cup.fitMethod"
                        :error-messages="
                            form.hasError('cupFittingMode') ? 'Please select an option' : ''
                        "
                        :options="[
                            { label: 'Auto', value: 'acid' },
                            { label: 'Fossa', value: 'fossa' },
                        ]"
                    />
                </app-form-group>

                <app-form-group label="Anteversion mode">
                    <app-toggle
                        v-model="form.state.cup.anteversion"
                        :error-messages="
                            form.hasError('cupAnteversionMode') ? 'Please select an option' : ''
                        "
                        :options="[
                            { label: 'Combined', value: 'auto' },
                            { label: 'Manual', value: 'manual' },
                        ]"
                    />
                </app-form-group>

                <app-form-group
                    label="Anteversion Angle"
                    class="mt-12"
                    input-id="anteversion-angle-input"
                    v-if="form.state.cup.anteversion === 'manual'"
                >
                    <app-text-input
                        suffix="deg"
                        v-model="form.state.cup.anteversionAngle"
                        data-test-id="anteversion-angle-input"
                        input-id="anteversion-angle-input"
                        :error-messages="
                            form.hasError('cupAnteversionAngle')
                                ? 'Please provide a number between -5 and 45'
                                : ''
                        "
                    />
                </app-form-group>

                <app-form-group label="Alignment mode">
                    <app-toggle
                        v-model="form.state.cup.alignment"
                        :error-messages="
                            form.hasError('alignmentMode') ? 'Please select an option' : ''
                        "
                        :options="[
                            { label: 'CT Scanner Coordinates', value: 'none' },
                            { label: 'Anterior Pelvic Plane', value: 'APP' },
                        ]"
                    />
                </app-form-group>
                <app-form-actions>
                    <app-form-action-notification
                        message="You have unsaved changes on this page"
                        v-if="form.hasUnsavedChanges"
                    />
                    <app-button-primary
                        height="55"
                        class="px-8"
                        width="350"
                        :loading="form.state.isSaving"
                        @click="form.save"
                        :disabled="!form.hasUnsavedChanges"
                        >Save your Surgical Preferences
                    </app-button-primary>
                </app-form-actions>
            </app-main-card>
        </app-main-view>
    </v-layout>
</template>

<script setup lang="ts">
    /**
     * Implements software requirements: H1SR-11
     *
     * @link https://formuslabs.youtrack.cloud/issue/H1SR-11/Default-values-for-cases-can-be-defined-in-the-hip-preferences
     */
    import { ROUTES } from '@/router';
    import { usePreferences } from '@/preferences/preferences';
    import { computed } from 'vue';
    import { availableStemSystems } from '@/types/stemSystems';
    import { useDeveloperSettings } from '@/planner/developerSettings';

    const form = usePreferences();
    await form.load();

    const availableStems = computed(() => {
        return form.options.stems.filter(
            (object: any) => object.system === form.state.preferredSystem,
        );
    });

    const stemSystems = computed(() => {
        return availableStemSystems(useDeveloperSettings().showAvenirImplants);
    });
</script>

<style scoped>
    :deep(.v-messages) {
        font-size: 14px;
        margin-top: 10px;
    }
</style>
