<template>
    <app-form-section-title>Hip-Spine Assessment</app-form-section-title>

    <app-form-group
        label="Spinopelvic Tilt"
        input-id="standing-pelvic-tilt"
        optional
        optional-text="if known"
    >
        <app-text-input
            v-model="state.current.standingPelvicTilt"
            input-id="spinopelvic-standing-pelvic-tilt"
            suffix="deg"
            :disabled="caseSettingsStore.isSaving"
            :error-messages="showError('standingPelvicTilt') ? INPUT_FIELD_ERROR_MSG : ''"
        />
    </app-form-group>

    <app-form-group label="Pelvic Femoral Angle" optional optional-text="if known">
        <app-text-input
            v-model="state.current.pelvicFemoralAngle"
            input-id="spinopelvic-pelvic-femoral-angle"
            suffix="deg"
            :disabled="caseSettingsStore.isSaving"
            :error-messages="showError('pelvicFemoralAngle') ? INPUT_FIELD_ERROR_MSG : ''"
        />
    </app-form-group>

    <app-form-group label="Lumbar Lordosis" optional optional-text="if known">
        <app-text-input
            v-model="state.current.lumbarLordosis"
            input-id="spinopelvic-lumbar-lordosis"
            suffix="deg"
            :disabled="caseSettingsStore.isSaving"
            :error-messages="showError('lumbarLordosis') ? INPUT_FIELD_ERROR_MSG : ''"
        />
    </app-form-group>

    <app-form-group label="Sacral Slope" optional optional-text="if known">
        <app-text-input
            v-model="state.current.sacralSlope"
            input-id="spinopelvic-sacral-slope"
            suffix="deg"
            :disabled="caseSettingsStore.isSaving"
            :error-messages="showError('sacralSlope') ? INPUT_FIELD_ERROR_MSG : ''"
        />
    </app-form-group>

    <app-form-group label="Pelvic Tilt" optional optional-text="if known">
        <app-text-input
            v-model="state.current.pelvicTilt"
            input-id="spinopelvic-pelvic-tilt"
            suffix="deg"
            :disabled="caseSettingsStore.isSaving"
            :error-messages="showError('pelvicTilt') ? INPUT_FIELD_ERROR_MSG : ''"
        />
    </app-form-group>
</template>

<script setup lang="ts">
    import { useSpinopelvic } from '@/spinopelvic/store/store';
    import { useCaseSettings } from '@/caseSettings/store/store';

    const INPUT_FIELD_ERROR_MSG = 'Please provide a number (2dp). All values must be entered';

    const caseSettingsStore = useCaseSettings();
    const store = useSpinopelvic();

    const { state } = store;

    function showError(field: string): boolean {
        if (!caseSettingsStore.displayErrors) return false;

        return store.hasFieldError(field);
    }
</script>
