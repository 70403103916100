<template>
    <div class="app-form-actions align-center">
        <slot></slot>
    </div>
</template>

<style scoped>
    .app-form-actions {
        display: flex;
        justify-content: end;
    }
    :slotted(button) {
        margin-left: 20px;
    }
</style>
