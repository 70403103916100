import { defineStore } from 'pinia';
import { computed, reactive, ref, toRef, toRefs } from 'vue';
import {
    type Adjustments,
    type PlannerMode,
    plannerState,
    type PlannerState,
    resetPlannerState,
} from '@/planner/plannerState';
import { initialVisibilityToggles, type VisibilityToggleId } from '@/planner/visibilityToggles';
import { loadCase, reloadTemplate } from '@/planner/loading';
import { assert, taggedLogger } from '@/util';
import type { Url } from '@/formus/types';
import { computeFittedStem, type FittedStem } from '@/planner/fittedStem';
import { anatomicAngles } from '@/formus/anatomy/pelvis/acetabularAngles';
import { toRadians } from '@/formus/anatomy/pelvis/anteversionInclination';
import { useTemplateSyncStore } from '@/planner/template/templateSyncStore';
import { formatLength } from '@/lib/format/formatLength';
import { putTemplate } from '@/api/template/putTemplate';
import { approvePlan } from '@/planner/approvePlan';
import { calculateLegLengthAndOffset } from '@/planner/adjustments';
import { positionalPart } from '@/formus/geometry/matrix';
import { computeFittedCup, type FittedCup } from '@/planner/fittedCup';
import { plannerNodes, type PlannerNodes } from '@/planner/scene/plannerNodes';
import { sceneState, type SceneState } from '@/planner/3d/SceneContext';
import type { PlannerNode } from '@/planner/scene/plannerSceneContext';
import { computeBearingUrl, computeHeadUrl, computeLinerUrl } from '@/planner/componentUrls';
import { crossSectionIds, isCrossSectionId } from '@/planner/scene/crossSection';
import { plannerOperationExecutor } from '@/planner/executeOperation';
import { watchImmediate } from '@vueuse/core';
import { syncTemplate } from '@/planner/template/manualTemplateState';

const log = taggedLogger('planner');

/** The type of the store that represents the 3d-planning-view */
export type PlannerStore = PlannerState & {
    nodes: PlannerNodes;
    sceneState: SceneState<PlannerNode>;
    isLoading: boolean;
    setMode: (mode: PlannerMode) => void;
    toggleVisibility: (id: VisibilityToggleId, on: boolean) => void;
    loadCase: (caseId: number) => Promise<void>;
    run: () => void;
    stop: () => void;
    adjustments: Adjustments | null;
    setTargetAdjustments: (adjustments: Adjustments) => Promise<void>;
    approvePlan: () => Promise<void>;
    fittedStem: FittedStem | null;
    fittedCup: FittedCup | null;
    setStem: (url: Url) => void;
    resetStem: () => void;
    setHead: (url: Url) => void;
    setCup: (url: Url) => void;
    resetCup: () => void;
    setDualMobility: (value: boolean) => void;
};

/** The store that represents the 3D-planning-view */
export const usePlannerStore: () => PlannerStore = defineStore('planner', () => {
    const templateSync = useTemplateSyncStore();

    const state = reactive<PlannerState>(plannerState());
    const nodes = ref<PlannerNodes>(plannerNodes());
    const sceneState_ = ref<SceneState<PlannerNode>>(sceneState());

    const { executeOperation, abortOperation } = plannerOperationExecutor(
        toRef(state, 'currentOperation'),
    );

    watchImmediate(
        () => state.currentOperation,
        (op) => log.info('Current operation: %s', op ?? 'none'),
    );

    watchImmediate(
        () => state._sync && state.currentOperation === null && state.case && state.template,
        (shouldSync) => {
            if (shouldSync) {
                assert(state.case && state.template);
                templateSync.startSync(state.case.manualTemplateUrl, state.template);
            } else {
                templateSync.stopSync();
            }
        },
    );

    const fittedCup = computed<FittedCup | null>(() => computeFittedCup(state));

    const fittedStem = computed<FittedStem | null>(() => computeFittedStem(state));

    const isLoading = computed(() => state.currentOperation === 'load-case');

    return {
        ...toRefs(state),
        nodes,
        sceneState: sceneState_,
        isLoading,
        setMode: (mode: PlannerMode) => {
            log.info('Planner mode set to %s', mode);
            state.plannerMode = mode;
            state.visibility = initialVisibilityToggles(mode);
        },
        toggleVisibility: (id: VisibilityToggleId, on: boolean) => {
            if (isCrossSectionId(id) && on) {
                // Toggling a cross-section on toggles other visible cross-sections off
                const ids = crossSectionIds.filter(
                    (id_) => id_ !== id && state.visibility[id_] === 'on',
                );
                ids.forEach((id_) => (state.visibility[id_] = 'off'));
            }
            state.visibility[id] = on ? 'on' : 'off';
        },
        loadCase: async (caseId: number): Promise<void> => {
            abortOperation();
            await executeOperation('load-case', async (signal) => {
                // Reset state
                resetPlannerState(state);
                nodes.value = plannerNodes();
                sceneState_.value = sceneState();

                await loadCase(state, nodes.value, caseId, { signal });
                state.plannerMode = 'default';
            });
        },
        run: () => state._sync = true,
        stop: () => {
            abortOperation();
            state._sync = false
        },
        adjustments: computed<Adjustments | null>(() => {
            if (
                !fittedCup.value ||
                !fittedStem.value ||
                !state.template ||
                !state.case ||
                isLoading.value
            ) {
                return null;
            }
            return calculateLegLengthAndOffset(
                state.case.operationalSide,
                fittedCup.value.basis,
                positionalPart(nodes.value.cupGroup.transform),
                positionalPart(state.template.stemTransform).applyMatrix4(
                    fittedStem.value.transform,
                ),
            );
        }),
        setTargetAdjustments: async (adjustments: Adjustments): Promise<void> => {
            log.info(
                'Setting target leg-length %s and offset %s',
                formatLength(adjustments.legLength),
                formatLength(adjustments.offset),
            );
            await executeOperation('set-targets', async (signal) => {
                assert(state.case && state.template);
                // update the template with the new adjustments, so template comparison will work
                state.template.targets.legLength = adjustments.legLength;
                state.template.targets.offset = adjustments.offset;
                await putTemplate(
                    state.case.manualTemplateUrl,
                    syncTemplate(state.template, adjustments),
                    { signal },
                );
                await reloadTemplate(state, { signal });
                state.plannerMode = 'stem';
            });
        },
        approvePlan: async (): Promise<void> => {
            log.info('Approving plan %s');
            await executeOperation('approve-plan', async (signal) => {
                await approvePlan(state, { signal });
            });
        },
        fittedStem,
        setStem: (url: Url) => {
            assert(state.template);
            state.template.stemUrl = url;
            state.template.stemTransform.identity();
        },
        resetStem: () => {
            assert(state.template && state.case);
            const autoTemplate = state.case.autoTemplate;
            state.template.stemUrl = autoTemplate.stem;
            state.template.headUrl = autoTemplate.head;
            state.template.stemTransform.identity();
        },
        setHead: (url: Url) => {
            assert(state.template);
            state.template.headUrl = url;
        },
        fittedCup,
        setCup: (url: Url) => {
            assert(state.template && state.catalog);
            const catalogCup = state.catalog.cups.get(url);
            if (!catalogCup) {
                throw Error(`Invalid cup url: ${url}`);
            }
            state.template.cupUrl = url;
            state.template.linerUrl = computeLinerUrl(state);
            state.template.bearingUrl = computeBearingUrl(state);
            state.template.headUrl = computeHeadUrl(state);
        },
        resetCup: () => {
            assert(state.case && state.template);
            const autoTemplate = state.case.autoTemplate;
            state.template.cupUrl = autoTemplate.cup;
            state.template.cupRotation = anatomicAngles(toRadians(autoTemplate.cupRotation));
            state.template.cupOffset = autoTemplate.cupOffset;
            state.template.dualMobility = false;

            state.template.linerUrl = autoTemplate.liner;
            state.template.bearingUrl = null;
            // Keep the head offset while resetting the cup
            state.template.headUrl = computeHeadUrl(state);
        },
        setDualMobility: (value: boolean) => {
            assert(state.template);
            state.template.dualMobility = value;
            state.template.linerUrl = computeLinerUrl(state);
            state.template.headUrl = computeHeadUrl(state);
            state.template.bearingUrl = computeBearingUrl(state);
        },
    };
});
