import { makeDataState } from '@/util/dataState';
import type { DicomStoreState } from '@/scanView/dicomStore/types';
import type { CatStackValue } from '@/scanView/types';

export type CatStackState = {
    axial: CatStackValue;
    coronal: CatStackValue;
    sagittal: CatStackValue;
};
export const initialState: DicomStoreState = {
    catStacks: {} as CatStackState,
    ...makeDataState(),
};
