import {
    type SpinopelvicCalculations, type SpinopelvicMeasurements,
    type SpinopelvicWarnings,
} from '@/spinopelvic/components/types';
import {
    ANTEVERSION_10_DEGREES,
    ANTEVERSION_30_DEGREES,
    INCLINATION_LOWER_LIMIT,
    INCLINATION_UPPER_LIMIT,
    LUMBAR_LORDOSIS_LIMIT,
    PFA_LOWER_LIMIT,
    PFA_UPPER_LIMIT,
    STANDING_PELVIC_TILT_LIMIT,
} from '@/spinopelvic/components/constants';
import {
    anteversionFromAIRI,
    anteversionRotated,
    inclinationRotated,
} from '@/spinopelvic/components/calculations';

export const makeSpinopelvicWarnings = (
    cupInclination: number,
    cupAnteversion: number,
    measurements: SpinopelvicMeasurements,
    calculations: SpinopelvicCalculations,
): SpinopelvicWarnings => {
    const standingPTWarning = measurements.standingPelvicTilt > STANDING_PELVIC_TILT_LIMIT;
    const lumbarLordosisWarning = measurements.lumbarLordosis < LUMBAR_LORDOSIS_LIMIT;
    const pelvicFemoralAngleWarning =
        measurements.pelvicFemoralAngle < PFA_LOWER_LIMIT ||
        measurements.pelvicFemoralAngle > PFA_UPPER_LIMIT;

    const csiLower = anteversionFromAIRI(
        cupInclination,
        calculations.csiLowerBound,
        calculations.pelvicTilt,
    );
    const csiUpper = anteversionFromAIRI(
        cupInclination,
        calculations.csiUpperBound,
        calculations.pelvicTilt,
    );
    const anteversionLower = anteversionRotated(
        cupInclination,
        ANTEVERSION_10_DEGREES,
        calculations.pelvicTilt,
    );
    const anteversionUpper = anteversionRotated(
        cupInclination,
        ANTEVERSION_30_DEGREES,
        calculations.pelvicTilt,
    );
    const inclinationLowerLimit = inclinationRotated(
        INCLINATION_LOWER_LIMIT,
        cupAnteversion,
        calculations.pelvicTilt,
    );
    const inclinationUpperLimit = inclinationRotated(
        INCLINATION_UPPER_LIMIT,
        cupAnteversion,
        calculations.pelvicTilt,
    );

    const anteversionLowerLimit = Math.min(csiLower, anteversionLower);
    const anteversionUpperLimit = Math.min(csiUpper, anteversionUpper);

    const anteversionLowerWarning = cupAnteversion < anteversionLowerLimit;
    const anteversionUpperWarning = cupAnteversion > anteversionUpperLimit;

    const inclinationLowerWarning = cupInclination < inclinationLowerLimit;
    const inclinationUpperWarning = cupInclination > inclinationUpperLimit;

    const hasWarnings =
        standingPTWarning ||
        lumbarLordosisWarning ||
        pelvicFemoralAngleWarning ||
        inclinationUpperWarning ||
        inclinationLowerWarning;
    return {
        // TODO: This could be a computed property in a store
        hasWarnings: hasWarnings,

        standingPTWarning: standingPTWarning,
        lumbarLordosisWarning: lumbarLordosisWarning,
        pelvicFemoralAngleWarning: pelvicFemoralAngleWarning,
        inclinationLowerWarning: inclinationLowerWarning,
        inclinationUpperWarning: inclinationUpperWarning,
        anteversionLowerWarning: anteversionLowerWarning,
        anteversionUpperWarning: anteversionUpperWarning,
        limits: {
            anteversionLowerLimit: anteversionLowerLimit,
            anteversionUpperLimit: anteversionUpperLimit,
            inclinationLowerLimit: inclinationLowerLimit,
            inclinationUpperLimit: inclinationUpperLimit,
        },
    };
};
