import { Vector2, type Vector3 } from 'three';
import { DEFAULT_TOLERANCE } from '@/formus/geometry/approxEquals';
import type { LpsVectors } from '@/formus/anatomy/LPS';
import { angleFromPlane, angleInPlanarProjection } from '@/formus/geometry/angle';
import { areAcute } from '@/formus/geometry/vector3';
import type { AnteversionInclination } from '@/formus/anatomy/pelvis/anteversionInclination';

const acetabularAngleSystems = ['anatomic', 'radiographic'] as const;

/** The system in which acetabular angles are defined */
export type AcetabularAngleSystem = typeof acetabularAngleSystems[number];


/** A set of acetabular angles represented in a particular system */
export type AcetabularAngles<System extends AcetabularAngleSystem = AcetabularAngleSystem> = {
    /** System in which the angles are defined */
    system: System;

    /** Anteversion angle */
    anteversion: number;

    /** Inclination angle */
    inclination: number;
};

/** Create a pair of acetabular angles in anatomic convention */
export function anatomicAngles(angles: AnteversionInclination): AcetabularAngles<'anatomic'> {
    return {
        system: 'anatomic',
        ...angles,
    }
}

/** Convert a pair of acetabular angles to anatomic convention */
export function toAnatomic(angles: AcetabularAngles<'radiographic'>): AcetabularAngles<'anatomic'> {
    const transverse = Math.cos(angles.anteversion) * Math.sin(angles.inclination);
    const superiorInferior = Math.cos(angles.anteversion) * Math.cos(angles.inclination);

    return {
        system: 'anatomic',
        anteversion: Math.atan2(Math.sin(angles.anteversion), transverse),
        inclination: Math.acos(superiorInferior),
    };
}

/** Create a pair of acetabular angles in radiographic convention */
export function radiographicAngles(angles: AnteversionInclination): AcetabularAngles<'radiographic'> {
    return {
        system: 'radiographic',
        ...angles,
    }
}

/** Convert a pair of acetabular angles to radiographic convention */
export function toRadiographic(angles: AcetabularAngles<'anatomic'>): AcetabularAngles<'radiographic'> {
    const transverse = Math.cos(angles.anteversion) * Math.sin(angles.inclination);
    const posteriorAnterior = Math.sin(angles.anteversion) * Math.sin(angles.inclination);
    const superiorInferior = Math.cos(angles.inclination);

    const calculateInclination = (): number => {
        if (
            Math.abs(transverse) > DEFAULT_TOLERANCE ||
            Math.abs(superiorInferior) > DEFAULT_TOLERANCE
        ) {
            return Math.atan2(transverse, superiorInferior);
        } else {
            // Degenerate case: in posterior or anterior direction. Fallback is 90 degrees.
            return Math.PI / 2;
        }
    };

    return {
        system: 'radiographic',
        anteversion:
            Math.sign(posteriorAnterior) *
            Math.acos(new Vector2(transverse, superiorInferior).length()),
        inclination: calculateInclination(),
    };
}

/**
 * Calculate radiographic anteversion angle from an 'acetabular vector'. This is the angle between the
 * 'acetabular vector' and the coronal plane.
 * For both cups and acetabular planes the 'acetabular vector' points laterally (away from the pelvis).
 *
 * Definition of Radiographic angles (Murray 1992):
 * The Radiographic Inclination (RI) is defined as the angle between the longitudinal axis and the acetabular
 *      axis when this is projected onto the coronal plane.
 */
export function radiographicAnteversion(acetabularVector: Vector3, vectors: LpsVectors): number {
    const anterior = vectors.posterior.clone().negate();
    return angleFromPlane(acetabularVector, anterior);
}

/**
 * Calculate radiographic inclination angle from an 'acetabular vector'. This is the angle between the
 * 'acetabular vector' and the inferior axis as projected in the coronal plane.
 * For both cups and acetabular planes the 'acetabular vector' points laterally (away from the pelvis).
 *
 * Definition of Radiographic angles (Murray 1992):
 * The Radiographic Inclination (RI) is defined as the angle between the longitudinal axis and the acetabular
 *      axis when this is projected onto the coronal plane.
 */
export function radiographicInclination(acetabularVector: Vector3, vecs: LpsVectors): number {
    const transverse = areAcute(acetabularVector, vecs.left)
        ? vecs.left
        : vecs.left.clone().negate();
    const inferior = vecs.superior.clone().negate();
    return angleInPlanarProjection(acetabularVector, inferior, transverse, 90);
}
