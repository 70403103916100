<template>
    <v-chip-group
        elevation="0"
        :prepend-icon="prependIcon"
        :disabled="disabled"
        :loading="loading"
        @click="emit('click')"
        :width="width"
        :error-messages="errorMessages"
    >
        <slot></slot>
        <div class="v-input__details w-100" v-if="errorMessages">
            <div class="v-messages opacity-100">
                <div class="v-messages__message text-error">{{ errorMessages }}</div>
            </div>
        </div>
    </v-chip-group>
</template>

<script setup lang="ts">
    /**
     * this is a wrapper around vuetify `V-Chip-group`.
     */

    const emit = defineEmits(['click']);

    withDefaults(
        defineProps<{
            prependIcon?: string;
            disabled?: boolean;
            loading?: boolean;
            width?: string;
            errorMessages?: string;
        }>(),
        {
            color: 'primary',
            disabled: false,
            loading: false,
        },
    );
</script>

<style scoped lang="scss">
    // TODO: use mixing  of text-subtitle-bold
    :deep(.v-chip__content) {
        /* text-subtitle-bold */
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: normal;
    }

    :deep(.v-chip:not(.v-chip--selected)) {
        background-color: rgb(var(--v-theme-secondary)) !important;
    }

    .v-input__details {
        padding-inline: 16px;
    }
</style>
