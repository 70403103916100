import { defineStore } from 'pinia';
import { fetchPlans } from '@/api/plan/request';
import type { LoadedPlanStoreState, PlanStoreState } from '@/planView/store/types';
import { initialState } from '@/planView/store/initialState';
import { downloadPdf } from '@/api/plan/downloadPdf';
import { verify } from '@/lib/verify';
import { downloadModel } from '@/api/plan/downloadModels';
import assert from 'assert';
import { taggedLogger } from '@/util';
import type { ApiPlan, ApiPlans } from '@/api/plan/types';
import { pollPlans } from '@/api/plan/pollPlans';
import { computed, reactive, ref } from 'vue';
import { cloneDeep } from 'lodash';
import { useAppErrorStore } from '@/app/appErrorStore';
import { alignmentMode } from '@/planner/api/alignmentMode';

const log = taggedLogger('plan-store');

function plansState(): PlanStoreState {
    return {
        ...cloneDeep(initialState),
    };
}

export const usePlansStore = defineStore('plans-store', () => {
    const appError = useAppErrorStore();

    const _state = reactive<PlanStoreState>(plansState());

    const isLoaded = computed(() => {
        return _state?.project !== null;
    });

    const caseId = ref<number | null>(null);

    const load = async (id: number) => {
        caseId.value = id;
        _clearState();

        log.info(`Loading plan for case ${caseId.value}...`);

        const data = await fetchPlans(id);
        if (!data) {
            throw new Error('Failed to load plan');
        }
        Object.assign(_state, assignPlansDataToState(data));

        await _startSync(id);
    };

    const _clearState = () => {
        log.info(`Clearing plan state for case ${caseId.value}...`);
        Object.assign(_state, plansState());
    };

    let syncController: AbortController | null = null;

    const _startSync = async (caseId: number) => {
        log.info(`Start syncing plan ${caseId}...`);

        // Cancel previous syncing
        syncController?.abort();
        syncController = new AbortController();
        const signal = syncController.signal;

        appError.catchErrors(() =>
            pollPlans(
                caseId,
                (data: ApiPlans | null) => {
                    if (!data) {
                        throw new Error('Failed to load plan');
                    }
                    Object.assign(_state, assignPlansDataToState(data));
                },
                { signal },
            ),
        );
    };
    const stopSync = () => {
        log.info(`Stop syncing plan ${caseId.value}...`);
        syncController?.abort();
    };

    const downloadAutomatedPlan = async () => {
        const report = verify(_state.automatedPlan?.report, 'automated report');
        await downloadPdf(report.href, report.name);
    };

    const downloadManualPlan = async () => {
        const report = verify(_state.manualPlan?.report, 'manual report');
        await downloadPdf(report.href, report.name);
    };

    // Return the state, getters, and actions
    return {
        state: _state as LoadedPlanStoreState,
        isLoaded,
        load,
        stopSync,
        downloadAutomatedPlan,
        downloadManualPlan,
        downloadModels,
        alignmentMode: computed(() => {
            const cupAlignMode = _state.specifications?.cup_align_mode;
            if (cupAlignMode) {
                return alignmentMode(cupAlignMode);
            }

            return null;
        }),
    };
});

export function assignPlansDataToState(data: ApiPlans): LoadedPlanStoreState {
    return {
        project: verify(data.case, 'no case'),
        study: verify(data.study, 'no study'),
        specifications: verify(data.specifications, 'no specifications'),
        automatedPlan: data.automated_plan ?? null,
        manualPlan: data.manual_plan ?? null,
    };
}

/**
 * Note: the files are available for download only by the super-admin, admin and organization admin,
 * so it is likely that the files collection will be empty for the common users.
 */
const downloadModels = async (plan: ApiPlan) => {
    try {
        if (plan.files.length) {
            await downloadModel(plan.files, `Formus Case ${plan.reference_number} 3D Models.zip`);
        } else {
            log.error('Case not available for download');
        }
    } catch (err: unknown) {
        assert.ok(err instanceof Error);
        // TODO: provide the error information back to the user
        log.error('Failed to download 3D models: %s', err.message);
        // Bugsnag.notify(err);
    }
};
