import { defineStore } from 'pinia';
import { isLogLevel, type LogLevel, logLevels, taggedLogger } from '@/util';
import { type Ref, ref, watch } from 'vue';

const log = taggedLogger('developer-settings');

// Identifier strings for various cookies
const VALIDATION_LOG_LEVEL_ID = 'validation-logging' as const;
const SHOW_DEVELOPER_TOOLS_ID = 'show-developer-tools' as const;
const SHOW_3D_FEATURES_ID = 'show-3d-features' as const;
const ANIMATE_CAMERAS_ID = 'animate-camera' as const;
const SUPPRESS_RELOAD_ERRORS = 'suppress-reload-errors' as const;
const HIGHLIGHT_OUTLINES = 'highlight-outlines' as const;
const SHOW_AVENIR_IMPLANTS = 'show-avenir-implants' as const;

/** Developer-facing settings, for validation and debugging etc */
export const useDeveloperSettings = defineStore('developer-settings', () => {
    const allSettings: Setting[] = [];

    const addBooleanSetting = (id: string): BooleanSetting => {
        const setting = booleanSetting(id);
        allSettings.push(setting);
        return setting;
    };

    const addLogLevelSetting = (id: string): LogLevelSetting => {
        const setting = logLevelSetting(id);
        allSettings.push(setting);
        return setting;
    };

    return {
        showDeveloperTools: true || addBooleanSetting(SHOW_DEVELOPER_TOOLS_ID).ref,
        show3dFeatures: addBooleanSetting(SHOW_3D_FEATURES_ID).ref,
        animateCameras: addBooleanSetting(ANIMATE_CAMERAS_ID).ref,
        validationLogLevel: addLogLevelSetting(VALIDATION_LOG_LEVEL_ID).ref,
        suppressReloadErrors: addBooleanSetting(SUPPRESS_RELOAD_ERRORS).ref,
        highlightOutlines: addBooleanSetting(HIGHLIGHT_OUTLINES).ref,
        // showAvenirImplants: addBooleanSetting(SHOW_AVENIR_IMPLANTS).ref,
        // TODO: Properly remove this feature flag after v1.19 release.
        //  Kept here in case ZB changes their mind on Avenir commercialisation in v1.19 last minute
        showAvenirImplants: true,

        /** Reload settings from cookies  */
        load: () => {
            allSettings.forEach(loadSetting);
        },
    };
});

type Setting = BooleanSetting | LogLevelSetting;

function loadSetting(setting: Setting) {
    let value;
    switch (setting.type) {
        case 'boolean':
            value = loadBooleanFromCookie(setting.id);
            break;
        case 'log-level':
            value = loadLogLevelFromCookie(setting.id);
            break;
    }

    log.info(`Loaded setting: ${setting.id}=${value}`);
    setting.ref.value = value;
}

// -------------------------------------------------------------------------------------------------
// Boolean setting

type BooleanSetting = {
    type: 'boolean';
    id: string;
    ref: Ref<boolean | undefined>;
};

function booleanSetting(id: string): BooleanSetting {
    const setting: BooleanSetting = {
        type: 'boolean',
        id,
        ref: ref<boolean | undefined>(undefined),
    };
    loadSetting(setting);
    watch(setting.ref, (value) => {
        if (value !== undefined) {
            if (typeof (value as unknown) !== 'boolean') {
                setting.ref.value = !!(value as unknown);
            } else {
                saveBooleanToCookie(id, value);
            }
        }
    });
    return setting;
}

function loadBooleanFromCookie(segmentId: string): boolean | undefined {
    const match = findCookie(segmentId);
    if (match) {
        if (match.value === 'true') {
            return true;
        } else if (match.value === 'false') {
            return false;
        } else {
            log.warning(
                "Found cookie-segment '%s' - expected explicit value 'true' or 'false'",
                match.segment,
            );
        }
    }
}

function saveBooleanToCookie(segmentId: string, value: boolean) {
    setCookie(segmentId, value ? 'true' : 'false');
}

// -------------------------------------------------------------------------------------------------
// Log-level setting

type LogLevelSetting = {
    type: 'log-level';
    id: string;
    ref: Ref<LogLevel | undefined>;
};

function logLevelSetting(id: string): LogLevelSetting {
    const setting: LogLevelSetting = {
        type: 'log-level',
        id,
        ref: ref<LogLevel | undefined>(undefined),
    };
    loadSetting(setting);
    watch(setting.ref, (value, previousValue) => {
        if (value !== undefined) {
            if (isLogLevel(value)) {
                saveLogLevelToCookie(VALIDATION_LOG_LEVEL_ID, value);
            } else {
                log.warning("Attempting to set invalid validation log-level '%s'", value);
                setting.ref.value = previousValue;
            }
        }
    });
    return setting;
}

function loadLogLevelFromCookie(segmentId: string): LogLevel | undefined {
    const match = findCookie(segmentId);
    if (match) {
        const { segment, value } = match;
        if (isLogLevel(value)) {
            return value;
        } else {
            log.warning(
                "Found cookie-segment '%s' - unknown log-level '%s' (expected one of %s)",
                segment,
                value,
                logLevels.map((level) => `'${level}'`).join(', '),
            );
        }
    }
}

function saveLogLevelToCookie(segmentId: string, level: LogLevel) {
    if (isLogLevel(level)) {
        setCookie(segmentId, level);
    } else {
        log.warning(
            "Ignoring attempt to set %s log-level to '%s' (expected one of %s)",
            segmentId,
            level,
            logLevels.map((level) => `'${level}'`).join(', '),
        );
    }
}

// -------------------------------------------------------------------------------------------------
// Cookie loading and saving

const DEFAULT_COOKIE_EXPIRY = 7 * 24 * 60 * 60 * 1000; // 1 week in milliseconds

function setCookie(name: string, value: string, expiryDate?: Date) {
    if (expiryDate === undefined) {
        expiryDate = new Date();
        expiryDate.setTime(expiryDate.getTime() + DEFAULT_COOKIE_EXPIRY);
    }
    document.cookie = `${name}=${value};expires=${expiryDate.toUTCString()};path=/`;
}

type MatchingCookie = {
    segment: string;
    value: string;
};

function findCookie(segmentId: string): MatchingCookie | undefined {
    const matcher = new RegExp(`${segmentId}=(.*)`);
    for (const segment of document.cookie.split(';')) {
        const match = segment.match(matcher);
        if (match) {
            return { segment, value: match[1] };
        }
    }
}
