import { watch } from 'vue';
import {
    objectNode,
    type ObjectNode, updateObject,
} from '@/planner/3d/object';
import { ArrowHelper, type ColorRepresentation, Object3D, type Vector3 } from 'three';
import { stop, stopAll, type StopHandle } from '@/util';
import { vector3, zero3 } from '@/formus/geometry/vector3';
import { watchImmediate } from '@vueuse/core';
import type { SceneContext } from '@/planner/3d/SceneContext';
import type { NodeId } from '@/planner/3d/nodeId';

export type AxisNode = ObjectNode<'axis'> & {
    direction: Vector3;
    length: number;
    color: ColorRepresentation;
    headLength: number;
    headWidth: number;
};

export function axisNode(id: NodeId, properties?: Partial<AxisNode>): AxisNode {
    return {
        ...objectNode('axis', id, properties),
        direction: properties?.direction ?? vector3({ y: 1 }),
        length: properties?.length ?? 20.0,
        color: properties?.color ?? 'white',
        headLength: properties?.headLength ?? 3.0,
        headWidth: properties?.headWidth ?? 2.0,
    };
}

export function updateAxis(context: SceneContext, node: AxisNode): StopHandle {
    let update: StopHandle | null = null;
    const object = new Object3D();
    return stopAll(
        updateObject(context, node, object),
        watchImmediate(
            () => [node.headLength, node.headWidth],
            () => {
                stop(update);
                const arrow = new ArrowHelper(
                    node.direction,
                    zero3(),
                    node.length,
                    node.color,
                    node.headLength,
                    node.headWidth,
                );
                object.add(arrow);
                update = stopAll(
                    watch(() => node.direction, (direction) => arrow.setDirection(direction), { deep: true }),
                    watch(() => node.length, (length) => arrow.setLength(length)),
                    watch(() => node.color, (color) => arrow.setColor(color), { deep: true }),
                    () => {
                        object.remove(arrow);
                        arrow.dispose();
                    }
                );
            }),
        () => stop(update),
    );
}
