<template>
    <v-btn
        elevation="0"
        :height="height"
        class="align-center"
        :color="color"
        rounded="pill"
        :bg-color="bgColor"
        :prepend-icon="prependIcon"
        :disabled="disabled"
        :loading="loading"
        @click="emit('click')"
        :width="width"
    >
        <slot></slot>
    </v-btn>
</template>

<script setup lang="ts">
    /**
     * this is a wrapper around vuetify `VBtn`.
     *
     * @see https://vuetifyjs.com/en/api/v-btn/ for list of available props
     */

    const emit = defineEmits(['click']);

    withDefaults(
        defineProps<{
            color?: string;
            bgColor?: string;
            prependIcon?: string;
            disabled?: boolean;
            loading?: boolean;
            width?: string;
            height?: string;
        }>(),
        {
            color: 'primary',
            disabled: false,
            loading: false,
            width: '244px',
            height: '48px',
        },
    );
</script>

<style scoped lang="scss">
    .v-btn {
         text-transform: none;
    }

    // TODO: use mixing  of text-subtitle-bold
    .v-btn :deep(.v-btn__content) {
        /* text-subtitle-bold */
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: normal;
    }

    /* Target elements with the Vuetify green color and apply hover effect */
    .bg-green:hover {
        border: 1px solid rgb(var(--v-theme-green)) !important;
        background-color: white !important;
        color: rgb(var(--v-theme-green))  !important;
    }

    .bg-primary:hover {
        border: 1px solid rgb(var(--v-theme-primary));
        background-color: white !important;
        color: rgb(var(--v-theme-primary)) !important;
    }

    .v-btn.v-btn--variant-outlined {
        background-color: white !important;
        color: rgb(var(--v-theme-primary)) !important;

        &:hover {
            border: 1px solid rgb(var(--v-theme-secondary)) !important;
            background-color: rgb(var(--v-theme-secondary)) !important;
            color: white !important;
        }
    }

    //.bg-secondary:hover {
    //    border: 1px solid rgb(var(--v-theme-secondary));
    //    background-color: white !important;
    //    color: rgb(var(--v-theme-secondary)) !important;
    //}
</style>
