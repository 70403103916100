<template>
    <v-main class="app-main-view">
        <v-container fluid class="pa-0">
            <slot></slot>
        </v-container>
    </v-main>
</template>

<style scoped lang="scss">
    .app-main-view {
        margin-top: 2.5rem;

        //push the main content to the right to leave space for the sticky sidebar
        --v-layout-left: 260px !important;
    }
</style>
