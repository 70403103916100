<template>
    <app-modal v-model="isOpen" persistent width="600">
        <v-card>
            <app-modal-title @close="isOpen = false">
                You will be logged out in {{ nicelyFormattedCountdown }}
            </app-modal-title>

            <app-modal-content>
                <p>
                    In order to ensure you and your patients&rsquo; security, you will be
                    automatically logged out after 60 minutes of inactivity. Click
                    <b>&lsquo;I&rsquo;m back&rsquo;</b> below to stay logged in.
                </p>
            </app-modal-content>

            <app-modal-actions>
                <app-button-primary :disabled="hasCountdownFinished" @click="onClick">
                    I'm back
                </app-button-primary>
            </app-modal-actions>
        </v-card>
    </app-modal>
</template>

<script setup lang="ts">
    import { ref, computed } from 'vue';
    import { useIdleTimeout } from '@/app/idleTimeout';
    import { Duration } from 'luxon';

    const idleTimeout = useIdleTimeout();

    const isUserBack = ref(false);

    const isOpen = computed(
        () => !isUserBack.value && idleTimeout.showWarning && !idleTimeout.hasTimedOut,
    );

    const hasCountdownFinished = computed(() => idleTimeout.countdown <= 1);

    const nicelyFormattedCountdown = computed(() => {
        return Duration.fromMillis((idleTimeout.countdown ?? 0) * 1000).toFormat('mm:ss');
    });

    const onClick = () => {
        /**
         * close the modal immediately.
         */
        isUserBack.value = true;
        setTimeout(() => {
            isUserBack.value = false;
        }, 1000);
        idleTimeout.reset();
    };
</script>
