import type { NumberArray3 } from '@/formus/geometry/apiVector';
import type { BoneId } from '@/formus/anatomy/bone';
import { type BodySide } from '@/formus/anatomy/side';

export type AngleAxis = {
    name?: string;
    value?: NumberArray3;
};

export type AngleMeasurement = {
    type: 'angle';
    name: string;

    /** The angle in degrees between axes[0] and axes[1] */
    value: number;

    /**
     *
     * The pair of axes to measure the angles
     *
     * NOTE: By convention the 1st axis in the tuple is the 'reference' axis
     *       and the 2nd one is the 'target' axis,
     *       where the angle measures from the 'reference' to the 'target'
     */
    axes?: [AngleAxis, AngleAxis];

    /**
     * An optional origin point for the two axis unit vectors
     */
    position?: NumberArray3;
};

export type AxisMeasurement = {
    type: 'axis';
    name: string;

    /** A directional vector */
    value: NumberArray3;

    /** The origin (starting position) of the vector */
    position: NumberArray3;
};

export type DistanceMeasurement = {
    type: 'distance';
    name: string;

    /** A directional vector */
    value: number;

    /** The origin (starting position) of the vector */
    /** At least 2 points. 2 or more points can be listed */
    points: NumberArray3[];
};

export type PlaneMeasurement = {
    type: 'plane';
    name: string;

    /** The 'z' normal (axis) */
    value: NumberArray3;

    /** The 'x' normal (axis) */
    x: NumberArray3;

    /** The 'y' normal (axis) */
    y: NumberArray3;

    /** The logical centre of the plan */
    origin: NumberArray3;
};

export type RadialMeasurement = {
    type: 'radial';
    name: string;

    /** The distance (linear measurement) in milli-metres (mm). */
    value: number;

    position: NumberArray3;
};

export type Measurement =
    | AngleMeasurement
    | AxisMeasurement
    | DistanceMeasurement
    | PlaneMeasurement
    | RadialMeasurement;

/**
 * Hip study measurement groups
 */
const measurementGroupNames = [
    'left-femur',
    'right-femur',
    'left-hemi',
    'right-hemi',
    'pelvis',
] as const;

export type MeasurementGroupName = (typeof measurementGroupNames)[number];

export type MeasurementGroupId = MeasurementGroupName | { bone: BoneId; side: BodySide };

export type MeasurementGroup = {
    /** A text identifier for the measurements. */
    name: MeasurementGroupName;

    /**
     * The URI of (link) of the one or more 3d models involved in the measurements.
     * The links must have an 'href', they should have a 'title', but they should not have 'rel'. The rel is
     * implicitly a 'model'.
     *
     * Normally only one 3d model will be referenced, however in the case of a pelvis this would refer
     * to both hemi-pelvis.
     */
    // TODO: do we ever use this?
    // models: Link[];

    values: Measurement[];

    groups: MeasurementGroup[] | null;
};

export function findMeasurement(
    groups: MeasurementGroup[],
    groupId: MeasurementGroupId,
    measurementName: string,
): Measurement {
    const group = findMeasurementGroup(groups, groupId);
    const matching = group.values.filter((m) => m.name === measurementName);
    if (matching.length == 1) {
        return matching[0];
    } else if (matching.length == 0) {
        throw Error(
            [
                `No measurement matched '${measurementName}' - available measurements are:`,
                ...group.values.map((m) => `'${m.name}'`),
            ].join('  \n'),
        );
    } else {
        throw Error(
            [
                `Multiple measurements matched '${measurementName}' - matching measurements are:`,
                ...matching.map((m) => `'${m.name}'`),
            ].join('  \n'),
        );
    }
}

export function findAngleMeasurement(
    groups: MeasurementGroup[],
    groupId: MeasurementGroupId,
    measurementName: string,
): AngleMeasurement {
    return findTypedMeasurement('angle', groups, groupId, measurementName);
}

export function findAxisMeasurement(
    groups: MeasurementGroup[],
    groupId: MeasurementGroupId,
    measurementName: string,
): AxisMeasurement {
    return findTypedMeasurement('axis', groups, groupId, measurementName);
}

export function findDistanceMeasurement(
    groups: MeasurementGroup[],
    groupId: MeasurementGroupId,
    measurementName: string,
): DistanceMeasurement {
    return findTypedMeasurement('distance', groups, groupId, measurementName);
}

export function findPlaneMeasurement(
    groups: MeasurementGroup[],
    groupId: MeasurementGroupId,
    measurementName: string,
): PlaneMeasurement {
    return findTypedMeasurement('plane', groups, groupId, measurementName);
}

export function findRadialMeasurement(
    groups: MeasurementGroup[],
    groupId: MeasurementGroupId,
    measurementName: string,
): RadialMeasurement {
    return findTypedMeasurement('radial', groups, groupId, measurementName);
}

function findMeasurementGroup(
    groups: MeasurementGroup[],
    groupId: MeasurementGroupId,
): MeasurementGroup {
    const name = asMeasurementGroupName(groupId);
    const matching = groups.filter((m) => m.name == name);
    if (matching.length == 1) {
        return matching[0];
    } else if (matching.length == 0) {
        throw Error(
            [
                `No measurement-groups matched '${name}' - available groups are:`,
                ...groups.map((m) => `'${m.name}'`),
            ].join('  \n'),
        );
    } else {
        throw Error(
            [
                `Multiple measurement-groups matched '${name}' - matching groups are:`,
                ...matching.map((m) => `'${m.name}'`),
            ].join('  \n'),
        );
    }
}

function findTypedMeasurement<T extends Measurement>(
    type: T['type'],
    groups: MeasurementGroup[],
    groupId: MeasurementGroupId,
    measurementName: string,
): T {
    const result = findMeasurement(groups, groupId, measurementName);
    if (isMeasurementType<T>(type, result)) {
        return result;
    } else {
        throw Error(
            `Measurement ${measurementName} in ${groupId} was an unexpected type. ` +
                `Expected '${type}' but got '${result.type}'`,
        );
    }
}

function asMeasurementGroupName(value: MeasurementGroupId): MeasurementGroupName {
    if (typeof value === 'string') {
        if (measurementGroupNames.includes(value as MeasurementGroupName)) {
            return value;
        } else {
            throw Error(`Invalid measurement-group name ${name}`);
        }
    } else {
        const { bone, side } = value;
        switch (bone) {
            case 'femur':
                return side === 'left' ? 'left-femur' : 'right-femur';
            case 'hemipelvis':
                return side === 'left' ? 'left-hemi' : 'right-hemi';
            default:
                throw Error(`No measurement group corresponds to bone '${bone}'`);
        }
    }
}

function isMeasurementType<T extends Measurement>(type: T['type'], value: Measurement): value is T {
    return value.type === type;
}
