<template>
    <div class="drag-dicom-container" v-bind="dropzone.getRootProps()">
        <input v-bind="dropzone.getInputProps()" />

        <v-icon icon="mdi-cloud-upload"></v-icon>

        <div v-if="upload.hasScan" class="override-notice">
            <app-text class="mb-0">Overwrite existing CT Scan?</app-text>
            <app-text class="mb-0">Drag a new CT Scan (DICOM files) here.</app-text>
            <app-text class="mb-0"><span class="caution">Please Note:</span></app-text>
            <app-text class="mb-0"
                >This will remove the existing scan and reprocess this Case.
            </app-text>
        </div>
        <app-text v-else class="mb-0">
            Upload a CT Scan by dragging your DICOM files here
        </app-text>

        <AppUploadPatientInconsistentIdentity />
        <AppUploadDicomMismatch />
        <AppUploadProgress />
    </div>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import { useDropzone } from 'vue3-dropzone';
    import { useCaseUpload } from '@/caseSettings/case-upload/store';
    import AppUploadDicomMismatch from '@/caseSettings/AppUploadDicomMismatch.vue';
    import AppUploadProgress from '@/caseSettings/AppUploadProgress.vue';
    import AppUploadPatientInconsistentIdentity from '@/caseSettings/AppUploadPatientInconsistentIdentity.vue';

    const upload = useCaseUpload();

    const mouseOver = ref(false);
    const toggleMouseOver = (on: boolean) => () => {
        mouseOver.value = on;
        return true;
    };
    const colour = computed(() =>
        mouseOver.value ? 'var(--planner-blue-button)' : 'var(--planner-grey-2)',
    );

    const dropzone = useDropzone({
        multiple: true,
        onDrop: upload.onUploadFile,
        onDragEnter: toggleMouseOver(true),
        onDragLeave: toggleMouseOver(false),
        onDropAccepted: toggleMouseOver(false),
        onDropRejected: toggleMouseOver(false),
    });
</script>

<style scoped>
    :deep(i.v-icon) {
        color: var(--planner-grey);
    }

    .drag-dicom-container {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 40px;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        background-color: var(--planner-grey-body);
        border-width: 2px;
        border-style: dashed;
        border-color: v-bind('colour');
        font-size: 18px;
        transition: border 200ms;
    }

    .override-notice {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .caution {
        font-weight: bold;
        color: var(--planner-red);
    }
</style>
