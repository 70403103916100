import type { CaseSettingsInputs } from '@/caseSettings/store/types';
import type {
    CaseSettingsApiObjectCreate,
    CaseSettingsOptions,
    OptionsApiResponse,
} from '@/api/case/types';
import { mapToApiObject } from '@/api/case/mapToApiObject';
import {
    createCaseSettings,
    getCaseSettings,
    getCaseSettingsOptions,
    updateCaseSettings,
} from '@/api/case/request';
import { verify } from '@/lib/verify';
import { mapToState } from '@/caseSettings/store/mapToState';
import { ById, ByName } from '@/lib/sorting';
import { getPreferences, NoSurgicalPreferencesError, type THAPreferences } from '@/api/preferences';

export async function loadCaseSettings(id: number): Promise<CaseSettingsInputs> {
    const options = await loadCaseSettingsOptions();
    const caseSettings = await getCaseSettings(id);

    return mapToState(caseSettings, options);
}

export async function saveCaseSettings(
    isNewCase: boolean,
    caseSettings: CaseSettingsInputs,
    webComponentVersion: string,
): Promise<number> {
    const body: CaseSettingsApiObjectCreate = mapToApiObject(caseSettings, webComponentVersion);

    if (isNewCase) {
        return await createCaseSettings(body);
    } else {
        const caseId = verify(caseSettings.id, 'case id is required');
        await updateCaseSettings(caseId, body);
        return caseId;
    }
}

export function mapOptionsToState(data: OptionsApiResponse) {
    return {
        stems: [...data.options.stems].sort(ById),
        surgeons: [...data.options.surgeons].sort(ByName),
    };
}

export async function loadCaseSettingsOptions(): Promise<CaseSettingsOptions> {
    const data = await getCaseSettingsOptions();
    return mapOptionsToState(data);
}

export async function getSurgeonPreferences(surgeonId: number): Promise<THAPreferences | null> {
    try {
        return await getPreferences(surgeonId);
    } catch (err) {
        if (err instanceof NoSurgicalPreferencesError) {
            return null;
        } else {
            throw err;
        }
    }
}
