<template>
    <aside>
        <div>
            <slot></slot>
        </div>

        <div class="aside-footer">
            <nav>
                <router-link class="text-caption mb-1" :to="{ name: ROUTES.ABOUT }">About</router-link>
                <router-link class="text-caption mb-1" :to="{ name: ROUTES.TERMS }">Terms of Use</router-link>
                <router-link class="text-caption mb-1" :to="{ name: ROUTES.PRIVACY }">Privacy</router-link>
            </nav>

            <div class="bg-surface-light pa-4 mr-6 version-container">
                <span class="text-caption mb-4">
                    Copyright © <br />
                    Formus Labs 2019-2021
                </span>

                <span class="text-caption">{{ versionStore.versionWithPrefix }}</span>
            </div>
        </div>
    </aside>
</template>
<script setup lang="ts">
    import { ROUTES } from '@/router';
    import { useVersion } from '@/app/version';

    const versionStore = useVersion();
    await versionStore.load();
</script>

<style scoped lang="scss">
    aside {
        --header-height: 80px;
        --margin-top: 2.5rem;
        --margin-bottom: 2.5rem;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 2.5rem 0;
        margin-left: 24px !important;
        margin-right: 16px !important;
        min-width: 200px;
        border-right: 1px solid var(--planner-grey-light);
        position: fixed; /* Make the sidebar sticky */
        height: calc(
            100vh - var(--header-height) - var(--margin-top) - var(--margin-bottom)
        ); /* Take full viewport height - header height - margin top - margin bottom */
        overflow: hidden; /* Prevent content overflow */
    }

    .aside-footer {
        margin-top: auto; /* Push footer to the bottom */
        padding-bottom: 0;
    }

    .aside-footer * {
        display: block;
    }

    .version-container {
        border-radius: 6px;
    }

    span {
        color: rgb(var(--v-theme-secondary-text));
    }

    a {
        color: var(--planner-blue-button);
    }

    nav {
        padding: 20px 0;
    }
</style>
