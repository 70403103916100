<template>
    <div class="pa-4">
        <h1>Theme</h1>
        <v-select label="Theme" :items="selectableThemes" v-model="selectedTheme" />
        <v-expansion-panels>
            <v-expansion-panel title="colors">
                <v-expansion-panel-text>
                    <div class="d-flex flex-wrap">
                        <v-card
                            v-for="color in themeColors"
                            :key="color[0]"
                            :title="color[0]"
                            :subtitle="color[1]"
                            :color="color[1]"
                            rounded="lg"
                            class="text-center ma-2"
                            style="width: 200px; flex-grow: 1"
                        />
                    </div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
        <h1>Components</h1>
        <v-checkbox label="Show frames" v-model="showFrame" />
        <v-tabs v-model="componentTab">
            <v-tab key="containment" style="text-transform: none">Containment</v-tab>
            <v-tab key="forms" style="text-transform: none">Forms</v-tab>
            <v-tab key="selection" style="text-transform: none">Selection</v-tab>
            <v-tab key="typography" style="text-transform: none">Typography</v-tab>
            <v-tab key="icons" style="text-transform: none">Icons</v-tab>
        </v-tabs>
        <v-window v-model="componentTab">
            <v-window-item key="containment">
                <div class="d-flex flex-column align-center">
                    <h2>v-btn</h2>
                    <div class="d-flex">
                        <div class="component-frame">
                            <v-btn>color=default</v-btn>
                        </div>
                        <div class="component-frame">
                            <v-btn color="primary">color=primary</v-btn>
                        </div>
                        <div class="component-frame">
                            <v-btn color="secondary" class="text-subtitle-bold"
                                >color=secondary
                            </v-btn>
                        </div>
                    </div>
                    <h2>custom-btn</h2>
                    <div class="d-flex">
                        <div class="component-frame">
                            <custom-btn>Default Button</custom-btn>
                        </div>
                        <div class="component-frame">
                            <custom-btn color="primary">Primary Button</custom-btn>
                        </div>
                        <div class="component-frame">
                            <custom-btn color="secondary">Secondary Button</custom-btn>
                        </div>
                    </div>
                    <h2>Aliases</h2>
                    <div class="d-flex">
                        <div class="component-frame">
                            <v-btn-secondary height="40" class="px-1"
                                >Secondary Button
                            </v-btn-secondary>
                        </div>
                        <div class="component-frame">
                            <v-btn-primary color="primary">Primary Button</v-btn-primary>
                        </div>
                    </div>
                    <h2>app-button-*****</h2>
                    <div class="d-flex">
                        <div class="component-frame">
                            <app-button color="primary">color=Primary</app-button>
                        </div>
                        <div class="component-frame">
                            <app-button-primary>app-button-primary</app-button-primary>
                        </div>
                        <div class="component-frame">
                            <app-button color="secondary" class="w-100">
                                color=secondary Button is not app-button-secondary
                            </app-button>
                        </div>
                        <div class="component-frame">
                            <app-button-secondary>app-button-secondary</app-button-secondary>
                        </div>
                        <div class="component-frame">
                            <app-button-secondary class="w-100" height="100"
                                >app-button-secondary with overriding properties
                            </app-button-secondary>
                        </div>
                        <div class="component-frame">
                            <app-button-cta>Green Button</app-button-cta>
                        </div>
                    </div>
                    <v-divider class="align-self-stretch" />
                    <h2>v-chip-group</h2>
                    <div class="component-frame">
                        <v-chip-group>
                            <v-chip>Chip 1</v-chip>
                            <v-chip>Chip 2</v-chip>
                            <v-chip>Chip 3</v-chip>
                        </v-chip-group>
                    </div>
                    <v-divider class="align-self-stretch" />
                    <h2>v-divider</h2>
                    <div class="component-frame">
                        <v-divider style="width: 300px" />
                    </div>
                    <v-divider class="align-self-stretch" />
                    <h2>v-expansion-panels</h2>
                    <div class="component-frame">
                        <v-expansion-panels>
                            <v-expansion-panel title="Panel 1" text="Content 1" />
                            <v-expansion-panel title="Panel 2" text="Content 2" />
                            <v-expansion-panel title="Panel 3" text="Content 3" />
                        </v-expansion-panels>
                    </div>
                    <v-divider class="align-self-stretch" />
                    <h2>v-list</h2>
                    <div class="component-frame">
                        <v-list style="width: 300px">
                            <v-list-item title="Item 1" />
                            <v-list-subheader>Subheader</v-list-subheader>
                            <v-list-item title="Item 2" />
                            <v-list-item title="Item 3" />
                        </v-list>
                    </div>
                    <v-divider class="align-self-stretch" />
                    <h2>v-tooltip</h2>
                    <div class="component-frame">
                        <app-tooltip text="Tooltip text">
                            <template v-slot:activator="{ props }">
                                <v-btn v-bind="props">Hover over me</v-btn>
                            </template>
                        </app-tooltip>
                    </div>
                </div>
            </v-window-item>
            <v-window-item key="forms">
                <div class="d-flex flex-column align-center">
                    <h2>v-chip-group</h2>
                    <div class="component-frame">
                        <v-chip-group v-model="chipGroupModel" column multiple>
                            <v-chip text="High offset" filter></v-chip>

                            <v-chip text="Washer / Dryer" filter></v-chip>

                            <v-chip text="Fireplace" filter></v-chip>

                            <v-chip text="Wheelchair access" filter></v-chip>

                            <v-chip text="Dogs ok" filter></v-chip>

                            <v-chip text="Cats ok" filter></v-chip>
                        </v-chip-group>
                    </div>
                    <h2>app-chip-group</h2>
                    <div class="component-frame">
                        <app-chip-group v-model="chipGroupModel" column multiple>
                            <v-chip text="High offset" filter></v-chip>

                            <v-chip text="Washer / Dryer" filter></v-chip>

                            <v-chip text="Fireplace" filter></v-chip>

                            <v-chip text="Wheelchair access" filter></v-chip>

                            <v-chip text="Dogs ok" filter></v-chip>

                            <v-chip text="Cats ok" filter></v-chip>
                        </app-chip-group>
                    </div>

                    <h2>v-checkbox</h2>
                    <div class="component-frame">
                        <v-checkbox label="Checkbox Label" />
                    </div>
                    <v-divider class="align-self-stretch" />
                    <h2>v-combobox</h2>
                    <div class="component-frame">
                        <v-combobox
                            label="Select Bone"
                            :items="['Pelvis', 'Hemipelvis', 'Sacrum', 'Femur']"
                            style="width: 300px"
                        />
                    </div>
                    <v-divider class="align-self-stretch" />
                    <h2>v-select</h2>
                    <div class="component-frame">
                        <v-select
                            label="Select Bone"
                            :items="['Pelvis', 'Hemipelvis', 'Sacrum', 'Femur']"
                            style="width: 300px"
                        />
                    </div>
                    <v-divider class="align-self-stretch" />
                    <h2>v-file-input</h2>
                    <div class="component-frame">
                        <v-file-input style="width: 300px" />
                    </div>
                    <v-divider class="align-self-stretch" />
                    <h2>v-radio-buttons</h2>
                    <div class="component-frame">
                        <v-radio-group>
                            <v-radio label="Radio 1" value="one"></v-radio>
                            <v-radio label="Radio 2" value="two"></v-radio>
                            <v-radio label="Radio 3" value="three"></v-radio>
                        </v-radio-group>
                    </div>
                    <v-divider class="align-self-stretch" />
                    <h2>v-slider</h2>
                    <div class="component-frame">
                        <v-slider label="slider label" style="width: 300px" />
                    </div>
                    <v-divider class="align-self-stretch" />
                    <h2>v-range-slider</h2>
                    <div class="component-frame">
                        <v-range-slider label="slider label" style="width: 300px" />
                    </div>
                    <v-divider class="align-self-stretch" />
                    <h2>v-switch</h2>
                    <div class="component-frame">
                        <v-switch label="switch label" />
                    </div>
                    <v-divider class="align-self-stretch" />
                    <h2>v-text-field</h2>
                    <div class="component-frame">
                        <v-text-field label="text-field label" style="width: 300px" />
                    </div>
                    <v-divider class="align-self-stretch" />
                    <h2>v-textarea</h2>
                    <div class="component-frame">
                        <v-textarea label="text-area label" style="width: 300px" />
                    </div>
                </div>
            </v-window-item>
            <v-window-item key="selection">
                <div class="d-flex flex-column align-center">
                    <h2>v-btn-toggle</h2>
                    <div class="component-frame">
                        <v-btn-toggle divided variant="flat">
                            <v-btn icon="mdi-format-align-left"></v-btn>
                            <v-btn icon="mdi-format-align-center"></v-btn>
                            <v-btn icon="mdi-format-align-right"></v-btn>
                            <v-btn icon="mdi-format-align-justify"></v-btn>
                        </v-btn-toggle>
                    </div>
                </div>
            </v-window-item>
            <v-window-item key="typography">
                <div class="d-flex flex-column align-center">
                    <h2>Typograph</h2>
                    <v-card v-for="[name, cls] in classes" :key="name" class="my-4">
                        <div :class="[cls, 'pa-2']">{{ name }}</div>
                        <div class="text-caption pa-2 bg-grey-lighten-4">
                            <div class="text-grey">Class</div>
                            <div class="font-weight-medium">{{ cls }}</div>
                        </div>
                    </v-card>
                </div>
            </v-window-item>
            <v-window-item key="icons">
                <div class="d-flex flex-column align-center">
                    <h2>Icons as img</h2>

                    <div class="component-frame">
                        <img
                            v-for="(image, index) in imageUrlMap"
                            :key="index"
                            :src="image.value"
                            class="my-4 mx-5"
                        />
                    </div>

                    <h2>Fommus Icons (f: prefix)</h2>
                    <div class="component-frame">
                        <v-icon class="bg-amber pa-1" color="primary">f:stem</v-icon>
                        <v-icon
                            v-for="[key] in Object.entries(formusIcons)"
                            :key="key"
                            class="mx-2"
                            size="40"
                        >
                            f:{{ key }}
                        </v-icon>
                    </div>

                    <h2>Fommus Icons in v-btn</h2>
                    <div class="component-frame">
                        <v-btn class="ma-2" color="blue" icon>
                            <v-icon icon="f:stem" size="24" color="yellow"></v-icon>
                        </v-btn>
                    </div>

                    <h2>Material Icons</h2>
                    <div class="component-frame d-flex">
                        <v-icon class="mx-5" :key="index" v-for="(icon, index) in mdiIcons"
                            >{{ icon }}
                        </v-icon>
                    </div>
                </div>
            </v-window-item>
        </v-window>
    </div>
</template>

<style scoped>
    h1 {
        margin: 8px 8px 0;
        text-align: center;
    }

    h2 {
        margin: 8px 8px 0;
        text-align: center;
    }

    .component-frame {
        padding: 4px;
        margin: 8px;
        border: v-bind(frameBorder);
    }
</style>

<script setup lang="ts">
    import { useTheme } from 'vuetify';
    import { computed, ref } from 'vue';
    import stemImageUrl from '@/assets/icons/stem-panel.svg';
    import cupImageUrl from '@/assets/icons/cup-panel.svg';
    import combinedImageUrl from '@/assets/icons/combined-panel.svg';
    import expandLateralSrc from '@/assets/icons/expand-lateral.svg';
    import collapseLateralSrc from '@/assets/icons/collapse-lateral.svg';
    import megaphoneSrc from '@/assets/icons/megaphone.svg';
    import caseNewSrc from '@/assets/icons/case-new.svg';
    import caseProcessingSrc from '@/assets/icons/case-processing.svg';
    import caseCompletedSrc from '@/assets/icons/case-completed.svg';
    import navigationSettingsSrc from '@/assets/icons/navigation-settings-outline.svg';
    import navigationCubeSrc from '@/assets/icons/navigation-cube-outline.svg';
    import navigationDicomsSrc from '@/assets/icons/navigation-dicom-outline.svg';
    import navigationPdfSrc from '@/assets/icons/navigation-pdf-outline.svg';

    import { formusIcons } from '@/plugins/vuetify/formusIcons';

    const theme = useTheme();

    const selectedTheme = computed<string>({
        get: () => theme.global.name.value,
        set: (value: string) => (theme.global.name.value = value),
    });

    const selectableThemes = computed(() => Object.keys(theme.computedThemes.value));

    const themeColors = computed(() => {
        return Object.entries(theme.global.current.value.colors);
    });

    const showFrame = ref(false);

    const frameBorder = computed(() => (showFrame.value ? '1px dashed grey' : ''));

    const componentTab = ref('containment');

    const classes = [
        ['Heading 1', 'text-h1'],
        ['Heading 2', 'text-h2'],
        ['Heading 3', 'text-h3'],
        ['Heading 4', 'text-h4'],
        ['Heading 5', 'text-h5'],
        ['Heading 6', 'text-h6'],
        ['Subtitle 1', 'text-subtitle-1'],
        ['Subtitle 2', 'text-subtitle-2'],
        ['Body 1', 'text-body-1'],
        ['Body 2', 'text-body-2'],
        ['Button', 'text-button'],
        ['Caption', 'text-caption'],
        ['Overline', 'text-overline'],
    ];

    const chipGroupModel = ref<string[]>([]);

    const imageUrlMap = [
        { label: 'stem', value: stemImageUrl },
        { label: 'cup', value: cupImageUrl },
        { label: 'combined', value: combinedImageUrl },
        { label: 'expand-lateral', value: expandLateralSrc },
        { label: 'collapse-lateral', value: collapseLateralSrc },
        { label: 'megaphone', value: megaphoneSrc },
        { label: 'case-new', value: caseNewSrc },
        { label: 'case-processing', value: caseProcessingSrc },
        { label: 'case-completed', value: caseCompletedSrc },
        { label: 'navigation-settings', value: navigationSettingsSrc },
        { label: 'navigation-dicoms', value: navigationDicomsSrc },
        { label: 'navigation-planner', value: navigationCubeSrc },
        { label: 'navigation-plans', value: navigationPdfSrc },
    ];

    const mdiIcons = [
        'mdi-account-circle-outline',
        'mdi-help-circle-outline',
        'mdi-book-open-blank-variant-outline',
        'mdi-play-circle-outline',
        'mdi-bell',
        'mdi-information-outline',
        'mdi-magnify',
        'mdi-account-plus-outline',
    ];
</script>
