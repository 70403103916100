import { objectNode, type ObjectNode, updateObject } from '@/planner/3d/object';
import { Matrix4, Mesh, PlaneGeometry, Vector3 } from 'three';
import { logger, stopAll, type StopHandle } from '@/util';
import { type MeshMaterial, updateMeshMaterial } from '@/planner/3d/materials/meshMaterial';
import type { NodeId } from '@/planner/3d/nodeId';
import type { SceneContext } from '@/planner/3d/SceneContext';
import { computed, watchEffect } from 'vue';
import { areCollinear, normalize, orthogonalUnit } from '@/formus/geometry/vector3';
import { projectOrthogonalUnit } from '@/formus/geometry/projection';
import { assert } from '@/util';
import { isRigidMatrix } from '@/formus/geometry/matrix';
import { type RenderOrderNode, renderOrderNode, updateRenderOrder } from '@/planner/3d/renderOrder';

const DEFAULT_WIDTH_HEIGHT = 100;
const DEFAULT_SEGMENTS = 1;

const log = logger('3d');

export type PlaneNode = ObjectNode<'plane'> & RenderOrderNode & {
    width: number;
    height: number;
    widthSegments: number;
    heightSegments: number;
    material: MeshMaterial;
}

export function planeNode(id: NodeId, properties?: Partial<PlaneNode>): PlaneNode {
    return {
        ...objectNode('plane', id, properties),
        ...renderOrderNode(properties),
        width: properties?.width ?? DEFAULT_WIDTH_HEIGHT,
        height: properties?.height ?? DEFAULT_WIDTH_HEIGHT,
        widthSegments: properties?.widthSegments ?? DEFAULT_SEGMENTS,
        heightSegments: properties?.heightSegments ?? DEFAULT_SEGMENTS,
        material: properties?.material ?? null,
    }
}

export function updatePlane(context: SceneContext, node: PlaneNode): StopHandle {
    let geometry: PlaneGeometry | null = null;
    const disposeGeometry = () => {
        if (geometry !== null) {
            geometry.dispose();
        }
    }
    const mesh = new Mesh();
    return stopAll(
        updateObject(context, node, mesh),
        updateMeshMaterial(context, computed(() => node.material), mesh),
        updateRenderOrder(node, mesh),
        watchEffect(
            () => {
                disposeGeometry();
                geometry = new PlaneGeometry(node.width, node.height, node.widthSegments, node.heightSegments);
                mesh.geometry = geometry;
            }
        ),
        disposeGeometry,
    )
}

const _defaultX = new Vector3(1, 0, 0);
const _alternateDefaultX = new Vector3(0, 1, 0);

function _planeX(normal: Vector3, x?: Vector3): Vector3 {
    if (x) {
        if (!areCollinear(x, normal)) {
            return projectOrthogonalUnit(x, normal);
        } else {
            log.warning('Plane x-vector is collinear with plane normal');
        }
    }
    return !areCollinear(normal, _defaultX)
        ? projectOrthogonalUnit(_defaultX, normal)
        : projectOrthogonalUnit(_alternateDefaultX, normal);
}

export function planeTransform(position: Vector3, normal: Vector3, x?: Vector3): Matrix4 {
    const planeX = _planeX(normal, x);
    const planeY = orthogonalUnit(normal, planeX);
    const result = new Matrix4().makeBasis(planeX, planeY, normalize(normal)).setPosition(position);
    assert(isRigidMatrix(result), 'Plane-transform is not rigid');
    return result;
}
