// API representation of rigid-transformations
import {
    isMatrixArray16, type MatrixArray16, matrixFromApi, matrixToApi,
} from '@/formus/geometry/apiMatrix';
import { type EulerAngles, isEulerAngles } from '@/formus/geometry/eulerRotation';
import { eulerRigidTransform, type RigidMatrix4, type RigidTransform } from '@/formus/geometry/rigidTransform';
import { isNumberArray3, type NumberArray3 } from '@/formus/geometry/apiVector';
import { identity4 } from '@/formus/geometry/matrix';
import type { Matrix4 } from 'three';

/**
 * Representation of a rigid-transformation on the API
 */
export type ApiRigidTransform = {
    type: 'rigid-transform';
    matrix?: MatrixArray16;
    translation?: NumberArray3;
    rotation?: EulerAngles;
};

/**
 * Convert a rigid-transform into its API representation
 */
export function transformToApi(transform: RigidTransform): ApiRigidTransform {
    return {
        type: 'rigid-transform',
        matrix: transform.matrix ? matrixToApi(transform.matrix) : undefined,
        translation: transform.translation?.clone()?.toArray(),
        rotation: transform.rotation ? { ...transform.rotation } : undefined,
    };
}

/**
 * Convert a rigid-transform from its API representation.
 *
 * A null value is treated as equivalent to an identity transform.
 */
export function transformFromApi(transform: ApiRigidTransform | null): RigidTransform {
    return eulerRigidTransform(
        transform?.matrix ? matrixFromApi(transform?.matrix) : undefined,
        transform?.rotation?.order,
    );
}

/**
 * Convert a transform matrix into an API rigid-transform representation
 */
export function transformMatrixToApi(matrix: Matrix4): ApiRigidTransform {
    return transformToApi(eulerRigidTransform(matrix));
}

/**
 * Convert an API rigid-transform representation into a matrix
 *
 * A null value is treated as equivalent to an identity transform.
 */
export function transformMatrixFromApi(transform: ApiRigidTransform | null): RigidMatrix4 {
    return transform?.matrix
        ? matrixFromApi(transform?.matrix)
        : identity4();
}

/**
 * Check whether the given object is a valid rigid-transform representation.
 */
export function isApiRigidTransform(value: unknown): value is ApiRigidTransform {
    const maybeTransform = value as ApiRigidTransform;
    return (
        !!maybeTransform &&
        maybeTransform.type === 'rigid-transform' &&
        (!maybeTransform.matrix || isMatrixArray16(maybeTransform.matrix)) &&
        (!maybeTransform.translation || isNumberArray3(maybeTransform.translation)) &&
        (!maybeTransform.rotation || isEulerAngles(maybeTransform.rotation))
    );
}
