<template>
    <div class="app-menu-item-wrapper" :class="cssClass">
        <component :is="to ? 'router-link' : 'div'" :to="to">
            <div class="app-menu-item" role="button">
                <app-tooltip activator="parent" location="bottom" v-if="tooltip" :aria-label="tooltip">
                    {{ tooltip }}
                </app-tooltip>
                <div class="icon-wrapper" :class="backgroundColorCss">
                    <v-icon :color="background === 'light' ? 'white' : 'dark-blue'">{{ iconName }}</v-icon>
                </div>
            </div>
        </component>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import type { RouteLocationRaw } from 'vue-router';

    const props = withDefaults(
        defineProps<{
            activeClass?: string;
            background?: 'dark' | 'light';
            icon:
                string
                | 'settings'
                | 'dicoms'
                | 'planner'
                | 'plans';
            tooltip?: string;
            to?: RouteLocationRaw;
            disabled?: boolean;
            disabledBgClass?: string;
        }>(),
        { background: 'dark', disabledBgClass: 'bg-surface-light' },
    );
    const cssClass = computed(() => {
        return {
            'cursor-pointer': props.tooltip || props.to,
            'menu-disabled': props.disabled,
        };
    });

    const categories = ['settings', 'dicoms', 'planner', 'plans'];
    const formusSources = Object.fromEntries(
        categories.map(category => [
            category,
            {
                light: {
                    enabled: `f:navigation-${category}-outline-white`,
                    disabled: `f:navigation-${category}-outline`,
                },
                dark: {
                    enabled: `f:navigation-${category}-outline`,
                    disabled: `f:navigation-${category}-outline`,
                },
            },
        ])
    );

    const iconName = computed(() => {
        const iconMap = formusSources[props.icon];
        if (!iconMap) {
            return props.icon;
        }
        const variant = iconMap[props.background];
        return variant ? variant[props.disabled ? 'disabled' : 'enabled'] : '';
    });

    const backgroundColorCss = computed(() => {
        if (props.background === 'dark') {
            if (props.disabled) {
                return props.disabledBgClass;
            } else {
                return `bg-white`;
            }
        } else {
            if (props.disabled) {
                return props.disabledBgClass;
            } else {
                return `bg-dark-blue`;
            }
        }
    });
</script>

<style scoped lang="scss">
    .app-menu-item {
        display: flex;
        align-items: center;
    }

    .app-menu-item span {
        padding-right: 8px;
        font-size: 14px;
        font-weight: 600;
    }

    .app-menu-item .icon-wrapper {
        display: flex;
        height: 40px;
        width: 40px;
        border-radius: 100%;
        padding: 8px;
        transition: background-color 0.2s ease-in-out;
    }

    .app-menu-item-wrapper.menu-disabled {
        .icon-wrapper {
            cursor: default;
        }
    }
</style>
