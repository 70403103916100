import { type AlignmentMode, alignmentVectors } from '@/formus/anatomy/pelvis/alignment';
import type { BodySide } from '@/formus/anatomy/side';
import { findPlaneMeasurement, type MeasurementGroup } from '@/formus/anatomy/measurements';
import { vector3 } from '@/formus/geometry/vector3';
import {
    type AcetabularAngles,
    radiographicAnteversion,
    radiographicInclination,
} from '@/formus/anatomy/pelvis/acetabularAngles';

/** Calculate the acetabular-angles of the native acetabular plane in radiographic convention */
export function nativeAcetabularAngles(
    mode: AlignmentMode,
    side: BodySide,
    measurements: MeasurementGroup[],
): AcetabularAngles<'radiographic'> {
    const acetabularPlane = findPlaneMeasurement(
        measurements,
        'pelvis',
        `hip_pelvis_acetabulum_plane_${side}`,
    );
    // Negate the acetabular normal, as it points into the acetabulum.
    const acetabularNormal = vector3(acetabularPlane.value).negate();
    const lps = alignmentVectors(mode, measurements);
    return {
        system: 'radiographic',
        anteversion: radiographicAnteversion(acetabularNormal, lps),
        inclination: radiographicInclination(acetabularNormal, lps),
    };
}
