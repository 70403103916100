import {
    BufferAttribute,
    Color,
    DoubleSide,
    InterleavedBufferAttribute,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    Raycaster,
    Vector3,
} from 'three';
import { asyncForEach } from '@/util/asyncForEach';
import {
    applyMatrix4ToGeometry,
    areaOfTriangle,
    boundingSphere,
    extractFaces,
    type Face3Indices,
    faceCount,
    faceIndices,
    type FacePredicate,
    getFaceVertexFromBufferAttribute,
    vertexPositionBuffer,
} from '@/formus/geometry/mesh';
import {
    type CollisionCoverageResults,
    type ColorWithOpacity,
    type ComponentCoverageStrategy,
    FaceAreaMetric,
    type FaceCollisionColorCallback,
    FaceColorOpacity,
    IntersectionResult,
    PointIntersection,
} from './types';
import { taggedLogger } from '@/util';
import { applyOnlyRotationMatrix, relativeTransform } from '@/formus/geometry/matrix';
import { type Face3Positions, getCentroid } from '@/formus/geometry/face';
import { renderOrder } from '@/planner/scene/renderOrder';
import { setWorldTransform } from '@/planner/3d/object3d';
import { any } from 'ramda';

const log = taggedLogger('cup-coverage');

export const FACES_PER_DEFER_LOOP = 10;

export function cupCoverageStrategy(): ComponentCoverageStrategy {
    return {
        getFaceColor: makeCupColorCallback(),
        getRayDirection: getRayDirection,
        coverageMeshRenderOrder: renderOrder['cup-coverage'],
    };
}

function makeCupColorCallback(): FaceCollisionColorCallback {
    /**
     * Method for making default collision face colors
     *
     * A default color is always provided, while the cortical and cancellous colors can be overwritten
     * by passing their respective colors
     */
    function _makeComponentCollisionFaceColor(
        options?: CollisionFaceColorOptions,
    ): CollisionFaceColors {
        return {
            /** Default collision color is RED */
            default: new Color(255, 0, 0),
            /** Default cortical color is YELLOW */
            cortical: options?.cortical || new Color(255, 255, 0),
            /** Default cancellous color is BLUE */
            cancellous: options?.cancellous || new Color(0, 0, 255),
            /** Default 'outside of bone' color is RED */
            outside: options?.outside || new Color(255, 0, 0),
        };
    }

    const faceColor = _makeComponentCollisionFaceColor();
    return (result: FaceAreaMetric): ColorWithOpacity | null => {
        if (result.intersection.inside) {
            // Set the face collision color to be red (default color) if the face position is inside
            // the collision area, no matter the distance
            return { rgb: faceColor.default, opacity: FaceColorOpacity.Transparent };
        } else {
            return null;
        }
    };
}

/** Options for creating a collision distance colors */
export interface CollisionFaceColorOptions {
    /** Within certain number of millimeters (default is 2mm) of the surface of the bone */
    cortical?: Color;
    /** Deeper than certain number of millimeters (default is 2mm) from the surface of the bone */
    cancellous?: Color;
    /** Outside of bone (no collision) */
    outside?: Color;
}

/** Collision distance colors representation */
export interface CollisionFaceColors {
    default: Color;
    cortical: Color;
    cancellous: Color;
    outside: Color;
}

/**
 * The ray direction in the local component space for testing coverage of a cup.
 *
 * How cup meshes are defined server-side:
 *
 * <pre>
 * o = Origin point
 * R = Ray direction on each face of M.
 *
 *        /|                                    A cup
 *      /  |         R <------------   ~ ~ -  ,
 *    /    |                                    ' ,
 *  /      |                        R <-----------  ,
 * |       |                        R <------------  ,
 * |  M    |                                          ,                      Y axis = -R
 * |  E    |                        o ----------------,--------------------------->
 * |  S    |                                          ,
 * |  H    |                        R <------------  ,
 * |       |                        R <------------ ,
 * |       |                   R <------------  , '
 * |      /             R <------------ _ ,  '
 * |    /
 * |  /
 * |/
 * </pre>
 *
 * @Note The hemisphere of the cup points towards the positive Y direction.
 * We want to search in the opposite direction. This direction is related
 * to how we are filtering the covering mesh.
 */
function getRayDirection(): Vector3 {
    return new Vector3(0, -1, 0);
}

/**
 * Create a mesh with the same vertices and transform as the source mesh but only a
 * subset of the faces. The new mesh will be assigned a {@link DoubleSide double-sided}
 * material so that the {@link Raycaster} will return intersections wth both front- and
 * back-side faces.
 *
 * @see https://threejs.org/docs/api/en/core/Raycaster.html#intersectObject
 */
function filterMeshFaces(sourceMesh: Mesh, includeFace: FacePredicate): Mesh {
    const reducedGeometry = extractFaces(sourceMesh.geometry.clone(), includeFace, false);
    const result = new Mesh(reducedGeometry, new MeshBasicMaterial({ side: DoubleSide }));
    result.matrixAutoUpdate = false;

    setWorldTransform(result, sourceMesh.matrixWorld);
    return result;
}

/**
 * Check whether a given point is inside a cylinder with the given radius and axis passing through the origin and
 * with the axis in a particular direction.
 */
function isInsideCylinder(
    position: Vector3,
    cylinderAxis: Vector3,
    cylinderRadius: number,
): boolean {
    // Find the (squared) distance from the position to the cylinder axis. If this (squared) distance is less
    // than the (squared) cylinder radius, the position is inside the cylinder.
    const squaredDistanceFromCylinderAxis = position.projectOnPlane(cylinderAxis).lengthSq();
    return squaredDistanceFromCylinderAxis < cylinderRadius * cylinderRadius;
}

/**
 * Check whether a given point is inside a sphere with the given radius, centred at the origin
 */
function isInsideSphere(position: Vector3, sphereRadius: number): boolean {
    // Find the (squared) distance from the position to the sphere centre. If this (squared) distance is less
    // than the (squared) sphere radius, the position is inside the sphere.
    const squaredDistanceFromCentre = position.lengthSq();
    return squaredDistanceFromCentre < sphereRadius * sphereRadius;
}

/**
 * Create the mesh to be used for intersection with tests with a subset
 * of the faces from the full covering-mesh.
 */
function makeIntersectionMesh(
    componentMask: Mesh,
    fullCoveringMesh: Mesh,
    componentToCoveringMesh: Matrix4,
): Mesh {
    // Transform the ray direction from component space into covering-mesh space
    const rayDirection = applyOnlyRotationMatrix(
        componentToCoveringMesh,
        getRayDirection().normalize().clone(),
    );

    // Get the bounding sphere of the component mesh in component space
    const bsphere = boundingSphere(componentMask.geometry);

    // Find the position of the bounding sphere in covering-mesh coordinates.
    const sphereCentre = bsphere.center.clone().applyMatrix4(componentToCoveringMesh);

    const isFaceInsideVolume = (facePositions: Face3Positions): boolean => {
        // Each face is included if it has at least one vertex within the inclusion volume.
        return any((vertexPosition: Vector3): boolean => {
            // Vector that gives the vertex position relative to the sphere centre
            const sphereToVertex = vertexPosition.clone().sub(sphereCentre);

            // Get the distance of the vertex from the plane through the component-bounding-sphere centre and
            // orthogonal to the ray direction. This plane splits the hemispherical from the cylindrical bounding
            // volumes.
            const vertexToComponentPlane = sphereToVertex.dot(rayDirection);

            if (vertexToComponentPlane > 0) {
                // Vertex is in the cylindrical region of the bounding volume.
                return isInsideCylinder(sphereToVertex, rayDirection, bsphere.radius);
            } else {
                // The vertex is in the hemispherical region of the bounding volume.
                return isInsideSphere(sphereToVertex, bsphere.radius);
            }
        }, facePositions);
    };

    const result = filterMeshFaces(fullCoveringMesh, isFaceInsideVolume);
    log.debug(
        `Created intersection mesh with ${faceCount(result.geometry)} ` +
            `out of ${faceCount(fullCoveringMesh.geometry)} faces`,
    );
    return result;
}

/**
 * Calculate the proportion of area of faces in the componentMask that are BEHIND faces coveringMesh
 * This is achieved creating a collision geometry and calculating collision area.
 *
 * *Strategy*: use the [crossing number algorithm]{@link https://en.wikipedia.org/wiki/Point_in_polygon}
 * (a.k.a. even-odd rule algorithm)
 *
 * @see https://en.wikipedia.org/wiki/Point_in_polygon
 *
 * Assumptions
 * -----------
 * 1. The collidable mesh is a
 *   [closed (watertight)]{@link https://davidstutz.de/a-formal-definition-of-watertight-meshes/)} mesh.
 * 2. The algorithm does not account for [osteophytes]{@link https://en.wikipedia.org/wiki/Osteophyte}
 * not filtered on the segmentation process.
 *
 * e.g.: Considering a hip - cup scenario:
 *  If a cup is making contact with an osteophyte, the algorithm will not know the difference between
 *  the osteophyte and actual hip socket where the cup is intended to be fit.
 *  This seems to not be a limitation of this algorithm but rather a residual of the segmentation process.
 *
 * Strategy illustration in 2D using a polygon and multiple points
 * ---------------------------------------------------------------
 * 1. Draw a horizontal line to the right of each point and extend it to infinity
 * 1. Count the number of times the line intersects with polygon edges.
 * 1. A point is inside the polygon if either count of intersections is odd or point lies on an edge of polygon.
 * If none of the conditions is true, then point lies outside.
 *
 * @see https://newbedev.com/check-if-point-is-inside-a-custom-mesh-geometry
 *
 * <pre>
 *                   y0----------------------------------------> 0 intersections (even - outside)
 *                               ___________
 *                              / x1--------|------------------> 1 intersection (odd - inside)
 *                           __/            |_________
 *                          |                         |
 *                          |   x2--------------------|--------> 1 intersection (odd - inside)
 *                          |                         |
 *                          |        _____            |
 *                   y1-----|-------|----|------------|--------> 4 intersections (even - outside)
 *                          |  x3 --|----|------------|--------> 3 intersections (odd - inside)
 *                          |_______|    |____________|
 * </pre>
 */
export async function calculateComponentCoverage(
    componentMask: Mesh,
    fullCoveringMesh: Mesh,
    signal: AbortSignal,
): Promise<CollisionCoverageResults> {
    // Get the matrix that transforms component space into covering-mesh space.
    const componentToCoveringMesh = relativeTransform(
        fullCoveringMesh.matrixWorld,
        componentMask.matrixWorld,
    );

    const coveringMesh = makeIntersectionMesh(
        componentMask,
        fullCoveringMesh,
        componentToCoveringMesh,
    );

    const collisionMask = applyMatrix4ToGeometry(componentMask.geometry, componentMask.matrixWorld);
    log.debug(
        'Checking coverage of obj1 (%s faces) in coveringMesh %s (%s faces)',
        faceCount(collisionMask),
        fullCoveringMesh.name,
        faceCount(coveringMesh.geometry),
    );

    let collisionArea = 0.0;
    let totalArea = 0.0;
    let faces = 0;

    const faceAreaMetrics: FaceAreaMetric[] = [];

    const collisionMaskVertexPositions = vertexPositionBuffer(collisionMask);

    // The ray direction when calculating the face intersection seems to be needed in the
    // world space (could not finding documentation about it, but seems reasonable)
    // Given the ray direction is in the component mask local space, and does not
    // taking in consideration the rotation of the components mask in the world space,
    // it is rotated as follows.
    const rayDirectionInWorldSpace = applyOnlyRotationMatrix(
        componentMask.matrixWorld,
        getRayDirection().normalize().clone(),
    );

    function _calculateFace(faceIndices: Face3Indices): void {
        const faceAreaMetric = calculateFace(
            faceIndices,
            collisionMaskVertexPositions,
            rayDirectionInWorldSpace,
            coveringMesh,
        );
        totalArea += faceAreaMetric.area;

        if (faceAreaMetric.intersection.inside) {
            collisionArea += faceAreaMetric.area;
            ++faces;
        }
        faceAreaMetrics.push(faceAreaMetric);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    await asyncForEach(faceIndices(collisionMask), _calculateFace, {
        chunkSize: FACES_PER_DEFER_LOOP,
        signal,
    });

    signal.throwIfAborted();

    return {
        isCalculating: false,
        coverage: collisionArea / totalArea,
        faces: faces,
        totalFaces: faceCount(collisionMask),
        area: collisionArea,
        totalArea,
        intersectionMesh: coveringMesh,
        faceAreaMetrics,
    };
}

function calculateFace(
    faceIndices: Face3Indices,
    /**
     * The position attribute of the buffer geometry. This is used to extract the face positions.
     *
     * Note: For **performance** reasons it is asked as a parameter, so it is calculated **only once** and
     * not on each iteration.
     */
    vertexPositions: BufferAttribute | InterleavedBufferAttribute,
    rayDirectionInWorldSpace: Vector3,
    coveringMesh: Mesh,
): FaceAreaMetric {
    const facePositions = getFaceVertexFromBufferAttribute(vertexPositions, faceIndices);
    const faceArea = areaOfTriangle(facePositions[0], facePositions[1], facePositions[2]);
    const pointIntersection = intersectFaceCentroid(
        facePositions,
        coveringMesh,
        rayDirectionInWorldSpace,
    );
    return new FaceAreaMetric(faceArea, facePositions, pointIntersection);
}

/**
 * Intersects a face with a mesh.
 *
 * A ray is configured as follows:
 *   - origin: face centroid.
 *   - direction: the inverse of the face normal
 */
function intersectFaceCentroid(
    facePositions: Face3Positions,
    coveringMesh: Object3D,
    rayDirection: Vector3,
): PointIntersection {
    const faceCentroid = getCentroid(facePositions);
    return intersectPoint(faceCentroid, rayDirection, coveringMesh);
}

/**
 * Intersects a point with a mesh.
 */
function intersectPoint(
    origin: Vector3,
    rayDirection: Vector3,
    coveringMesh: Object3D,
): PointIntersection {
    const ray = new Raycaster(origin, rayDirection);
    const result = new IntersectionResult(origin, rayDirection, ray.intersectObject(coveringMesh));
    return new PointIntersection(result);
}
