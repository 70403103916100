<template>
    <v-menu
        max-height="500"
        max-width="500"
        content-class="support-menu"
        transition="fade-transition"
        open-on-hover
        location="bottom"
        offset="30"
        open-delay="0"
        close-delay="0"
    >
        <template v-slot:activator="{ props }">
            <v-hover>
                <template v-slot:default="{ isHovering, props: hoverProps }">
                    <app-menu-item
                        v-bind="{ ...hoverProps }"
                        :background="isHovering ? 'light' : 'dark'"
                        class="cursor-pointer"
                        icon="mdi-palette-outline"
                        @click="props.onClick"
                        @focus="props.onFocus"
                        @blur="props.onBlur"
                        @mouseenter="props.onMouseenter"
                        @mouseleave="props.onMouseleave"
                    />
                </template>
            </v-hover>
        </template>
        <v-card dense min-width="400" color="planner-rich-blue" class="notifications-card">
            <v-list dense class="pa-5 overflow-y-auto">
                <v-radio-group v-model="selectedTheme">
                    <v-radio
                        v-for="theme in selectableThemes"
                        :key="theme"
                        :value="theme"
                        :label="theme"
                    />
                </v-radio-group>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script setup lang="ts">
    import { useTheme } from 'vuetify';
    import { computed } from 'vue';

    const theme = useTheme();

    const selectedTheme = computed<string>({
        get: () => theme.global.name.value,
        set: (value: string) => (theme.global.name.value = value),
    });

    const selectableThemes = computed(() => Object.keys(theme.computedThemes.value));
</script>
