import { assert, taggedLogger } from '@/util';
import type { Url } from '@/formus/types';
import type { PlannerState } from '@/planner/plannerState';
import { templateId } from '@/api/template/templateUrl';
import { pollTemplate } from '@/api/template/pollTemplate';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AxiosError, HttpStatusCode } from 'axios';
import { client } from '@/api/http';
import { getIdFromUrl } from '@/lib/getIdFromUrl';
import type { TemplateId } from '@/formus/template/template';
import { useVersion } from '@/app/version';
import { router, ROUTES } from '@/router';

const log = taggedLogger('approve-plan');

export async function approvePlan(
    state: PlannerState,
    config?: AxiosRequestConfig,
): Promise<'approved' | 'failed'> {
    const productVersion = useVersion().webComponentVersion;
    const planId = await _approvePlan(state, productVersion, config);
    if (!planId) {
        log.error('Failed to approve plan');
        return 'failed';
    }

    log.info('Plan approved: %s. Attempt to navigate to plans page.', planId);
    assert(state.case);
    const failure = await router.push({
        name: ROUTES.PLANS,
        params: { id: state.case.caseId },
    });
    if (failure) {
        log.error('Failed to navigate to plans');
        return 'failed';
    } else {
        log.info('Navigated to plans.');
        return 'approved';
    }
}

/**
 * Approves the current planner state, creating a plan from it.
 */
export async function _approvePlan(
    state: PlannerState,
    webComponentVersion: string,
    config?: AxiosRequestConfig,
): Promise<string | null> {
    assert(state.case && state.template);

    const templateIds = templateId(state.case.manualTemplateUrl);
    log.info('Waiting surgical-template %d', templateIds.template);
    const template = await pollTemplate(
        state.case.manualTemplateUrl,
        (template) => template.recordState === 'completed',
        config,
    );

    assert(state.case.manualTemplateUrl === template.self);
    assert(!!template.canonical);

    return await _createPlan(
        templateIds,
        {
            name: 'user-approved-plan',
            web_component_version: webComponentVersion,
            surgical_template_history: template.canonical,
        },
        config,
    );
}

type PlanCreatedDataRepresentation = {
    /** An arbitrary name */
    readonly name: string;

    readonly web_component_version: string;

    /** the uri of the specific version being approved */
    readonly surgical_template_history: Url;
};

function _planCollectionUrl(caseId: number, templateId: number): Url {
    return `/project/${caseId}/surgical/template/${templateId}/plan`;
}

async function _createPlan(
    templateId: TemplateId,
    createData: PlanCreatedDataRepresentation,
    config?: AxiosRequestConfig,
): Promise<string | null> {
    const { status, data, headers }: AxiosResponse = await client.post(
        _planCollectionUrl(templateId.case, templateId.template),
        createData,
        config,
    );
    if (status === HttpStatusCode.Created) {
        const idFromUrl = getIdFromUrl(headers.location);
        return idFromUrl !== '' ? idFromUrl : null;
    }

    throw new AxiosError(`Failed to create plan: ${data?.detail}`);
}
