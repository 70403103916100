/**
 * Implements software requirements: H1SR-68, H1SR-149
 *
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-68/User-can-authenticate-with-their-email-as-a-username-and-their-chosen-password
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-149/Keep-user-passwords-secure
 */

import axios, { type AxiosError, type AxiosResponse, HttpStatusCode } from 'axios';
import { apiBaseUrl } from '@/lib/headMetaTags';
import { cachedAuthTokens, unexpiredAuthTokens } from '@/api/cache';
import { refreshTokens } from '@/api/authenticator';
import { useAuth } from '@/app/auth';

const ONE_SECOND = 1000;

export const CONFIG = {
    exponentialBackoffMultiplier: ONE_SECOND,
    requestTimeoutLimit: ONE_SECOND * 30,
};

export type UserFriendlyError = string;

export const isUserFriendlyErrorMessage = (error: any): error is UserFriendlyError =>
    typeof error === 'string';

export const client = axios.create({
    baseURL: apiBaseUrl(),
    timeout: CONFIG.requestTimeoutLimit,
    headers: { 'Content-Type': 'application/json' },
    validateStatus: (status: number) =>
        (status >= 200 && status < 300) || [400, 401, 403, 404].includes(status),
});

export function expectedErrorOrRethrow(
    error: AxiosError | any,
    genericUserFriendlyError: UserFriendlyError,
): UserFriendlyError {
    if (error.constructor.name === 'AxiosError') {
        // server errors including 500s, 502s, or network errors where
        // the request timed out or the client is offline are expected and can be handled.
        return genericUserFriendlyError;
    } else {
        // this could be a js syntax error or some other serious exception
        throw error;
    }
}

/**
 * Include access token as authorization header to all requests.
 */
client.interceptors.request.use(
    async (config: any) => {
        const tokens = await unexpiredAuthTokens();
        if (tokens) {
            const acceptHeader = config.headers['Accept'];
            if (!acceptHeader || acceptHeader === '') {
                config.headers['Accept'] = 'application/json';
            }

            config.headers['Authorization'] = `Bearer ${tokens.access}`;
        }
        return config;
    },
    (error: AxiosError) => Promise.reject(error),
);

/**
 * Refresh access token when it has expired.
 */
client.interceptors.response.use(
    async (response: AxiosResponse) => {
        // Note: 401 is a valid response code, as it was listed in the client definition
        if (response?.status === HttpStatusCode.Unauthorized) {
            const { config }: any = response;

            /**
             * there is an edge case where a 401 response when refreshing the token
             * can start an endless loop. this prevents that from happening.
             */
            if (config.retried) {
                return Promise.reject('Could not reauthenticate user.');
            }

            const tokens = await cachedAuthTokens();
            if (!tokens) {
                return Promise.reject('Could not reauthenticate user.');
            }

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [_newTokens, responseCode] = await refreshTokens(tokens.refresh);

            if (responseCode === HttpStatusCode.Ok) {
                /** retry request when new access token is available. */
                config.retried ??= true;
                return client(config);
            } else {
                // If the refresh token was invalid (e.g.: expired already), the user is forced to authenticate again
                const auth = useAuth();
                await auth.logout();
            }
        }
        return response;
    },
    (error: AxiosError) => Promise.reject(error),
);

/**
 * Failed requests will retry up to three times.
 */
client.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
        const { config }: any = error;

        if (!config) {
            return Promise.reject(error);
        }

        config.retryAttempt ??= 1;

        if (config.retryAttempt === 3) {
            return Promise.reject(error);
        }

        config.retryAttempt += 1;
        const increasingBackoff = CONFIG.exponentialBackoffMultiplier * config.retryAttempt;
        const delayedRequest = new Promise<void>((resolve) => {
            setTimeout(() => {
                resolve();
            }, increasingBackoff);
        });
        return delayedRequest.then(() => client(config));
    },
);
