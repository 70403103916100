<template>
    <h3 class="text-secondary-text">
        <slot></slot>
    </h3>
</template>

<script setup lang="ts" />

<style scoped>
    h3 {
        font-size: 21px;
        line-height: 34px;
        font-weight: 400;
        margin: 0 0 20px;
    }
</style>
