import { vector3 } from '@/formus/geometry/vector3';
import { findPlaneMeasurement, type MeasurementGroup } from '@/formus/anatomy/measurements';
import { lpsIdentityVectors, type LpsVectors } from '@/formus/anatomy/LPS';

/**
 * Alignment mode (coordinate systems) for measurements related to the pelvis.
 *
 * - 'CT' is the CT coordinate system
 * - 'APP' is the APP coordinate system, which is defined relative to the anterior-pelvic-plane
 */
export type AlignmentMode = 'CT' | 'APP';

/**
 * Get a set of LPS vectors for the given alignment mode
 */
export function alignmentVectors(
    alignmentMode: AlignmentMode,
    measurements: MeasurementGroup[],
): LpsVectors {
    if (alignmentMode === 'CT') {
        return lpsIdentityVectors();
    } else if (alignmentMode === 'APP') {
        return appAlignmentVectors(measurements);
    } else {
        throw new Error(`Invalid alignment mode ${alignmentMode}`);
    }
}

/**
 * Create a set of direction-vectors representing the APP coordinate system, which is defined
 * relative to the anterior-pelvic-plane
 * */
function appAlignmentVectors(measurements: MeasurementGroup[]): LpsVectors {
    const anteriorPelvicPlane = findPlaneMeasurement(
        measurements,
        'pelvis',
        `hip_pelvis_anterior_pelvic_plane`,
    );

    const right = vector3(anteriorPelvicPlane.x);
    const anterior = vector3(anteriorPelvicPlane.value);
    const superior = vector3(anteriorPelvicPlane.y);

    const left = right.negate();
    const posterior = anterior.negate();
    return { left, posterior, superior };
}
