/**
 * Implements software requirements: H1SR-11, H1SR-86
 *
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-11/Default-values-for-cases-can-be-defined-in-the-hip-preferences
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-86/Surgeon-users-are-able-to-define-from-a-selection-of-stem-types-which-stems-to-be-used-in-the-fitting-process
 */
import { isEmpty } from 'lodash';
import {
    isDateIsoString,
    isInRange,
    isInteger,
    isNotEmpty,
    isPositiveInteger,
} from '@/lib/validation';
import { type CaseSettingsInputs, OperativeSide, PatientSex } from '@/caseSettings/store/types';
import type { ValidatorsDefinition } from '@/spinopelvic/validation';

export enum AnteversionMode {
    Auto = 'auto',
    Manual = 'manual',
}

/**
 * functions should return true when the input is valid
 * and false when it is not valid.
 */
export const validators: ValidatorsDefinition<CaseSettingsInputs> = {
    surgeryName: ({ surgery: { name } }) => {
        return isNotEmpty(name);
    },
    surgeryDate: ({ surgery: { date } }) =>
        !isNotEmpty(date) || (isNotEmpty(date) && isDateIsoString(date)),
    surgerySide: ({ surgery: { side } }) =>
        side !== null && (side === OperativeSide.Left || side === OperativeSide.Right),
    patientFirstName: ({ patient }) => isNotEmpty(patient.firstName),
    patientFamilyName: ({ patient }) => isNotEmpty(patient.familyName),
    patientSex: ({ patient }) =>
        patient.sex !== null &&
        (patient.sex === PatientSex.Male || patient.sex === PatientSex.Female),
    patientDateOfBirth: ({ patient }) =>
        isNotEmpty(patient.dateOfBirth) && isDateIsoString(patient.dateOfBirth),
    surgeon: ({
        specifications: {
            surgeon: { selected, error },
        },
    }) => {
        return selected !== null && isPositiveInteger(`${selected}`) && !error;
    },
    stems: ({
        specifications: {
            stems: { preferredSystem, selected },
        },
    }) => {
        return !isEmpty([...selected].filter((stem => stem.includes(preferredSystem))));
    },
    preferredSystem: ({
        specifications: {
          stems: { preferredSystem },
        },
    }) => {
        return isNotEmpty(preferredSystem);
    },
    targetLegLengthChange: ({
        specifications: {
            targets: { legLength },
        },
    }) => isInteger(legLength) && isInRange(legLength, -20, 20, true),
    targetOffsetChange: ({
        specifications: {
            targets: { offset },
        },
    }) => isInteger(offset) && isInRange(offset, -20, 20, true),
    cupInclinationAngle: ({
        specifications: {
            cup: { inclinationAngle },
        },
    }) => {
        return isInteger(inclinationAngle) && isInRange(inclinationAngle, 0, 60, true);
    },
    cupFittingMode: ({
        specifications: {
            cup: { fitMethod },
        },
    }) => isNotEmpty(fitMethod),
    cupAnteversionMode: ({
        specifications: {
            cup: { anteversion },
        },
    }) => anteversion !== undefined && anteversion !== null && anteversion.length > 0,
    cupAnteversionAngle: ({
        specifications: {
            cup: { anteversion, anteversionAngle },
        },
    }) => {
        return (
            anteversion !== undefined &&
            anteversion !== null &&
            (anteversion === AnteversionMode.Auto ||
                (anteversion === AnteversionMode.Manual &&
                    isInteger(anteversionAngle) &&
                    isInRange(anteversionAngle, -5, 45, true)))
        );
    },
    alignmentMode: ({
        specifications: {
            cup: { alignment },
        },
    }) => alignment !== undefined && alignment !== null && alignment.length > 0,
};
