<template>
    <v-card-item class="px-10 py-5">
        <template #title>
            <h3 class="text-wrap">
                <slot></slot>
            </h3>
        </template>
        <template #append>
            <v-hover v-if="hasCloseHandler">
                <template v-slot:default="{ isHovering, props }">
                    <v-btn
                        v-bind="props"
                        elevation="0"
                        icon
                        size="x-small"
                        height="40"
                        width="40"
                        :base-color="isHovering ? 'surface-light' : 'white'"
                        @click.stop="emit('close')"
                    >
                        <v-icon size="20">mdi-close</v-icon>
                        <!--                    <img :src="collapseLateral" class="" />-->
                    </v-btn>
                </template>
            </v-hover>
        </template>
    </v-card-item>
</template>

<script setup lang="ts">
    import { computed, getCurrentInstance } from 'vue';

    const emit = defineEmits(['close']);

    // Access the current instance to get $attrs
    const instance = getCurrentInstance();

    // Check if the @close handler exists.
    const hasCloseHandler = computed(() => {
        return !!instance?.vnode.props?.onClose;
    });
</script>

<style scoped lang="scss">
    h3 {
        word-break: auto-phrase;
        font-size: 21px;
        line-height: 34px;
        font-weight: 400;
        color: rgb(var(--v-theme-primary));
    }
</style>
