import anylogger from 'anylogger';
import * as amplitude from '@amplitude/analytics-browser';
import { useUserStore } from '@/app/userStore/store';
import { parseHeadMetaTag } from '@/lib/headMetaTags';

const logger = anylogger('Amplitude');

const DEV_API_KEY = '4ac249f924e92a695bbfcf4e0e208f83';
const AT_LEAST_5_CHARS = 5;

export function configureAmplitude() {
    const api_key = parseHeadMetaTag('amplitude-api-key', DEV_API_KEY);
    amplitude.init(api_key);
}

export async function trackEvent(eventName: string, eventProperties?: Record<string, any>): Promise<void> {
    logger.debug(`Amplitude event fired: ${eventName}`);
    const hipUser = useUserStore();
    if (!hipUser.isLoaded) {
        await hipUser.load();
    }

    const eventInput = {
        event_type: eventName,
        user_id: hipUser.userId.padStart(AT_LEAST_5_CHARS, '0'),
        user_properties: {
            // TODO: add org id to response
            // organisation_id: hipUser.orgId,

            user_role: hipUser.role,
        },
        event_properties: eventProperties,
    };
    amplitude.track(eventInput);
    logger.debug(eventInput);
}
