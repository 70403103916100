<template>
    <div class="input-group">
        <group-label>Stem Offsets</group-label>
        <app-chip-group column mandatory v-model="store.selectedStem">
            <v-chip
                role="checkbox"
                v-for="offset in store.selectableStemOffsets"
                :value="offset.stemUrl"
                :key="offset.stemUrl"
                :text="offset.offset"
                filter
            />
        </app-chip-group>
    </div>

    <div class="input-group">
        <group-label>Head Offsets</group-label>
        <app-chip-group column mandatory v-model="store.selectedHead">
            <v-chip
                role="checkbox"
                v-for="offset in store.selectableHeadOffsets"
                :value="offset.headUrl"
                :key="offset.headUrl"
                :text="offset.text"
                filter
            />
        </app-chip-group>
    </div>
</template>

<script setup lang="ts">
    import GroupLabel from './GroupLabel.vue';
    import { useStemOverlayStore } from '@/planner/stemOverlayStore';

    const store = useStemOverlayStore();
</script>

<style scoped>
    .input-group {
        margin-bottom: 20px;
    }
</style>
