<template>
    <app-main-card class="app-plan-card-wrapper">
        <v-card-item class="px-0 app-plan-card-title">
            <template #title>
                <div class="fill-height">
                    <div class="text-primary text-heading-h5">{{ title }}</div>
                    <div class="text-secondary-text text-heading-h4">{{ project.name }}</div>
                </div>
            </template>
            <template #append>
                <QRCodeContainer v-if="displayQrCode" :project="project" :plan="plan" />
            </template>
        </v-card-item>
        <v-row no-gutters class="mb-4">
            <div class="text-secondary text-heading-h5">{{ project.description }}</div>
            <v-spacer />
            <div class="text-secondary-text">PLAN REFERENCE NUMBER: {{ refNumber }}</div>
        </v-row>
        <div class="app-plan-card-content mb-4">
            <v-row class="" no-gutters>
                <v-col cols="6">
                    <PlanDetailItem
                        label="Formus Case Number"
                        :value="formattedCaseNumber"
                        class=""
                    />
                </v-col>
                <v-col cols="6">
                    <PlanDetailItem label="Patient" :value="formattedPatientName" />
                </v-col>
                <v-col cols="6">
                    <PlanDetailItem label="G7 CUP" :value="`Size ${formattedCupSize}`" />
                </v-col>
                <v-col cols="6">
                    <PlanDetailItem label="G7 Liner" :value="formattedLinerDescription" />
                </v-col>
                <v-col cols="6">
                    <PlanDetailItem label="Cup anteversion / inclination">
                        <div class="d-flex">
                            <PlanDetailItemBox
                                label="Anteversion"
                                class="mr-4"
                                :value="formattedCupAnteversion"
                            />
                            <PlanDetailItemBox
                                label="Inclination"
                                :value="formattedCupInclination"
                            />
                        </div>
                    </PlanDetailItem>
                </v-col>
                <v-col cols="6">
                    <PlanDetailItem label="Plan generated" :value="formattedDate" />
                </v-col>
                <v-col cols="6">
                    <PlanDetailItem
                    :label="`${formattedSystem} ${formattedStemShape}`"
                    :value="formattedStemDescription"
                    />
                </v-col>
                <v-col cols="6">
                    <PlanDetailItem label="Femoral Head" :value="formattedHeadDescription" />
                </v-col>
                <v-col cols="6">
                    <PlanDetailItem label="Stem Anteversion" :value="formattedStemAnteversion" />
                </v-col>
                <v-col cols="6">
                    <PlanDetailItem label="Resection height (lt/gt/saddle)">
                        <div class="d-flex">
                            <PlanDetailItemBox
                                label="LT"
                                class="mr-4"
                                :value="formattedLesserTrochanterDistance"
                            />
                            <PlanDetailItemBox
                                label="GT"
                                :value="formattedGreaterTrochanterDistance"
                            />
                            <PlanDetailItemBox label="Saddle" :value="formattedSaddleDistance" />
                        </div>
                    </PlanDetailItem>
                </v-col>
            </v-row>
        </div>

        <PlanActions :project="project" :plan="plan" />

        <v-divider v-if="showSpinopelvic" class="my-5" />
        <div v-if="showSpinopelvic">
            <PlanItemSpinopelvic :id="plan.id" :plan="plan" />
        </div>
    </app-main-card>
</template>

<script setup lang="ts">
    import { formatLength } from '@/lib/format/formatLength';
    import { formatDate } from '@/lib/format/formatDate';
    import { PlanType } from '@/planView/store/types';
    import { computed } from 'vue';
    import PlanDetailItem from '@/planView/PlanDetailItem.vue';
    import PlanActions from '@/planView/PlanActions.vue';
    import { DateTime } from 'luxon';
    import { formatAngle } from '@/lib/format/formatAngle';
    import { NameUtil } from '@/lib/NameUtil';
    import {
        formatStemLabel,
        formatStemProfile,
        formatStemSystem,
        stemTypeInfo,
    } from '@/formus/catalog/stem';
    import {
        type AcetabularAngles,
        anatomicAngles,
        toRadiographic,
    } from '@/formus/anatomy/pelvis/acetabularAngles';
    import { toDegrees, toRadians } from '@/formus/anatomy/pelvis/anteversionInclination';
    import { catalogStem } from '@/api/catalog/catalogStem';
    import type { ApiPlan, ApiPlansCase } from '@/api/plan/types';
    import { spinopelvicFeature } from '@/lib/headMetaTags';
    import PlanItemSpinopelvic from '@/planView/PlanItemSpinopelvic.vue';
    import { useSpinopelvic } from '@/spinopelvic/store/store';
    import { formatNumberSign } from '@/lib/format/formatNumberSign';
    import { alignmentMode } from '@/planner/api/alignmentMode';
    import QRCodeContainer from '@/planView/qr-code/QRCodeContainer.vue';
    import { usePlansStore } from '@/planView/store/store';
    import PlanDetailItemBox from '@/planView/PlanDetailItemBox.vue';

    export interface Props {
        project: ApiPlansCase;
        plan: ApiPlan;
    }

    const store = usePlansStore();
    const props = defineProps<Props>();

    const title = computed(() => {
        if (props.plan.type === PlanType.Manual) {
            return 'User Approved Plan';
        } else if (props.plan.type === PlanType.Automated) {
            return 'Formus Plan';
        } else {
            return '';
        }
    });

    const formattedDate = computed(() => {
        return formatDate(DateTime.fromISO(props.plan.created_on));
    });

    const refNumber = computed(() => {
        return props.plan.reference_number;
    });

    const formattedLesserTrochanterDistance = computed(() => {
        const measurements = props.plan.measurements;
        if (measurements) {
            const lesserTrochanterDistance = measurements.resection_distances_lesser_trochanter;
            return formatLength(lesserTrochanterDistance, 0);
        }
        return '--';
    });

    const formattedGreaterTrochanterDistance = computed(() => {
        const measurements = props.plan.measurements;
        if (measurements) {
            const greaterTrochanterDistance = measurements.resection_distances_greater_trochanter;
            return formatLength(greaterTrochanterDistance, 0);
        }
        return '--';
    });

    const formattedSaddleDistance = computed(() => {
        const measurements = props.plan.measurements;
        if (measurements) {
            const saddleDistance = measurements.resection_distances_saddle;
            return formatLength(saddleDistance, 0);
        }
        return '--';
    });

    const displayQrCode = computed(() => {
        const mode = store.state.specifications?.cup_align_mode;
        if (mode) {
            return alignmentMode(mode) === 'APP';
        }

        return false;
    });

    const formattedStemShape = computed((): string => {
        const stem = catalogStem(props.plan.template.stem);
        return stem ? `(${formatStemProfile(stemTypeInfo(stem.type).profile)})` : '';
    });

    const formattedSystem = computed((): string => {
        const stem = catalogStem(props.plan.template.stem);
        return stem ? formatStemSystem(stem.system) : '';
    });

    const formattedStemDescription = computed((): string => {
        const stem = catalogStem(props.plan.template.stem);
        return stem ? formatStemLabel(stem) : '';
    });

    const formattedStemAnteversion = computed((): string => {
        const measurements = props.plan.measurements;
        if (measurements) {
            return formatAngle(measurements.stem_angle_anteversion ?? null);
        }
        return '--';
    });

    const formattedCupSize = computed((): string => {
        return props.plan.template.cup?.size ?? '';
    });

    const formattedHeadDescription = computed((): string => {
        const head = props.plan.template.head;
        if (head) {
            const size = head.size;
            const offset = formatNumberSign(head.offset);

            if (size && offset) {
                return `${formatLength(parseInt(size), 0)} ${offset} Offset`;
            }
        }

        return '';
    });

    const formattedLinerDescription = computed((): string => {
        const liner = props.plan.template.liner;
        const size = liner?.size;
        const type = liner?.type;

        if (size && type) {
            return `${size} (${type})`;
        }

        return '';
    });

    const radiographicRotation = computed((): AcetabularAngles<'radiographic'> => {
        const anatomicAnglesRadians = anatomicAngles(toRadians(props.plan.template.cup_rotation));
        return toDegrees(toRadiographic(anatomicAnglesRadians));
    });

    const formattedCupAnteversion = computed((): string => {
        return formatAngle(radiographicRotation.value?.anteversion ?? null);
    });

    const formattedCupInclination = computed((): string => {
        return formatAngle(radiographicRotation.value?.inclination ?? null);
    });

    const formattedPatientName = computed((): string => {
        const patient = props.project.patient;

        if (patient) {
            return NameUtil.formatCaseApiPatient(patient);
        }

        return '';
    });

    const formattedCaseNumber = computed(() => {
        const id = props.project.id;
        return id ? String(id) : '';
    });

    const showSpinopelvic = computed(() => {
        return spinopelvicFeature() && useSpinopelvic().hasData;
    });
</script>

<style scoped>
    ul,
    li {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .app-plan-card-wrapper {
        //display: flex;
        //flex-wrap: wrap;
    }

    .app-plan-card-content {
        display: flex;
        flex-basis: 0;
        flex-grow: 1;
        flex-direction: column;
    }

    .app-plan-card-title :deep(.v-card-item__content) {
        height: 100% !important;
    }
</style>
