<template>
    <div
        class="app-case-card-badge d-flex flex-column text-secondary text-center align-center justify-center mt-n3"
    >
        <v-icon :icon="icon" size="small" style="height: 40px; width: 30px" />
        <div class="status-text text-caption text-secondary-text" style="max-width: 62px">
            {{ status }}
        </div>
    </div>
</template>

<script setup lang="ts">
    import { type CaseStatus, CaseStatusState } from '@/api/caseSearch';
    import { computed } from 'vue';

    export interface Props {
        caseStatus: CaseStatus;
    }

    const props = defineProps<Props>();

    const iconMap = {
        [CaseStatusState.New]: 'f:case-new',
        [CaseStatusState.ProcessingCatStack]: 'f:case-processing',
        [CaseStatusState.Processing]: 'f:case-processing',
        [CaseStatusState.Complete]: 'f:case-completed',
        [CaseStatusState.Warning]: 'f:case-completed',
        [CaseStatusState.Unknown]: 'mdi-help',
        error: 'mdi-exclamation-thick',
    };

    const tooltipMap = {
        [CaseStatusState.New]: 'Awaiting imaging',
        [CaseStatusState.ProcessingCatStack]: 'Processing',
        [CaseStatusState.Processing]: 'Processing',
        [CaseStatusState.Complete]: 'Plan available',
        [CaseStatusState.Warning]: 'Manual planning required',
        [CaseStatusState.Unknown]: 'Unknown',
        error: 'Technical issue',
    };

    const statusKey = computed(() => {
        if (props.caseStatus.failed) {
            return 'error';
        }

        return props.caseStatus.state;
    });

    const icon = computed(() => iconMap[statusKey.value]);

    const status = computed(() => tooltipMap[statusKey.value]);
</script>

<style scoped>
    .app-case-card-badge {
        height: 88px;
        margin-right: 30px;
    }

    .status-text {
        line-height: 1.2;
    }
</style>
