import { watch, watchEffect } from 'vue';
import { watchImmediate } from '@vueuse/core';
import { stopAll, type StopHandle } from '@/util';
import { positionalPart } from '@/formus/geometry/matrix';
import { type FittedStemAxis } from '@/planner/fittedStem';
import type { AxisNode } from '@/planner/3d/axis';
import type { PlannerStore } from '@/planner/plannerStore';
import type { AxisMeasurement } from '@/formus/anatomy/measurements';
import { vector3, zero3 } from '@/formus/geometry/vector3';
import type { NumberArray3 } from '@/formus/geometry/apiVector';
import { useDeveloperSettings } from '@/planner/developerSettings';
import { planeTransform } from '@/planner/3d/plane';
import { logValidation } from '@/planner/logValidation';
import { formatMatrixBasis, formatMatrixEuler, indent, joinIndented } from '@/formus/geometry/formatMath';
import { computeBearingUrl, computeHeadUrl } from '@/planner/componentUrls';
import { updateCrossSection } from '@/planner/scene/crossSection';

/** Updates to the planner-store that relate to the stem, bearing and head */
export function updateStem(store: PlannerStore): StopHandle {
    return stopAll(
        watchImmediate(
            () => store.plannerMode !== 'cup',
            (show) => {
                store.nodes.stemGroup.visible = show;
                store.nodes.femoralGroup.visible = show;
            },
        ),
        watchImmediate(
            () => store.template?.stemUrl ?? null,
            (url) => (store.nodes.stem.geometrySource = url),
        ),
        watchImmediate(
            () => (store.template && store.catalog ? computeHeadUrl(store) : null),
            (url) => (store.nodes.head.geometrySource = url),
        ),
        watchImmediate(
            () => (store.template && store.catalog ? computeBearingUrl(store) : null),
            (url) => (store.nodes.bearing.geometrySource = url),
        ),
        watchImmediate(
            () => store.fittedStem,
            (fittedStem) => {
                if (fittedStem) {
                    store.nodes.stem.transform.copy(fittedStem.stemLocal);
                    store.nodes.head.transform.copy(fittedStem.headLocal);
                    if (fittedStem.bearingLocal) {
                        store.nodes.bearing.transform.copy(fittedStem.bearingLocal);
                    }
                }
            },
        ),
        watchEffect(() => _updateFemoralObjectTransforms(store)),
        watchEffect(() => _updateGroupTransforms(store)),
        watchEffect(() => _updateStemAxes(store)),
        watchEffect(() => _updateFemoralAxes(store)),
        _logStemChanges(store),
        watchEffect(() => _updateCrossSectionTransforms(store)),
        updateCrossSection(store, store.nodes.stemCoronalCrossSection),
        updateCrossSection(store, store.nodes.stemNeckCrossSection),
    );
}

function _logStemChanges(store: PlannerStore): StopHandle {
    return stopAll(
        watch(
            () => (store.isLoading || !store.template ? null : store.template.stemTransform),
            (localTransform) => {
                if (localTransform != null) {
                    logValidation(
                        joinIndented(2)([
                            'Stem manual-transform:',
                            ...formatMatrixEuler(localTransform),
                        ]),
                    );
                }
            },
        ),
        watch(
            () =>
                store.isLoading
                    ? null
                    : {
                        stemGroup: store.nodes.stemGroup.transform,
                        stem: store.nodes.stem.transform,
                        head: store.nodes.head.transform,
                    },
            (transforms) => {
                if (transforms) {
                    const { stemGroup, stem, head } = transforms;
                    const headWorld = stemGroup.clone().multiply(head);
                    const stemWorld = stemGroup.clone().multiply(stem);
                    logValidation(joinIndented(2)([
                        `Stem-group transforms (${store.plannerMode !== 'stem' ? 'retracted' : 'native'}}):`,
                        'stem-group:',
                        ...indent(2)(formatMatrixBasis(stemGroup)),
                        'head (world):',
                        ...indent(2)(formatMatrixBasis(headWorld)),
                        'stem (world):',
                        ...indent(2)(formatMatrixBasis(stemWorld)),
                    ]));
                }
            },
            { deep: true },
        ),
    );
}

/**
 * Update the transform of the operative-femur and inner-cortical-surface relative to the femoral-group
 */
function _updateFemoralObjectTransforms(store: PlannerStore): void {
    if (store.fittedStem) {
        // The femoral-group will be at the fitted-head-transform when the
        // femur is in its native (pre-operative) position, so the relative placement
        // of the femoral objects is the inverse of this transform.
        const femurInFemoralGroup = store.fittedStem.transform.clone().invert();
        store.nodes.operativeFemur.transform.copy(femurInFemoralGroup);
        store.nodes.operativeFemurInternal.transform.copy(femurInFemoralGroup);
        logValidation(joinIndented(2)([
            `Local femoral transforms:`,
            'femur:',
            ...indent(2)(formatMatrixBasis(store.nodes.operativeFemur.transform)),
            'femur-internal:',
            ...indent(2)(formatMatrixBasis(store.nodes.operativeFemurInternal.transform)),
        ]));
    } else {
        // If the fitted-head-transform is not available the femoral-group will
        // have an identity transform, so the femoral objects have the same.
        store.nodes.operativeFemur.transform.identity();
        store.nodes.operativeFemurInternal.transform.identity();
        logValidation('Local femoral transforms set to identity');
    }
}

function _updateGroupTransforms(store: PlannerStore): void {
    if (store.template && store.fittedStem) {
        const stemGroupTransform = store.fittedStem.transform
            .clone()
            .multiply(store.template.stemTransform);
        store.nodes.stemGroup.transform.copy(stemGroupTransform);
        store.nodes.femoralGroup.transform.copy(store.fittedStem.transform);

        if (store.plannerMode !== 'stem') {
            // If we are not in stem-mode we want to translate the stem-group so that its
            // position matches the cup-group (the head is 'inside' the cup)
            const hjc = positionalPart(store.nodes.cupGroup.transform);
            store.nodes.stemGroup.transform.setPosition(hjc);

            // Translate the femoral-group by the same amount, preserving the transform between
            // the femur and the stem (the stem-transform).
            const shiftedFemoralGroupPosition = positionalPart(store.fittedStem.transform)
                .add(hjc)
                .sub(positionalPart(stemGroupTransform));
            store.nodes.femoralGroup.transform.setPosition(shiftedFemoralGroupPosition);
        }

        store.nodes.stemGroup.showAxes = useDeveloperSettings().show3dFeatures || false;
    } else {
        store.nodes.stemGroup.showAxes = false;
    }
}

function _updateStemAxes(store: PlannerStore) {
    function updateStemAxis(state: FittedStemAxis | undefined, node: AxisNode) {
        if (state) {
            node.visible = useDeveloperSettings().show3dFeatures || false;
            node.transform.setPosition(state.position);
            node.direction.copy(state.direction);
        } else {
            node.visible = false;
        }
    }

    updateStemAxis(store.fittedStem?.axesLocal?.neckAxis, store.nodes.stemNeckAxis);
    updateStemAxis(store.fittedStem?.axesLocal?.shaftAxis, store.nodes.stemShaftAxis);
    updateStemAxis(store.fittedStem?.axesLocal?.paAxis, store.nodes.stemPaAxis);
}

function _updateCrossSectionTransforms(store: PlannerStore) {
    if (store.fittedStem?.axesLocal) {
        store.nodes.stemCoronalCrossSection.transform = planeTransform(
            zero3(),
            store.fittedStem.axesLocal.paAxis.direction.clone().negate(),
        );
        store.nodes.stemNeckCrossSection.transform = planeTransform(
            store.fittedStem.resectionPlaneLocal.origin,
            store.fittedStem.resectionPlaneLocal.normal,
            store.fittedStem.resectionPlaneLocal.x,
        );
    }
}

function _updateFemoralAxes({ femoralFeatures, fittedStem, nodes }: PlannerStore) {
    if (!femoralFeatures || !fittedStem) {
        nodes.femoralShaftAxis.visible = false;
        nodes.femoralProximalShaftAxis.visible = false;
        nodes.femoralNeckAxis.visible = false;
        nodes.femoralAnteversionNeckAxis.visible = false;
        nodes.femoralAnteversionCondylarAxis.visible = false;
        return;
    }

    const femurInFemoralGroup = fittedStem.transform.clone().invert();
    const { shaftAxis, proximalShaftAxis, neckAxis, anteversionNeck, anteversionCondylar } =
        femoralFeatures;

    const updateAxis = (node: AxisNode, direction: NumberArray3, position: NumberArray3) => {
        node.direction = vector3(direction).transformDirection(femurInFemoralGroup);
        node.transform.setPosition(vector3(position).applyMatrix4(femurInFemoralGroup));
        node.visible = useDeveloperSettings().show3dFeatures || false;
    };

    const updateAxisFromMeasurement = (node: AxisNode, measurement: AxisMeasurement) =>
        updateAxis(node, measurement.value, measurement.position);

    updateAxisFromMeasurement(nodes.femoralShaftAxis, shaftAxis);
    updateAxisFromMeasurement(nodes.femoralProximalShaftAxis, proximalShaftAxis);
    updateAxisFromMeasurement(nodes.femoralNeckAxis, neckAxis);

    updateAxis(nodes.femoralAnteversionCondylarAxis, anteversionCondylar, neckAxis.position);
    updateAxis(nodes.femoralAnteversionNeckAxis, anteversionNeck, neckAxis.position);
}
