<template>
    <v-data-table
        class="warnings-table"
        :items="tableWarning"
    >
        <!--
            Removes pagination
            https://stackoverflow.com/questions/76211136/cant-remove-footer-pagination-from-v-data-table-in-vue-3-2
        -->
        <template #headers></template>
        <template #bottom></template>
    </v-data-table>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useSpinopelvic } from '@/spinopelvic/store/store';
    import {
        LUMBAR_LORDOSIS_LIMIT,
        PFA_LOWER_LIMIT,
        PFA_UPPER_LIMIT,
        STANDING_PELVIC_TILT_LIMIT,
    } from '@/spinopelvic/components/constants';
    import type {
        SpinopelvicCalculations,
        SpinopelvicWarnings,
    } from '@/spinopelvic/components/types';
    import { formatAngle } from '@/lib/format/formatAngle';

    const props = defineProps<{
        calculations: SpinopelvicCalculations;
        warnings: SpinopelvicWarnings;
    }>();

    const store = useSpinopelvic();
    const tableWarning = computed(() => {
        let result = [];

        if (props.warnings.standingPTWarning) {
            result.push({
                label: 'Spinopelvic Tilt',
                value: `> ${STANDING_PELVIC_TILT_LIMIT}°`,
            });
        }

        if (props.warnings.lumbarLordosisWarning) {
            result.push({
                label: 'Lumbar Lordosis',
                value: `< ${LUMBAR_LORDOSIS_LIMIT}°`,
            });
        }

        if (props.warnings.pelvicFemoralAngleWarning) {
            let warning = '';
            if (store.measurements.pelvicFemoralAngle < PFA_LOWER_LIMIT) {
                warning = `< ${PFA_LOWER_LIMIT}°`;
            } else if (store.measurements.pelvicFemoralAngle > PFA_UPPER_LIMIT) {
                warning = `> ${PFA_UPPER_LIMIT}°`;
            }
            result.push({
                label: 'Pelvic Femoral Angle',
                value: warning,
            });
        }

        if (props.warnings.inclinationLowerWarning) {
            result.push({
                label: 'Cup Inclination',
                value: `< ${formatAngle(props.warnings.limits.inclinationLowerLimit, 2)}`,
            });
        }

        if (props.warnings.inclinationUpperWarning) {
            result.push({
                label: 'Cup Inclination',
                value: `> ${formatAngle(props.warnings.limits.inclinationUpperLimit, 2)}`,
            });
        }

        if (props.warnings.anteversionLowerWarning) {
            result.push({
                label: 'Cup Anteversion',
                value: `< ${formatAngle(props.warnings.limits.anteversionLowerLimit, 2)}`,
            });
        }

        if (props.warnings.anteversionUpperWarning) {
            result.push({
                label: 'Cup Anteversion',
                value: `> ${formatAngle(props.warnings.limits.anteversionUpperLimit, 2)}`,
            });
        }

        return result;
    });
</script>

<style scoped lang="scss">
    :deep(.v-table) {
        background-color: rgb(var(--v-theme-surface-light)) !important;
    }

    .warnings-table :deep(table) {
        border-top: none;
        //margin-bottom: 10px;
    }

    .warnings-table :deep(table tbody tr td) {
        color: var(--planner-orange);
        background-color: rgb(var(--v-theme-surface-light)) !important;
        padding-left: 18px;
    }
</style>
