import { asyncTimeout, logger } from '@/util';
import { search, type SearchResult } from '@/api/caseSearch';

const log = logger('poll-cases');

const POLL_INTERVAL_MILLISECONDS = 10 * 1000;

/**
 * Repeatedly fetch cases until cancelled
 */
export async function pollCases(
    searchText: string,
    page: number,
    onFetch: (result: SearchResult | string) => void,
    options?: {
        signal?: AbortSignal;
    },
): Promise<void> {
    const fetch = async (): Promise<SearchResult | string> => {
        return await search(searchText, page);
    };

    try {
        do {
            options?.signal?.throwIfAborted();

            // Attempt to fetch cases
            const response = await fetch();

            options?.signal?.throwIfAborted();

            // Call the callback with the result
            onFetch(response);

            options?.signal?.throwIfAborted();

            await asyncTimeout(POLL_INTERVAL_MILLISECONDS, options?.signal);
            // eslint-disable-next-line no-constant-condition
        } while (true);
    } catch (e: unknown) {
        if (options?.signal?.aborted) {
            log.debug('cases polling cancelled');
            return;
        }
        throw e;
    }
}