<template>
    <div class="app-hint">
        <div class="app-hint-icon">
            <v-icon :icon="hintIcon" />
        </div>
        <slot></slot>
    </div>
</template>

<style scoped>
    .app-hint {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .app-hint-icon {
        color: v-bind('hintIconColour');
        margin-right: 10px;
    }
</style>
<script setup lang="ts">
    import { toRefs } from 'vue';

    export interface Props {
        danger?: boolean;
    }

    const props = defineProps<Props>();
    const { danger } = toRefs(props);

    const hintIcon = danger.value ? 'mdi-exclamation-thick' : 'mdi-information-outline';
    const hintIconColour = danger.value ? 'var(--planner-yellow)' : 'var(--planner-blue-button)';
</script>
