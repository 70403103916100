import { client } from '@/api/http';
import { type AxiosRequestConfig, HttpStatusCode } from 'axios';
import type { ApiCaseCatStacks } from '@/api/cat-stacks/types';
import { AppError } from '@/app/appErrorStore';
import { errorDetail } from '@/planner/api/errorDetail';

export const fetchCatStacks = async (
    caseId: number,
    config?: AxiosRequestConfig,
): Promise<ApiCaseCatStacks | null> => {
    const response = await client.get<ApiCaseCatStacks>(`/cases/${caseId}/cat-stacks`, config);
    const { status, data } = response;
    if (status === HttpStatusCode.Ok) {
        return data;
    } else if (status === HttpStatusCode.Forbidden) {
        throw new AppError(
            'Case not found' + errorDetail(data),
            'This case does not exist or you do not have permission to view it.',
        );
    } else if (status === HttpStatusCode.NotFound) {
        return null;
    } else {
        throw Error(`Failed to load cat-stack`);
    }
};
