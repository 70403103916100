<template>
    <v-row :id="elementId" />
</template>

<script lang="ts" setup>
    import { computed, onBeforeUnmount, onMounted, watch } from 'vue';
    import assert from 'assert';
    import Plotly, { type Shape } from 'plotly.js-basic-dist';
    import {
        ANTEVERSION_Y_MAX,
        ANTEVERSION_Y_MIN,
        INCLINATION_X_MAX,
        INCLINATION_X_MIN,
    } from '@/spinopelvic/components/constants';
    import {
        anteversionSafeZonePath,
        inclinationSafeZonePath,
        patientSafeZonePath,
        yAxisCupCSIArray,
    } from '@/spinopelvic/components/NomogramPlotUtils';
    import { makeSafeZoneDataSet } from '@/spinopelvic/components/safeZoneDataSet';

    import type { Config, Data, Layout } from 'plotly.js';
    import type { SpinopelvicCalculations } from '@/spinopelvic/components/types';
    import colors_v1_8 from '@/plugins/colors_v1_8';

    const props = defineProps<{
        id: number;
        cupInclination: number;
        cupAnteversion: number;
        calculations: SpinopelvicCalculations;
    }>();

    const elementId = computed(() => `${props.id}-nomogram-plot`);

    const plotElement = computed(() => {
        const element = document.getElementById(elementId.value);
        assert.ok(element, 'no plot element found.');
        return element;
    });

    const safeZoneDataSet = computed(() => makeSafeZoneDataSet(props.calculations));

    const plotData = computed((): Data[] => {
        const cupAnteversionLower = {
            x: safeZoneDataSet.value.xAxisArray,
            y: yAxisCupCSIArray(
                safeZoneDataSet.value.xAxisArray,
                props.calculations.csiLowerBound,
                props.calculations.pelvicTilt,
            ),
            mode: 'lines',
            line: {
                color: 'grey',
                width: 1,
            },
            showlegend: false,
        };
        const cupAnteversionUpper = {
            x: safeZoneDataSet.value.xAxisArray,
            y: yAxisCupCSIArray(
                safeZoneDataSet.value.xAxisArray,
                props.calculations.csiUpperBound,
                props.calculations.pelvicTilt,
            ),
            mode: 'lines',
            line: {
                color: 'grey',
                width: 1,
            },
            showlegend: false,
        };
        const cupAnteversionMarker = {
            x: [props.cupInclination],
            y: [props.cupAnteversion],
            mode: 'markers',
            marker: {
                symbol: 'circle-open-dot',
                size: 20,
                color: 'black',
            },
            name: 'Planned Orientation',
        };

        return [cupAnteversionLower, cupAnteversionUpper, cupAnteversionMarker];
    });

    const anteversionSafeZoneShape = computed(
        (): Partial<Shape> => ({
            type: 'path',
            path: anteversionSafeZonePath(safeZoneDataSet.value),
            fillcolor: colors_v1_8['planner-grey-1'],
            opacity: 0.2,
            line: {
                width: 0,
            },
        }),
    );

    const inclinationSafeZoneShape = computed(
        (): Partial<Shape> => ({
            type: 'path',
            path: inclinationSafeZonePath(safeZoneDataSet.value),
            fillcolor: colors_v1_8['planner-grey-1'],
            opacity: 0.2,
            line: {
                width: 0,
            },
        }),
    );

    const patientSafeZoneShape = computed(
        (): Partial<Shape> => ({
            type: 'path',
            path: patientSafeZonePath(safeZoneDataSet.value, props.calculations),
            fillcolor: colors_v1_8['planner-green'],
            opacity: 0.5,
            line: {
                width: 1,
            },
        }),
    );

    const plotLayout = computed((): Partial<Layout> => {
        const plotBoundary: Partial<Shape> = {
            type: 'rect',
            x0: INCLINATION_X_MIN,
            y0: ANTEVERSION_Y_MIN,
            x1: INCLINATION_X_MAX,
            y1: ANTEVERSION_Y_MAX,
            line: {
                width: 1,
                color: colors_v1_8['planner-grey'],
            },
        };

        return {
            width: 700,
            height: 500,
            font: {
                family: 'Proxima Nova',
            },
            xaxis: {
                title: 'Inclination (\xB0)',
                range: [INCLINATION_X_MIN, INCLINATION_X_MAX],
                zeroline: false,
            },
            yaxis: {
                title: 'Anteversion (\xB0)',
                range: [ANTEVERSION_Y_MIN, ANTEVERSION_Y_MAX],
                zeroline: false,
            },
            showlegend: true,
            legend: {
                orientation: 'h',
            },
            autosize: true,
            margin: {
                t: 0,
                b: 40,
                l: 40,
                r: 40,
            },
            shapes: [
                plotBoundary,
                anteversionSafeZoneShape.value,
                inclinationSafeZoneShape.value,
                patientSafeZoneShape.value,
            ],
        };
    });

    const plotConfig = computed(
        (): Partial<Config> => ({
            staticPlot: true,
            displayModeBar: false,
        }),
    );

    function plot() {
        Plotly.newPlot(plotElement.value, plotData.value, plotLayout.value, plotConfig.value);
    }

    onMounted(plot);

    onBeforeUnmount(() => {
        Plotly.purge(plotElement.value);
    });

    watch([() => props.calculations, () => props.cupInclination, () => props.cupAnteversion], plot);
</script>
