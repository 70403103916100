<template>
    <v-slider
        :model-value="value.currentIndex"
        @update:model-value="onUpdateSlider"
        :thumb-size="24"
        min="0"
        color="blue"
        step="1"
        :max="value && value.count ? value.count - 1 : 0"
        thumb-label="always"
        label="Adjust plane"
    />
</template>

<script setup lang="ts">
    import type { CatStackValue } from '@/scanView/types';

    const props = defineProps<{
        value: CatStackValue;
    }>();

    const onUpdateSlider = (value: number) => {
        // eslint-disable-next-line vue/no-mutating-props
        props.value.currentIndex = value;
    };
</script>
