<template>
    <div class="mt-5 mb-10 text-h6 font-weight-regular">Hip-Spine Assessment</div>
    <v-row class="ml-0">
        <v-col class="spinopelvic">
            <NomogramPlot
                :id="id"
                :cup-inclination="cupInclination"
                :cup-anteversion="cupAnteversion"
                :calculations="calculations"
            />
        </v-col>
        <v-col>
            <SpinopelvicParameterWarnings
                v-if="warnings && calculations"
                :warnings="warnings"
                :calculations="calculations"
            />
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import NomogramPlot from '@/spinopelvic/components/NomogramPlot.vue';
    import SpinopelvicParameterWarnings from '@/spinopelvic/components/SpinopelvicParameterWarnings.vue';
    import { useSpinopelvic } from '@/spinopelvic/store/store';
    import {
        makeSpinoPelvicCalculations,
        makeSpinoPelvicLandmarks,
    } from '@/spinopelvic/components/calculations';
    import { makeSpinopelvicWarnings } from '@/spinopelvic/components/warnings';
    import type { ApiPlan } from '@/api/plan/types';
    import {
        type AcetabularAngles,
        anatomicAngles,
        toRadiographic,
    } from '@/formus/anatomy/pelvis/acetabularAngles';
    import { toDegrees, toRadians } from '@/formus/anatomy/pelvis/anteversionInclination';
    import { usePlansStore } from '@/planView/store/store';
    import { verify } from '@/lib/verify';

    const props = defineProps<{
        id: number;
        plan: ApiPlan;
    }>();

    const store = useSpinopelvic();
    const planStore = usePlansStore();

    const radiographicRotation = computed((): AcetabularAngles<'radiographic'> => {
        const anatomicAnglesRadians = anatomicAngles(toRadians(props.plan.template.cup_rotation));
        return toDegrees(toRadiographic(anatomicAnglesRadians));
    });

    const cupAnteversion = computed((): number => {
        return radiographicRotation.value.anteversion;
    });

    const cupInclination = computed((): number => {
        return radiographicRotation.value.inclination;
    });

    const calculations = computed(() => {
        const alignmentMode = verify(planStore.alignmentMode, 'Alignment mode not set');
        return makeSpinoPelvicCalculations(
            makeSpinoPelvicLandmarks(planStore.state.study.landmarks),
            store.measurements,
            alignmentMode,
        );
    });

    const warnings = computed(() => {
        if (calculations.value) {
            return makeSpinopelvicWarnings(
                cupInclination.value,
                cupAnteversion.value,
                store.measurements,
                calculations.value,
            );
        }

        return null;
    });
</script>

<style scoped lang="scss">
    .spinopelvic {
        flex-grow: 3;
    }
</style>
