import type { ApiFittedComponents } from '@/api/fittedComponents/getFittedComponents';
import { type ComponentCatalog, getCatalogComponent } from '@/formus/catalog/catalog';
import type { ApiFittedCup } from '@/api/fittedComponents/fittedCup';
import type { Url } from '@/formus/types';
import type { ApiFittedStem } from '@/api/fittedComponents/fittedStem';
import type { HeadOffset } from '@/formus/catalog/head';
import type { Matrix4 } from 'three';
import { vector3 } from '@/formus/geometry/vector3';
import { matrixFromApi, matrixToApi } from '@/formus/geometry/apiMatrix';
import { positionalPart } from '@/formus/geometry/matrix';

/**
 * Find a fitted-cup-and-liner(-bearing) combination given the catalog urls of a cup, a liner, and bearing
 *
 * This data is almost the same as that on the API, but the URL of the embedded liner
 * is adjusted to the given one.
 */
export function findFittedCup(
    fittedComponents: ApiFittedComponents,
    catalog: ComponentCatalog,
    catalogCupUrl: Url,
    catalogLinerUrl: Url,
    catalogBearingUrl: Url | null,
): ApiFittedCup {
    // Get the set of all fitted components
    // Find the specified fitted cup (and 'original' fitted-liner) in the set of fitted components
    const cupAndOriginalLiner = fittedComponents.cups.get(catalogCupUrl);
    if (!cupAndOriginalLiner) {
        throw Error(`Could not find cup matching '${catalogCupUrl}' in fitted components`);
    }

    // Check the specified liner catalog-component exists
    if (!catalog.liners.has(catalogLinerUrl)) {
        throw Error(`Could not find liner component ${catalogLinerUrl}`);
    }

    // Check the specified bearing catalog-component exists
    if (catalogBearingUrl && !catalog.bearings.has(catalogBearingUrl)) {
        throw Error(`Could not find bearing component ${catalogBearingUrl}`);
    }

    // Return the fitted-cup and a fitted-liner created by combining the catalog-component with placement
    // info from the original liner and the placement info from the bearing
    return {
        ...cupAndOriginalLiner,
        liner: {
            links: [{ rel: 'component', title: null, href: catalogLinerUrl }],
            catalogUrl: catalogLinerUrl,
            tmatrix: [...cupAndOriginalLiner.liner.tmatrix],
            head_centre: [...cupAndOriginalLiner.liner.head_centre],
        },
        bearing: (catalogBearingUrl && cupAndOriginalLiner.bearing) ? {
            links: [{ rel: 'component', title: null, href: catalogBearingUrl }],
            catalogUrl: catalogBearingUrl,
            tmatrix: [...cupAndOriginalLiner.bearing.tmatrix],
            head_centre: [...cupAndOriginalLiner.bearing.head_centre],
            centre_line_axis: [...cupAndOriginalLiner.bearing.centre_line_axis],
        } : null,
    };
}

/**
 * Find a fitted-stem-and-head combination given the catalog urls of a stem and a head
 *
 * This data is not the same as that on the API, as the URL, transform and offset of the
 * original fitted head needs to adapted to the desired head and its offset.
 */
export function findFittedStem(
    fittedComponents: ApiFittedComponents,
    catalog: ComponentCatalog,
    catalogStemUrl: Url,
    catalogHeadUrl: Url,
): ApiFittedStem {
    // Find the specified fitted stem (and 'original' fitted-head) in the set of fitted components
    const stemAndOriginalHead = fittedComponents.stems.get(catalogStemUrl);
    if (!stemAndOriginalHead) {
        throw Error(`Could not find stem matching '${catalogStemUrl}' in fitted components`);
    }

    // Get the specified head catalog-component
    const headComponent = getCatalogComponent(catalog.heads, catalogHeadUrl);
    const headTransform = headTransformWithOffset(stemAndOriginalHead, headComponent.offset);

    // Return the fitted-stem and a head adjusted to have the correct offset
    return {
        ...stemAndOriginalHead,
        head: {
            links: [{ rel: 'component', title: null, href: catalogHeadUrl }],
            catalogUrl: catalogHeadUrl,
            tmatrix: matrixToApi(headTransform),
            offset: headComponent.offset,
        },
    };
}

/**
 * Calculate the transform that puts a head wth the given offset on the given stem
 *
 * The fitted-stem on the API gives the transform for the fitted-head, which is already
 * at some particular offset. To find the transform for a head with what might be a different
 * offset we need to shift the position according to the difference between the fitted-offset
 * and the desired offset along the neck-axis of the stem.
 */
function headTransformWithOffset(fittedStem: ApiFittedStem, offset: HeadOffset): Matrix4 {
    const stemNeckAxis = vector3(fittedStem.neck_axis);
    const fittedHeadTransform = matrixFromApi(fittedStem.head.tmatrix);
    const offsetDifference = offset - fittedStem.head.offset;
    const headPosition = positionalPart(fittedHeadTransform)
        .add(stemNeckAxis.multiplyScalar(offsetDifference));

    return fittedHeadTransform.setPosition(headPosition)
}

