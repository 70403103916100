<template>
    <!--
        The Level from wikipedia is:
            Level L (Low)          7% of data bytes can be restored.
            Level M (Medium)       15% of data bytes can be restored.
            Level Q (Quartile)[67] 25% of data bytes can be restored.
            Level H (High)         30% of data bytes can be restored.

        Note: the QRCode is renders as SVG so that it can be printed. The default canvas element does
        not print.

        see
          - https://en.wikipedia.org/wiki/QR_code#Error_correction
          - https://github.com/scopewu/qrcode.vue
          - https://www.npmjs.com/package/qrcode.vue
    -->
    <div>
        <qrcode-vue
            v-if="transferValue"
            :value="transferValue"
            :size="size"
            level="L"
            render-as="svg"
        />
        <v-alert
            v-else
            border="start"
            :icon="false"
            :closable="false"
            colored-border
            type="error"
            variant="tonal"
            elevation="0"
        >
            Error rendering QR Code.
        </v-alert>
    </div>
</template>

<script setup lang="ts">
    import QrcodeVue from 'qrcode.vue';
    import anylogger from 'anylogger';
    import { makeQrCodeData } from '@/planView/qr-code/makeQrCodeData';
    import { usePlansStore } from '@/planView/store/store';
    import { computed } from 'vue';
    import type { ApiPlan } from '@/api/plan/types';

    export interface Props {
        plan: ApiPlan;
        size: number;
    }

    const props = defineProps<Props>();

    const store = usePlansStore();

    const log = anylogger('QRCode');

    const transferValue = computed((): string | null => {
        try {
            const preoperativePlan = makeQrCodeData(store.state, props.plan.template, props.plan);
            const data = JSON.stringify(preoperativePlan);
            console.info(`QR code data: ${data}`);
            return data;
        } catch (e) {
            log.error(`QR code data generation failed. ${e}`);
            return null;
        }
    });
</script>
