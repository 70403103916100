import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import colors_v1_8 from '@/plugins/colors_v1_8';
import { createVuetify } from 'vuetify';
import { VBtn } from 'vuetify/components';
import { formusLightTheme } from '@/plugins/formusTheme';
import { f, formusIcons } from '@/plugins/vuetify/formusIcons';
import { mdi } from 'vuetify/iconsets/mdi';

export function registerVuetify() {
    return createVuetify({
        components,
        directives,
        theme: {
            defaultTheme: 'formus-light',
            themes: {
                light_v1_8: {
                    dark: false,
                    colors: { ...colors_v1_8 },
                },
                'formus-light': formusLightTheme,
            },
        },
        aliases: {
            CustomBtn: VBtn,
            VBtnPrimary: VBtn,
            VBtnSecondary: VBtn,
            // VBtnSecondary: AppButton,
        },
        icons: {
            defaultSet: 'mdi', // Set your custom set as the default
            aliases: {
                ...formusIcons
            },
            sets: {
                mdi, // Retain Material Design Icons for fallback
                f,   // Register the custom icon set
            },
        },
        defaults: {
            VChipGroup: {
                VChip: {
                    filter: true,
                    class: ['text-subtitle-1', 'font-weight-bold', 'px-6'],
                    color: 'primary',
                    variant: 'flat',
                    style: 'height: 40px',
                },
            },
            VBtn: {
                // style: 'text-transform: none',
                // rounded: 'pill',
                // height: 48,
                elevation: 0,
                // class: ['px-4', 'py-3', 'text-none', 'text-center'],
            },
            AppSaveBtn: {
                color: 'primary',
                height: '60',
                class: ['px-6', 'py-3', 'text-none', 'text-center', 'text-subtitle-bold'],
                rounded: 'pill',
                elevation: 0,
            },
            VBtnPrimary: {
                color: 'primary',
                height: '60',
                class: ['px-6', 'py-3', 'text-none'],
                rounded: 'pill',
                elevation: 0,
            },
            VBtnSecondary: {
                color: 'secondary',
                height: '60',
                rounded: 'pill',
                elevation: 0,
                class: ['px-6', 'py-3', 'v-btn--secondary', 'text-none'],
            },
            CustomBtn: {
                rounded: 'shaped',
                style: 'min-height: 40px',
            },
        },
    });
}
