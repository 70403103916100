import type { StateHolder } from '@/util/dataState';
import type { ApiUserObject } from '@/api/user/me';

export enum HipRoleNames {
    Admin = 'admin',
    OrgAdmin = 'org_admin',
    Surgeon = 'surgeon',
    SurgeonAssist = 'surgeon_assist',
    Sales = 'sales',
}

export type CurrentUserState = {
    user: ApiUserObject | null;
} & StateHolder;
