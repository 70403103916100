<template>
    <div class="d-flex flex-column justify-center header-center-wrapper">
        <div class="case-title mb-2">{{ caseSync.caseName }}</div>
        <div class="case-menu-items w-100 justify-center">
            <v-hover>
                <template v-slot:default="{ isHovering, props: hoverProps }">
                    <app-menu-item
                        v-bind="{ ...hoverProps }"
                        class="mr-5"
                        :background="menuBackground(ROUTES.EDIT_CASE, isHovering)"
                        icon="settings"
                        tooltip="Case Information"
                        :to="caseSync.routeTo(ROUTES.EDIT_CASE)"
                        :disabled="!caseSync.routeTo(ROUTES.EDIT_CASE)"
                        disabled-bg-class="bg-secondary"
                    />
                </template>
            </v-hover>
            <v-hover>
                <template v-slot:default="{ isHovering, props: hoverProps }">
                    <app-menu-item
                        v-bind="{ ...hoverProps }"
                        class="mr-5"
                        :background="menuBackground(ROUTES.CT_SCAN, isHovering)"
                        icon="dicoms"
                        tooltip="CT Scans"
                        :to="caseSync.routeTo(ROUTES.CT_SCAN)"
                        :disabled="!caseSync.routeTo(ROUTES.CT_SCAN)"
                        disabled-bg-class="bg-secondary"
                    />
                </template>
            </v-hover>
            <v-hover>
                <template v-slot:default="{ isHovering, props: hoverProps }">
                    <app-menu-item
                        v-bind="{ ...hoverProps }"
                        class="mr-5"
                        :background="menuBackground(ROUTES.PLANNER, isHovering)"
                        icon="planner"
                        tooltip="3D Planner"
                        :to="caseSync.routeTo(ROUTES.PLANNER)"
                        :disabled="!caseSync.routeTo(ROUTES.PLANNER)"
                        disabled-bg-class="bg-secondary"
                    />
                </template>
            </v-hover>
            <v-hover>
                <template v-slot:default="{ isHovering, props: hoverProps }">
                    <app-menu-item
                        v-bind="{ ...hoverProps }"
                        class="mr-5"
                        :background="menuBackground(ROUTES.PLANS, isHovering)"
                        icon="plans"
                        tooltip="Plans"
                        :to="caseSync.routeTo(ROUTES.PLANS)"
                        :disabled="!caseSync.routeTo(ROUTES.PLANS)"
                        disabled-bg-class="bg-secondary"
                    />
                </template>
            </v-hover>
            <v-hover v-if="useUserStore().isAdmin && caseSync.case_">
                <template v-slot:default="{ isHovering, props: hoverProps }">
                    <app-menu-item
                        v-bind="{ ...hoverProps }"
                        :background="menuBackground(ROUTES.ADMIN, isHovering)"
                        icon="mdi-tools"
                        tooltip="Admin"
                        :to="adminCaseRoute(caseSync.case_?.id!)"
                    />
                </template>
            </v-hover>
        </div>
    </div>
</template>

<script setup lang="ts">
    /**
     * Implements software requirement: H1SR-80, H1SR-85
     *
     * @link https://formuslabs.youtrack.cloud/issue/H1SR-80/The-Home-Button-Formus-Logo-directs-the-user-to-the-dashboard
     * @link https://formuslabs.youtrack.cloud/issue/H1SR-85/Revoke-user-authentication-when-user-clicks-the-logout-button
     */
    import AppMenuItem from '@/components/AppMenuItem.vue';
    import { adminCaseRoute, ROUTES } from '@/router';
    import { useCaseSyncStore } from '@/app/useCaseSyncStore';
    import { useUserStore } from '@/app/userStore/store';
    import { useRoute } from 'vue-router';

    const caseSync = useCaseSyncStore();

    const route = useRoute();
    const menuBackground = function (name: string, isHovering: boolean | null) {
        if (isHovering) return 'light';
        //
        if (name === route.name) return 'light';
        return 'dark';
    };
</script>

<style scoped>
    .header-center-wrapper {
        left: calc(50vw - 200px);
        margin-top: 20px;
        position: absolute;
        top: 0;
        width: 400px;
    }

    .case-title {
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: #fff;
        font-weight: 400;
        line-height: 2rem;
        font-size: 1.5rem;
        letter-spacing: normal;
        text-transform: uppercase;
    }

    .case-menu-items {
        z-index: 910;
        display: flex;
    }
</style>
