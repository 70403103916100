import { taggedLogger } from '@/util/taggedLogger';
import { type Logger } from '@/util/logger';
import { useDeveloperSettings } from '@/planner/developerSettings';
import type { Vector3 } from 'three';
import { formatFloat, formatVector } from '@/formus/geometry/formatMath';

/**
 * Log a message:
 *   1. With a `[validation]` tag prefixed
 *   2. At the validation log-level (See {@link validationLogLevel})
 */
export function logValidation(message?: unknown, ...args: unknown[]) {
    _logger(useDeveloperSettings().validationLogLevel ?? 'debug', message, ...args);
}

/** Default tag applied to validation logging */
const _VALIDATION_LOG_TAG = 'validation' as const;

const _logger: Logger = taggedLogger(_VALIDATION_LOG_TAG);

export function logCalculation(title: string, values: [string, number | Vector3][]) {
    const formatValue = (value: number | Vector3) => typeof (value) == 'number' ?
        formatFloat(value) :
        formatVector(value);
    logValidation([
        title,
        ...values.map(([header, value]) => `  ${header}: ${formatValue(value)}`),
    ].join('\n'));
}
