import { watchImmediate } from '@vueuse/core';
import { trackballControls, type TrackballControls } from '@/planner/3d/trackballControls';
import { EventDispatcher, PerspectiveCamera, Vector3 } from 'three';
import { type StopHandle, stopAll, taggedLogger } from '@/util';
import { type RenderingContext } from '@/planner/3d/updateRendering';
import LPS from '@/formus/anatomy/LPS';
import { floatsApproxEqual, vector3sApproxEqual } from '@/formus/geometry/approxEquals';

const log = taggedLogger('3D');

/**
 * Properties of a three-js [camera]{@link PerspectiveCamera} that are shared with the
 * [camera-node]{@link PerspectiveCameraNode}
 */
type ThreeCameraState = {
    position: Vector3;
    up: Vector3;
    target: Vector3;
    zoom: number;
    fov: number;
    near: number;
    far: number;
};

/** Node representing the state of a [camera]{@link PerspectiveCamera} */
export type PerspectiveCameraNode = ThreeCameraState & {
    readonly type: 'perspective-camera';
    animate: boolean;
};

/** Creates a node representing the state of a [camera]{@link PerspectiveCamera} */
export function perspectiveCameraNode(
    initialState?: Partial<PerspectiveCameraNode>,
): PerspectiveCameraNode {
    const target = initialState?.target?.clone() ?? new Vector3();
    return {
        type: 'perspective-camera',
        position:
            initialState?.position?.clone() ??
            target.clone().add(LPS.Anterior.multiplyScalar(DEFAULT_CAMERA_DISTANCE)),
        target: target,
        ...DEFAULT_CAMERA_STATE,
        ...initialState,
    };
}

export function updatePerspectiveCamera(context: RenderingContext): StopHandle {
    const controls = trackballControls(context.canvas, context.camera);
    applyStateToThreeCamera(context.cameraNode, context.camera, controls);

    let animation: CameraAnimation | null = null;
    let nextAnimationId: number = 1;
    let inInteraction: boolean = false;
    let suppressControlsChange: boolean = false;

    context.beforeRender(() => {
        if (animation) {
            if (updateAnimation(context.camera, controls, animation) === 'finished') {
                animation = null;
            }
            // Changing the position or target of the camera in update-animation can
            // cause the controls update to emit a 'change' event. We need to suppress this
            // because we want to continue the animation
            suppressControlsChange = true;
            controls.update();
            suppressControlsChange = false;
        } else {
            controls.update();
        }
    });

    return stopAll(
        watchImmediate(context.cameraNode, () => {
            if (inInteraction) {
                // The node has changed, but we are updating the node from the user interacting with
                // the controls, so there is no change to apply
                return;
            }
            const initialState = getStateFromThreeCamera(context.camera, controls);
            if (cameraStatesApproxEqual(initialState, context.cameraNode)) {
                // The node is still consistent with the camera and controls.
                // This is typically the case when the user makes certain control changes
                // e.g. mouse-wheel zoom
                return;
            }
            if (context.cameraNode.animate) {
                // Create an animation to apply the change
                animation = {
                    id: nextAnimationId++,
                    initialTime: performance.now(),
                    duration: ANIMATION_DURATION_MILLISECONDS,
                    initialState,
                    targetState: cloneCameraState(context.cameraNode),
                };
                log.info('Start camera animation %d', animation.id);
            } else {
                // Not animating, so apply the change directly
                applyStateToThreeCamera(context.cameraNode, context.camera, controls);
            }
        }),
        addEventListener(controls, 'start', () => {
            // The user has started interaction, so begin setting the state from the camera and controls
            inInteraction = true;
            animation = null;
            updateNodeFromThreeCamera(context.cameraNode, context.camera, controls);
        }),
        addEventListener(controls, 'change', () => {
            if (!suppressControlsChange) {
                animation = null;
                updateNodeFromThreeCamera(context.cameraNode, context.camera, controls);
            }
        }),
        addEventListener(controls, 'end', () => {
            // The user has stopped interaction the controls, so apply the changes to camera and controls to the state
            inInteraction = false;
        }),
    );
}

/** Default distance in millimeters between a camera's look-at point and its position */
const DEFAULT_CAMERA_DISTANCE = 450;

/** Default initial properties for a camera-node */
const DEFAULT_CAMERA_STATE = {
    up: LPS.Superior,
    zoom: 1,
    fov: 45,
    near: 5,
    far: 10000,
    animate: false,
} as const;

const ANIMATION_DURATION_MILLISECONDS = 400;

type CameraAnimation = {
    id: number;
    initialTime: DOMHighResTimeStamp;
    duration: number;
    initialState: ThreeCameraState;
    targetState: ThreeCameraState;
};

function updateAnimation(
    camera: PerspectiveCamera,
    controls: TrackballControls,
    animation: CameraAnimation,
): 'finished' | undefined {
    const elapsed = performance.now() - animation.initialTime;
    if (elapsed >= animation.duration) {
        applyStateToThreeCamera(animation.targetState, camera, controls);
        return 'finished';
    }

    const t = easeOut(elapsed / animation.duration);
    applyStateToThreeCamera(
        lerpCameraState(t, animation.initialState, animation.targetState),
        camera,
        controls,
    );
}

/**
 * Copy properties of the three-js camera and controls as a camera-state
 */
function getStateFromThreeCamera(
    camera: PerspectiveCamera,
    controls: TrackballControls,
): ThreeCameraState {
    return {
        position: camera.position.clone(),
        up: camera.up.clone(),
        target: controls.target.clone(),
        near: camera.near,
        far: camera.far,
        zoom: camera.zoom,
        fov: camera.fov,
    };
}

/**
 * Return true if the camera-states are the same
 */
function cameraStatesApproxEqual(state1: ThreeCameraState, state2: ThreeCameraState): boolean {
    return (
        vector3sApproxEqual(state1.position, state2.position) &&
        vector3sApproxEqual(state1.target, state2.target) &&
        vector3sApproxEqual(state1.up, state2.up) &&
        floatsApproxEqual(state1.far, state2.far) &&
        floatsApproxEqual(state1.near, state2.near) &&
        floatsApproxEqual(state1.zoom, state2.zoom) &&
        floatsApproxEqual(state1.fov, state2.fov)
    );
}

/**
 * Set properties of the camera-state from the three-js camera and controls
 */
function updateNodeFromThreeCamera(
    node: PerspectiveCameraNode,
    camera: PerspectiveCamera,
    controls: TrackballControls,
): void {
    node.position.copy(camera.position);
    node.up.copy(camera.up);
    node.target.copy(controls.target);
    node.near = camera.near;
    node.far = camera.far;
    node.zoom = camera.zoom;
    node.fov = camera.fov;
}

/**
 * Create a copy of camera-state
 */
function cloneCameraState(state: ThreeCameraState): ThreeCameraState {
    return {
        ...state,
        position: state.position.clone(),
        up: state.up.clone(),
        target: state.target.clone(),
    };
}

/**
 * Apply the given camera-state to the given camera and controls
 */
function applyStateToThreeCamera(
    state: ThreeCameraState,
    camera: PerspectiveCamera,
    controls: TrackballControls,
) {
    camera.position.copy(state.position);
    camera.up.copy(state.up);
    controls.target.copy(state.target);
    camera.near = state.near;
    camera.far = state.far;
    camera.fov = state.fov;
    camera.zoom = state.zoom;
}

/**
 * Linearly interpolate camera-state between initial and final values according to the
 * parameter t
 */
function lerpCameraState(
    t: number,
    initialState: ThreeCameraState,
    finalState: ThreeCameraState,
): ThreeCameraState {
    return {
        position: new Vector3().lerpVectors(initialState.position, finalState.position, t),
        up: new Vector3().lerpVectors(initialState.up, finalState.up, t),
        target: new Vector3().lerpVectors(initialState.target, finalState.target, t),
        zoom: lerp(initialState.zoom, finalState.zoom, t),
        fov: lerp(initialState.fov, finalState.fov, t),
        near: lerp(initialState.near, finalState.near, t),
        far: lerp(initialState.zoom, finalState.zoom, t),
    };
}

/**
 * A standard linear-interpolation function, interpolating between values x0 and x1 according
 * to parameter t
 */
function lerp(x0: number, x1: number, t: number): number {
    return x0 + t * (x1 - x0);
}

const INTERPOLATION_FACTOR = 1.0 as const;
const A = 4 + INTERPOLATION_FACTOR;
const B = A / 3;

/**
 * This is an 'ease-out' interpolation function t -> x(t)
 *   - returns 0 if t is less than or equal to 0
 *   - returns 1 if t is greater than or equal to 1
 *   - otherwise interpolates between 0 and 1, in a way such that the value of x changes quickly
 *     at t = 0, x = 0 and eases slowly into t = 1, x = 1
 */
function easeOut(t: number): number {
    if (t < 0) {
        return 0;
    } else if (t > 1) {
        return 1;
    } else {
        const u = 1 - t;
        return (
            Math.pow(t, 4) +
            4 * Math.pow(t, 3) * u +
            A * Math.pow(t, 2) * Math.pow(u, 2) +
            B * t * Math.pow(u, 3)
        );
    }
}

/**
 * Add a stoppable event-listener to a three-js event-dispatcher
 */
function addEventListener(
    dispatcher: EventDispatcher,
    type: string,
    callback: () => void,
): StopHandle {
    dispatcher.addEventListener(type, callback);
    return () => {
        dispatcher.removeEventListener(type, callback);
    };
}
