<template>
    <app-button-primary
        width="100%"
        :disabled="!spinopelvicStore.hasData"
        @click="onShow"
        class="my-2 mb-3"
    >
        View Hip-Spine
    </app-button-primary>
    <app-tooltip right activator="parent">
        <span>Show more hip-spine assessment </span>
    </app-tooltip>

    <app-modal v-model="isDialogVisible" persistent style="{'width': 'fit-content'}">
        <template #default>
            <v-card class="hip-spine-dialog" cy-data="hip-spine-dialog">
                <app-modal-title class="headline" cy-data="title" @close="isDialogVisible = false">
                    Hip-Spine Assessment
                </app-modal-title>

                <v-divider />

                <app-modal-content class="mt-5 content">
                    <v-row style="margin: 0">
                        <v-col style="flex-grow: 3">
                            <NomogramPlot
                                :id="1"
                                :cup-inclination="cupInclination"
                                :cup-anteversion="cupAnteversion"
                                :calculations="calculations"
                            />
                        </v-col>

                        <v-col style="flex-grow: 1">
                            <SpinopelvicParameterWarnings
                                :warnings="warnings"
                                :calculations="calculations"
                            />
                        </v-col>
                    </v-row>
                </app-modal-content>

                <app-modal-actions>
                    <v-spacer />
                    <app-button-secondary @click="onClose"> Close</app-button-secondary>
                </app-modal-actions>
            </v-card>
        </template>
    </app-modal>
</template>

<script lang="ts" setup>
    import { ref } from 'vue';
    import {
        type SpinopelvicCalculations,
        type SpinopelvicWarnings,
    } from '@/spinopelvic/components/types';
    import { useSpinopelvic } from '@/spinopelvic/store/store';
    import NomogramPlot from '@/spinopelvic/components/NomogramPlot.vue';
    import SpinopelvicParameterWarnings from '@/spinopelvic/components/SpinopelvicParameterWarnings.vue';
    // import { trackEvent } from '@/analytics/amplitude';
    // import { caseEventProperties } from '@/analytics/event';

    defineProps<{
        warnings: SpinopelvicWarnings;
        calculations: SpinopelvicCalculations;
        cupInclination: number;
        cupAnteversion: number;
    }>();

    const spinopelvicStore = useSpinopelvic();

    const isDialogVisible = ref(false);

    const onShow = () => {
        isDialogVisible.value = true;
        // trackEvent('spinopelvic_dialog_opened', {
        //     ...caseEventProperties(props.hipTemplateStore.project),
        // });
    };

    const onClose = () => {
        isDialogVisible.value = false;
        // trackEvent('spinopelvic_dialog_closed', {
        //     ...caseEventProperties(props.hipTemplateStore.project),
        // });
    };
</script>

<style scoped lang="scss">
    .v-dialog .v-overlay__content {
        width: fit-content;
    }

    //
    //:deep(.table div) {
    //    overflow-x: hidden;
    //    overflow-y: hidden;
    //}
    //
    .hip-spine-dialog {
        width: fit-content;
        margin: auto;
    }
</style>
