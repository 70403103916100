<script setup lang="ts">
    import { onMounted, onUnmounted, ref } from 'vue';
    import { stop, stopAll, type StopHandle } from '@/util';
    import { usePlannerStore } from '@/planner/plannerStore';
    import { updateRendering } from '@/planner/3d/updateRendering';
    import { updatePlanner } from '@/planner/updatePlanner';
    import { updateCup } from '@/planner/updateCup';
    import { updateStem } from '@/planner/updateStem';
    import { useAppErrorStore } from '@/app/appErrorStore';
    import { plannerSceneContext } from '@/planner/scene/plannerSceneContext';
    import { updatePlannerScene } from '@/planner/scene/updatePlannerScene';

    const planner = usePlannerStore();
    const appError = useAppErrorStore();

    // Reference to the container div
    const containerRef = ref<HTMLDivElement>();

    // Reference to the canvas
    const canvasRef = ref<HTMLCanvasElement>();

    let updates: StopHandle | null = null;

    onMounted(() => {
        const [canvas, container] = [canvasRef.value, containerRef.value];
        if (!canvas || !container) {
            throw Error('DOM elements are not defined');
        }

        const sceneContext = plannerSceneContext(planner.sceneState);

        updates = stopAll(
            updateCup(planner),
            updateStem(planner),
            updatePlanner(planner),
            updatePlannerScene(planner, sceneContext),
            updateRendering(container, canvas, sceneContext.scene, planner.nodes.camera),
        );
    });

    onUnmounted(() => {
        appError.catchErrors(() => {
            stop(updates);
        });
    });
</script>

<template>
    <div class="viewport-container" ref="containerRef">
        <canvas class="viewport-canvas" ref="canvasRef" />
    </div>
</template>

<style scoped>
    .viewport-container {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .viewport-canvas {
        position: absolute;
    }
</style>
