<template>
    <canvas ref="canvasRef" :width="canvasWidth" :height="canvasHeight" />
</template>

<script setup lang="ts">
    import anylogger from 'anylogger';
    import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
    import type { CatStackValue } from '@/scanView/types';
    import type { ImageSize } from '@/api/cat-stacks/types';
    import {
        CatStackImageUtil,
        type DrawCanvasInfo,
    } from '@/scanView/CatStackImageUtil';
    import { verify } from '@/lib/verify';

    type IndicatorLine = 'horizontal' | 'vertical' | 'none';

    interface Props {
        value: CatStackValue;
        containerHeight: number;
        containerWidth: number;
        indicatorLine?: IndicatorLine;
        indicatorOffset?: number;
        padding?: number;
    }

    const props = withDefaults(defineProps<Props>(), {
        indicatorLine: 'none',
        indicatorOffset: 0,
        padding: 0,
    });

    const log = anylogger('CatStackImage');

    // Reference to the canvas
    const canvasRef = ref<HTMLCanvasElement>();

    onMounted((): void => {
        const canvas = canvasRef.value;
        if (!canvas) {
            throw Error('DOM elements are not defined');
        }

        const canvasContext = verify(canvas.getContext('2d'), 'no context 2d');
        renderSlice(canvasContext);

        // on container width / height re-render the slice
        // Doing this in the nextTick works more reliable that without it. It maybe related to waiting for some of
        // the DOM/canvas element changes to take-effect
        watch(
            () => props.containerWidth,
            () => nextTick(() => renderSlice(canvasContext)),
        );
        watch(
            () => props.containerHeight,
            () => nextTick(() => renderSlice(canvasContext)),
        );

        // If the indicator position changes, redraw. This is because the slider is
        // manipulated on the three different slices.
        watch(
            () => props.indicatorOffset,
            () => renderSlice(canvasContext),
        );

        // Watch for the current slices pages.
        watch(
            () => props.value.currentIndex,
            () => renderSlice(canvasContext),
        );
    });

    onBeforeUnmount(() => {
        if (canvasRef.value) {
            canvasRef.value = undefined;
        }
    });

    /**
     * @returns the scale ratio needed to fit an image in the parent container.
     * - If the image is smaller thant the container, the scale ratio will be bigger than 1.
     * - If the image is bigger than the container, the scale ratio is will be between 0 and 1.
     * The ratio is chosen based on the smallest of the limiting factors (width, height or both).
     * E.g: if container is 500wx500h,
     * - a) image is 700wx300h => scale ratio will be 500/700
     * - b) image is 300wx800h => scale ratio will be 500/800
     * - c) image is 700wx900h => scale ratio will be 500/900, because the smallest ratio
     * is chosen to keep the aspect ratio of the image
     */
    const scaleRatio = computed((): number => {
        return Math.min(widthScaleRatio.value, heightScaleRatio.value);
    });

    const widthScaleRatio = computed((): number => {
        return (props.containerWidth - props.padding * 2) / catStackSize.value.width;
    });

    const heightScaleRatio = computed((): number => {
        return (props.containerHeight - props.padding * 2) / catStackSize.value.height;
    });

    const canvasWidth = computed((): number => {
        return catStackSize.value.width * scaleRatio.value;
    });

    const canvasHeight = computed((): number => {
        return catStackSize.value.height * scaleRatio.value;
    });

    /**
     * Render a single slice from the image file, using the meta-data from.
     *
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage}
     */
    const renderSlice = (canvasContext: CanvasRenderingContext2D): void => {
        const targetHeight = canvasHeight.value;
        const targetWidth = canvasWidth.value;
        const { count, image, slice, spriteImage } = props.value;

        if (count > 0) {
            const drawCanvasInfo = CatStackImageUtil.calculate(
                image,
                slice,
                currentSliceIndex.value,
                targetWidth,
                targetHeight,
            );

            CatStackImageUtil.resizeCanvas(canvasContext, targetWidth, targetHeight);

            // First draw the CT image, this will over-write any old image and any
            // old indicator lines.
            CatStackImageUtil.render(canvasContext, spriteImage, drawCanvasInfo);

            // Optionally draw an indicator line.
            renderIndicatorLine(canvasContext, drawCanvasInfo);
        } // else no catstack info
    };

    const renderIndicatorLine = (
        context: CanvasRenderingContext2D,
        position: DrawCanvasInfo,
    ): void => {
        const offset = props.indicatorOffset;

        switch (props.indicatorLine) {
            case 'horizontal':
                CatStackImageUtil.renderHorizontalLine(context, position, offset);
                break;
            case 'vertical':
                CatStackImageUtil.renderVerticalLine(context, position, offset);
                break;
            case 'none':
                // nothing to do
                break;
            default:
                log.debug('Unknown indicator mode %s', props.indicatorLine);
        }
    };

    const currentSliceIndex = computed((): number => {
        /**
         * return a number so that the value is constrained from:
         *       min <= val <= max
         */
        const _constrain = (val: number, min: number, max: number): number => {
            return Math.min(Math.max(val, min), max);
        };

        return _constrain(props.value.currentIndex, 0, props.value.count - 1);
    });

    const catStackSize = computed((): ImageSize => {
        return props.value.world;
    });
</script>
