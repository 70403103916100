<template>
    <div class="app-plan-actions">
        <div class="app-plan-download-buttons d-flex align-center">
            <app-button-secondary
                class="mt-2"
                v-if="plan.files.length"
                :loading="isDownloadingModels"
                @click="onDownloadModels"
            >
                Download Raw Models
            </app-button-secondary>

            <v-spacer />
            <app-button
                v-if="plan.report"
                @click="isAutomated ? onAutomatedPlanDownload() : onManualPlanDownloadIntent()"
                :loading="isDownloadingPdf"
            >
                Download PDF
            </app-button>
            <v-card
                width="244"
                rounded="lg"
                variant="tonal"
                v-else-if="isCreating || isCreationBroken"
                class="px-4 py-2 text-secondary text-caption d-block"
            >
                <div v-if="isCreating">
                    <v-progress-linear color="primary" class="mt-2" indeterminate height="6" />
                    <br />
                    Your downloadable Plan is being created, this process can take up to 10 minutes.
                </div>
                <div v-else-if="isCreationBroken">
                    <v-icon color="planner-red" size="40" class="w-100 mb-5 d-flex">
                        mdi-alert-circle-outline
                    </v-icon>
                    <span class="small-text text-planner-red">
                        There was an error creating your Plan
                    </span>
                    <span class="small-text text-planner-grey">
                        Please
                        <a href="https://support.formuslabs.com/hc/en-us" target="_blank">
                            contact support
                        </a>
                        for assistance
                    </span>
                </div>
            </v-card>

            <PlanManualDownloadWarningModal
                v-if="isManual && plan.report && !plan.report.include_latest_changes"
                :value="showWarningDialog"
                @close="closeWarningDialog"
                @submit="onDownloadFromWarningDialog"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { PlanType } from '@/planView/store/types';
    import { usePlansStore } from '@/planView/store/store';
    import { computed, ref } from 'vue';
    import PlanManualDownloadWarningModal from '@/planView/PlanManualDownloadWarningModal.vue';
    import QRCodeContainer from '@/planView/qr-code/QRCodeContainer.vue';
    import { alignmentMode } from '@/planner/api/alignmentMode';
    import type { ApiPlan, ApiPlansCase } from '@/api/plan/types';
    import { assert } from '@/util';
    import { verify } from '@/lib/verify';
    import { planState } from '@/planView/store/planState';
    import { DateTime } from 'luxon';
    import { trackEvent } from '@/plugins/amplitude';
    import { caseEventProperties, planEventProperties } from '@/plugins/amplitudeEvent';

    const store = usePlansStore();

    export interface Props {
        project: ApiPlansCase;
        plan: ApiPlan;
    }

    const props = defineProps<Props>();

    const showWarningDialog = ref(false);
    const isDownloadingPdf = ref(false);
    const isDownloadingModels = ref(false);

    const isAutomated = computed(() => {
        return props.plan.type === PlanType.Automated;
    });

    const isManual = computed(() => {
        return props.plan.type === PlanType.Manual;
    });

    const onAutomatedPlanDownload = async () => {
        assert(isAutomated.value, 'Automated plan download called for manual plan');
        isDownloadingPdf.value = true;
        try {
            await store.downloadAutomatedPlan();
        } finally {
            await trackEvent('plan_downloaded', {
                ...caseEventProperties({
                    projectId: props.project.id.toString(),
                    // surgicalTemplateHistoryId: store.state.automatedTemplate.id.toString(),
                }),
                ...planEventProperties(props.plan.id.toString(), true),
            });
            isDownloadingPdf.value = false;
        }
    };

    const onManualPlanDownloadIntent = async () => {
        assert(isManual.value, 'Manual plan download intent called for automated plan');
        const report = verify(
            props.plan.report,
            'Manual plan download intent called for plan without report',
        );

        if (report.include_latest_changes) {
            await onManualDownload();
        } else {
            showWarningDialog.value = true;
        }
    };

    const onDownloadModels = async () => {
        isDownloadingModels.value = true;
        try {
            await store.downloadModels(props.plan);
        } finally {
            await trackEvent('models_downloaded', {
                ...caseEventProperties({
                    projectId: props.project.id.toString(),
                    // surgicalTemplateHistoryId: isAutomated.value ?
                    //     store.state.automatedTemplate.id.toString() : store.state.manualTemplate?.id.toString(),
                }),
                ...planEventProperties(props.plan.id.toString(), isAutomated.value),
            });
            isDownloadingModels.value = false;
        }
    };

    const closeWarningDialog = () => {
        showWarningDialog.value = false;
    };

    const onDownloadFromWarningDialog = async () => {
        closeWarningDialog();
        await onManualDownload();
    };

    const onManualDownload = async () => {
        isDownloadingPdf.value = true;
        try {
            await store.downloadManualPlan();
        } finally {
            await trackEvent('plan_downloaded', {
                ...caseEventProperties({
                    projectId: props.project.id.toString(),
                    // surgicalTemplateHistoryId: store.state.manualTemplate?.id.toString(),
                }),
                ...planEventProperties(props.plan.id.toString(), false),
            });
            isDownloadingPdf.value = false;
        }
    };

    const isCreating = computed((): boolean => {
        const state = planState(props.plan.state);
        return (state === 'new' || state === 'processing') && !isStale.value;
    });

    const isCreationBroken = computed((): boolean => {
        const state = planState(props.plan.state);
        return state === 'error' || (state !== 'completed' && isStale.value);
    });

    const isStale = computed((): boolean => {
        const date = createdOn(props.plan);
        return !!date && date.plus({ day: 1 }) < DateTime.utc();
    });

    const createdOn = (plan: ApiPlan): DateTime => {
        return DateTime.fromISO(plan.created_on, { zone: 'utc' });
    };
</script>

<style scoped>
    .app-plan-actions {
        flex-basis: 260px;
        flex-grow: 0;
    }

    .app-plan-actions {
        //height: 160px;
    }

    .app-plan-card-ref {
        color: var(--planner-grey-2);
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.75rem;
        margin-bottom: 20px;
    }

    .app-plan-card-ref-number {
        color: var(--planner-grey);
    }
</style>
