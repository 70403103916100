<template>
    <app-modal
        :model-value="upload.hasMultiplePatientIdentities"
        persistent
        max-width="800"
        width="800"
    >
        <v-card>
            <app-modal-title class="headline patient-inconsistent-identity-container-title" @close="upload.cancel">
                There was an issue processing your upload: DICOM patient details differ between
                files.
            </app-modal-title>

            <v-divider />

            <app-modal-content>
                <v-card-text>
                    We noticed while processing your DICOM files that the patient details contained
                    in some individual files differed from others:
                </v-card-text>

                <v-card-text class="mt-5 small-text">
                    <v-data-table>
                        <template #default>
                            <thead class="custom-grey-light">
                                <tr>
                                    <th class="">Files</th>
                                    <th class="text-left">Name</th>
                                    <th class="text-left">DOB</th>
                                    <th class="">Sex</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(identity, index) in differentPatientGroups"
                                    :key="`${index}-group`"
                                >
                                    <td>
                                        {{ identity.dicoms.length }}
                                        {{ identity.dicoms.length === 1 ? 'file' : 'files' }}
                                    </td>
                                    <td>{{ NameUtil.format(identity.patient?.name) }}</td>
                                    <td class="custom-grey--text">
                                        {{ identity.patient?.birth_date }}
                                    </td>
                                    <td class="text-capitalize">
                                        {{ identity.patient?.sex }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                        <!--
                            Removes pagination
                            https://stackoverflow.com/questions/76211136/cant-remove-footer-pagination-from-v-data-table-in-vue-3-2
                        -->
                        <template #bottom></template>
                    </v-data-table>
                </v-card-text>

                <v-card-text v-if="allDicomsInConflict.length === 1" class="mt-5">
                    The file that was not consistent was
                    <span class="custom-black--text">
                        {{ allDicomsInConflict[0].file.name }}
                    </span>
                </v-card-text>
                <v-card-text v-else-if="allDicomsInConflict.length" class="mt-5">
                    The {{ allDicomsInConflict.length }}
                    {{ allDicomsInConflict.length === 1 ? 'file' : 'files' }} that were not
                    consistent were
                    <span class="custom-black--text"> {{ fileNameListed }}. </span>
                </v-card-text>

                <v-card-text>
                    Your DICOM files will not be processed - please review the files with your
                    radiology provider and reupload when you&rsquo;re ready.
                </v-card-text>
            </app-modal-content>

            <app-modal-actions>
                <app-button-secondary @click="upload.cancel">
                    Close
                </app-button-secondary>
            </app-modal-actions>
        </v-card>
    </app-modal>
</template>

<script setup lang="ts">
    import { useCaseUpload } from '@/caseSettings/case-upload/store';
    import { computed } from 'vue';
    import type { DicomInfo } from '@/lib/dicom/DicomInfo';
    import { PatientComparisonUtil } from '@/lib/dicom/PatientComparisonUtil';
    import type { PatientPersonalInfo } from '@/lib/dicom/types';
    import { NameUtil } from '@/lib/NameUtil';
    import { flatten, sortBy } from 'ramda';
    import AppModalTitle from '@/components/modal/AppModalTitle.vue';
    import AppModalContent from '@/components/modal/AppModalContent.vue';

    type RowData = {
        patient: PatientPersonalInfo | null;
        dicoms: DicomInfo[];
    };

    const filesToDisplay = 10;

    const upload = useCaseUpload();

    const identities = computed(() => {
        return upload.patientIdentityCheck?.identities ?? [];
    });

    const differentPatientGroups = computed((): RowData[] => {
        return Object.values(identities.value).map((dicoms: DicomInfo[]) => {
            if (dicoms.length) {
                return {
                    patient: PatientComparisonUtil.fromDicom(dicoms[0]),
                    dicoms,
                };
            }

            return { patient: null, dicoms: [] };
        });
    });

    /**
     * @returns the dicoms files in the group of less length. It is assumed that the first
     * group with more dicom files is the correct one, and the other ones are wrong.
     */
    const allDicomsInConflict = computed((): DicomInfo[] => {
        const groups = differentPatientGroups.value;
        const sortedByLenghDesc = sortBy((row: RowData) => -row.dicoms.length, groups);
        sortedByLenghDesc.shift(); // mutate the array, removes the group with more files.
        return flatten(sortedByLenghDesc.map((row: RowData) => row.dicoms));
    });

    /**
     * An array with the first 10 files names.
     */
    const discomNamesToDisplay = computed(() => {
        return allDicomsInConflict.value
            .slice(0, filesToDisplay)
            .map((dicom: DicomInfo) => dicom.file.name);
    });

    /**
     * The amount of files that are not specifically listed.
     * This will be > 0 only when the amount of files in conflict is bigger than {@property this.filesToDisplay}.
     * Note: This is done to not clog the screen with a really large list of file names.
     */
    const amountOfFilesNotListed = computed((): number => {
        return allDicomsInConflict.value.length - discomNamesToDisplay.value.length;
    });

    /**
     * The formatted list of file names
     * e.g: if the limit of files to display is 10
     *  - if 11 files => name and 1 other file.
     *  - if 12 files => name and 2 other files.
     */
    const fileNameListed = computed(() => {
        // @ts-ignore https://github.com/microsoft/TypeScript/issues/46907
        const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });

        if (amountOfFilesNotListed.value) {
            return formatter.format([
                ...discomNamesToDisplay.value,
                `${amountOfFilesNotListed.value} other ${amountOfFilesNotListed.value === 1 ? 'file' : 'files'}`,
            ]);
        } else {
            return formatter.format(discomNamesToDisplay.value);
        }
    });
</script>

<style scoped>
    .patient-inconsistent-identity-container-title {
        white-space: break-spaces;
    }
</style>
