import { defineStore } from 'pinia';
import { initialState } from '@/scanView/dicomStore/initialState';
import { makeDataStateGetters, withStateLoading } from '@/util/dataState';
import type { DicomStoreState } from '@/scanView/dicomStore/types';
import { fetchCatStacks } from '@/api/cat-stacks/request';
import { verify } from '@/lib/verify';
import type { CatStackValue } from '@/scanView/types';
import loadImage from '@/api/cat-stacks/loadImage';
import { cloneDeep } from 'lodash';

export const useDicomStore = defineStore({
    id: 'dicom-store',
    state: (): DicomStoreState => ({ ...cloneDeep(initialState) }),
    getters: {
        ...makeDataStateGetters(),
        axial(state): CatStackValue {
            return state.catStacks.axial;
        },
        coronal(state): CatStackValue {
            return state.catStacks.coronal;
        },
        sagittal(state): CatStackValue {
            return state.catStacks.sagittal;
        },
    },
    actions: {
        async load(caseId: number) {
            console.info('Loading cat-stacks...');

            await withStateLoading(this, async () => {
                const catStacks = verify(await fetchCatStacks(caseId), 'cat-stack');

                const axialImage = await loadImage(catStacks.axial.image_url);
                const coronalImage = await loadImage(catStacks.coronal.image_url);
                const sagittalImage = await loadImage(catStacks.sagittal.image_url);

                this.catStacks.axial = {
                    ...catStacks.axial,
                    spriteImage: verify(axialImage, 'no axial image'),
                    currentIndex: Math.round(catStacks.axial.count / 2),
                };

                this.catStacks.coronal = {
                    ...catStacks.coronal,
                    spriteImage: verify(coronalImage, 'no coronal image'),
                    currentIndex: Math.round(catStacks.coronal.count / 2),
                };

                this.catStacks.sagittal = {
                    ...catStacks.sagittal,
                    spriteImage: verify(sagittalImage, 'no sagittal image'),
                    currentIndex: Math.round(catStacks.sagittal.count / 2),
                };
            });
        },
    },
});
