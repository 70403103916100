import type { AxiosRequestConfig } from 'axios';
import { Vector3 } from 'three';
import type { FemoralFeatures, NativeMeasurements, PlannerState } from '@/planner/plannerState';
import { type ApiCase, getCase } from '@/planner/api/case';
import { type ApiStudy, getStudy, studyIdFromUrl } from '@/planner/api/study';
import { assert, assertDefined, taggedLogger } from '@/util';
import {
    type ApiBoneId,
    type FindModelOptions,
    findStudyModel,
    getStudyModels,
} from '@/planner/api/studyModels';
import {
    type ApiStudyLandmarks,
    getLandmarks,
    landmarksIdFromUrl,
} from '@/api/study/studyLandmarks';
import { findLandmark } from '@/formus/anatomy/landmarks';
import { areCollinear, orthogonalUnit, vector3 } from '@/formus/geometry/vector3';
import {
    type ApiStudyMeasurements,
    getStudyMeasurements,
    studyMeasurementsIdFromUrl,
} from '@/api/study/studyMeasurements';
import {
    type AxisMeasurement,
    findAngleMeasurement,
    findAxisMeasurement,
    findDistanceMeasurement,
} from '@/formus/anatomy/measurements';
import {
    type ApiCompleteSpecification,
    getCompleteSpecification,
    specificationIdFromUrl,
} from '@/planner/api/specification';
import { getComponentCatalog } from '@/api/catalog/getComponentCatalog';
import { formatBasis, formatDegrees, formatFloat, joinIndented } from '@/formus/geometry/formatMath';
import { getFittedComponents } from '@/api/fittedComponents/getFittedComponents';
import { pollTemplate } from '@/api/template/pollTemplate';
import { nativeAcetabularAngles } from '@/formus/anatomy/pelvis/nativeAcetabularAngles';
import { anatomicAngles } from '@/formus/anatomy/pelvis/acetabularAngles';
import { toRadians } from '@/formus/anatomy/pelvis/anteversionInclination';
import type { BodySide } from '@/formus/anatomy/side';
import type { Template } from '@/formus/template/template';
import LPS, { type LpsVectors } from '@/formus/anatomy/LPS';
import { createManualTemplate } from '@/planner/createManualTemplate';
import { templateId } from '@/api/template/templateUrl';
import { type ApiPlan, getPlan, planIdFromUrl } from '@/planner/api/plan';
import { logValidation } from '@/planner/logValidation';
import { makeSpinoPelvicLandmarks } from '@/spinopelvic/components/calculations';
import { useSpinopelvic } from '@/spinopelvic/store/store';
import { fetchCatStacks } from '@/api/cat-stacks/request';
import type { ApiCaseCatStacks } from '@/api/cat-stacks/types';
import { catstacksData } from '@/planner/cat-stack/catstacksData';
import { catstacksState } from '@/planner/cat-stack/catstacksState';
import type { PlannerMeshNode } from '@/planner/scene/plannerMesh';
import type { PlannerNodes } from '@/planner/scene/plannerNodes';
import { computeBearingUrl } from './componentUrls';
import { getTemplate } from '@/api/template/getTemplate';

const log = taggedLogger('case-load');

export async function loadCase(
    state: PlannerState,
    nodes: PlannerNodes,
    caseId: number,
    config?: AxiosRequestConfig,
): Promise<void> {
    const {
        case_,
        landmarks,
        measurements,
        specification,
        study,
        autoTemplate,
        template,
        plan,
        catstacks,
    } = await loadCaseData(caseId, config);

    // TODO: This is a hack to get the spinopelvic measurements.
    await useSpinopelvic().load(caseId, config);

    state.case = {
        caseId: caseId,
        caseUrl: case_.self,
        caseVersion: case_.version,
        operationalSide: case_.side,
        autoTemplate: autoTemplate,
        manualTemplateUrl: case_.manualTemplate,
        manualTemplateCanonicalUrl: template.canonical,
        manualTemplateLastApprovedUrl: plan?.template ?? null,

        // TODO: There are already 3 properties from the specification that are being used here.
        //       We should consider using the specification directly.
        alignmentMode: specification.alignmentMode,
        preferredStemSystem: specification.preferredSystem,
        targets: {
            legLength: specification.target.leg_length_change,
            offset: specification.target.offset_change,
        },
    };

    state.sceneCentre = calculateSceneCentre(landmarks);
    state.nativeMeasurements = makeNativeMeasurements(case_.side, specification, measurements);
    state.femoralFeatures = findFemoralFeatures(case_.side, measurements);
    state.hipSpineLandmarks = makeSpinoPelvicLandmarks(landmarks);
    state.catstacksData = catstacksData(catstacks);
    state.catstacks = catstacksState(catstacks);

    log.info('Assigning anatomical model sources');
    await assignBoneSources(nodes, study);

    if (state.catalog) {
        log.info('Components catalog is already loaded');
    } else {
        log.info('Loading components catalog');
        state.catalog = await getComponentCatalog(config);
    }

    await initStateFromTemplate(state, template);

    log.info('Finished loading case');
}

/** Reload the manual-template and initialize the planner-state from it */
export async function reloadTemplate(
    state: PlannerState,
    config?: AxiosRequestConfig,
): Promise<void> {
    assert(state.case, 'Cannot reload template: no case loaded');

    log.info('Reloading surgical-template %d', templateId(state.case.manualTemplateUrl).template);
    const template = await pollTemplate(
        state.case.manualTemplateUrl,
        (template) => !!template.components,
        config,
    );
    await initStateFromTemplate(state, template, config);
}

/** Initialize the planner-state from a template */
async function initStateFromTemplate(
    state: PlannerState,
    template: Template,
    config?: AxiosRequestConfig,
): Promise<void> {
    log.info('Loading fitted components');
    assert(template.components !== null);
    state.fittedComponents = await getFittedComponents(template.components, config);

    log.info('Assigning template');
    state.targetAdjustments = {
        legLength:
            template.targetLegLengthChange ??
            assertDefined(state.case?.targets.legLength, 'no case leg length'),
        offset:
            template.targetOffsetChange ??
            assertDefined(state.case?.targets.offset, 'no case offset'),
    };
    state.template = {
        cupUrl: template.cup,
        linerUrl: template.liner,
        bearingUrl: null,
        stemUrl: template.stem,
        headUrl: template.head,
        cupRotation: anatomicAngles(toRadians(template.cupRotation)),
        cupOffset: template.cupOffset,
        dualMobility: template.dualMobility,
        stemTransform: template.stemTransform,
        targets: {
            legLength: template.targetLegLengthChange,
            offset: template.targetOffsetChange,
        },
    };
    state.template.bearingUrl = template.dualMobility ? computeBearingUrl(state) : null;
}

/** Api-data that we load up-front */
type ApiData = {
    case_: ApiCase;
    specification: ApiCompleteSpecification;
    autoTemplate: Template;
    template: Template;
    plan: ApiPlan | null;
    study: ApiStudy;
    measurements: ApiStudyMeasurements;
    landmarks: ApiStudyLandmarks;
    catstacks: ApiCaseCatStacks;
};

/** Up-front load of data */
async function loadCaseData(caseId: number, config?: AxiosRequestConfig): Promise<ApiData> {
    log.info('Loading case %d', caseId);
    let case_ = await getCase(caseId, config);

    const autoTemplate = await getTemplate(case_.automatedTemplate, config);

    if (!case_.manualTemplate) {
        case_ = await createManualTemplate(case_, config);
    }

    log.info(
        'Loading surgical-specification %d',
        specificationIdFromUrl(case_.specification).specification,
    );
    const specification = await getCompleteSpecification(case_.specification, config);

    log.info('Loading surgical-template %d', templateId(case_.manualTemplate).template);

    // Fetch the surgical-template until the components-url is available
    const template = await pollTemplate(
        case_.manualTemplate,
        (template) => !!template.components,
        config,
    );

    let plan = null;
    if (template.plan) {
        log.info('Loading study %d', planIdFromUrl(template.plan).plan);
        plan = await getPlan(template.plan, config);
    }

    log.info('Loading study %d', studyIdFromUrl(template.study).study);
    const study = await getStudy(template.study, config);

    log.info(
        'Loading study-measurements %d',
        studyMeasurementsIdFromUrl(study.measurements).measurements,
    );
    const measurements = await getStudyMeasurements(study.measurements, config);

    log.info('Loading landmarks %d', landmarksIdFromUrl(study.landmarks).landmarks);
    const landmarks = await getLandmarks(study.landmarks, config);

    log.info('Loading catstacks %d', landmarksIdFromUrl(study.landmarks).landmarks);
    const catstacks = await fetchCatStacks(caseId, config);
    if (catstacks === null) {
        throw new Error(`Failed to load cat-stacks for case `);
    }

    return {
        case_,
        specification,
        study,
        measurements,
        landmarks,
        autoTemplate,
        template,
        plan,
        catstacks,
    };
}

async function assignBoneSources(
    nodes: PlannerNodes,
    study: ApiStudy,
    config?: AxiosRequestConfig,
): Promise<void> {
    const boneModels = await getStudyModels(study.models, config);

    const assignBoneSource = (
        node: PlannerMeshNode,
        boneId: ApiBoneId,
        options?: FindModelOptions,
    ) => {
        const source = findStudyModel(boneModels, boneId, options);
        node.geometrySource = source !== null ? source.id : null;
    };

    assignBoneSource(nodes.operativeFemur, 'operative-femur');
    assignBoneSource(nodes.operativeFemurInternal, 'operative-femur-internal');
    assignBoneSource(nodes.operativeHemipelvis, 'operative-hemipelvis');
    assignBoneSource(nodes.sacrum, 'sacrum');
    assignBoneSource(nodes.contralateralHemipelvis, 'contralateral-hemipelvis');
    assignBoneSource(nodes.contralateralFemur, 'contralateral-femur');
    assignBoneSource(nodes.metal, 'metal', { optional: true });
}

/**
 * Calculate the centre-point for a hip case. This is a point in the centre of the pelvis between
 * the left and right asis.
 *
 * NOTE: also known as the scene 'origin'
 */
function calculateSceneCentre({ groups }: ApiStudyLandmarks): Vector3 {
    const leftItem = findLandmark(groups, 'pelvis', 'lasis');
    const rightItem = findLandmark(groups, 'pelvis', 'rasis');
    return vector3(leftItem.coordinates).add(rightItem.coordinates).divideScalar(2);
}

/**
 * Create an LPS basis aligned to a femoral coordinate system:
 * - superior direction along the **proximal** shaft-axis
 * - left/right medial direction in the same plane as the neck-axis but orthogonal to the superior direction
 * - posterior direction orthogonal to the superior and medial directions
 *
 * This basis is used to position the stem.
 */
function calculateStemTranslationBasis(
    side: BodySide,
    proximalShaftAxis: AxisMeasurement,
    neckAxis: AxisMeasurement,
): LpsVectors {
    const superior = vector3(proximalShaftAxis.value).normalize();
    const medial = vector3(neckAxis.value).projectOnPlane(superior).normalize();
    const left = side === 'right' ? medial : medial.clone().negate();
    const posterior = orthogonalUnit(superior, left);
    const result = { left, posterior, superior };

    logValidation(
        joinIndented(2)(['Stem-translation-basis:', ...formatBasis(result, { precision: 5 })]),
    );

    return { left, posterior, superior };
}

/**
 * Create an LPS basis aligned to a femoral coordinate system:
 * - superior direction corresponding to the femoral-shaft-axis.
 * - posterior direction orthogonal to the superior direction in the CT saggital plane
 *
 * This is intended to be functionally equivalent to the shaft-plane used to calculate
 * stem-anteversion on the server-side.
 */
function calculateFemoralShaftBasis(shaftAxis: AxisMeasurement): LpsVectors {
    const superior = vector3(shaftAxis.value).normalize();

    let approxPosterior: Vector3;
    if (areCollinear(superior, LPS.Posterior)) {
        log.warn('Femoral shaft axis is exactly in posterior direction');
        approxPosterior = LPS.Inferior;
    } else {
        approxPosterior = LPS.Posterior;
    }

    const left = orthogonalUnit(approxPosterior, superior);
    const posterior = orthogonalUnit(superior, left);
    const result = { left, posterior, superior };

    logValidation(
        joinIndented(2)(['Femoral-shaft-basis:', ...formatBasis(result, { precision: 5 })]),
    );

    return { left, posterior, superior };
}

/**
 * Extract femoral features from a set of study-measurements
 *
 * Returns null if the necessary measurements cannot be found, which may be the result of an old cases.
 */
function findFemoralFeatures(
    side: BodySide,
    { groups }: ApiStudyMeasurements,
): FemoralFeatures | null {
    try {
        const shaftAxis = findAxisMeasurement(
            groups,
            { bone: 'femur', side },
            'hip_femur_shaft_axis',
        );
        const proximalShaftAxis = findAxisMeasurement(
            groups,
            { bone: 'femur', side },
            'hip_femur_proximal_shaft_axis',
        );
        const neckAxis = findAxisMeasurement(
            groups,
            { bone: 'femur', side },
            'hip_femur_neck_axis',
        );
        const anteversion = findAngleMeasurement(
            groups,
            { bone: 'femur', side },
            'hip_femur_anteversion_angle',
        );

        const [anteversionCondylarAxis, anteversionNeckAxis] = assertDefined(
            anteversion.axes,
            'anteversion-axes',
        );

        return {
            shaftAxis,
            proximalShaftAxis,
            neckAxis,
            anteversionCondylar: assertDefined(
                anteversionCondylarAxis.value,
                'anteversion-condylar-axis',
            ),
            anteversionNeck: assertDefined(anteversionNeckAxis.value, 'anteversion-neck-axis'),
            stemTranslationBasis: calculateStemTranslationBasis(side, proximalShaftAxis, neckAxis),
            shaftBasis: calculateFemoralShaftBasis(shaftAxis),
        };
    } catch (error) {
        if (error instanceof Error) {
            log.warning(
                'Error getting femoral features needed for femoral alignment ' +
                    '(this may be an old case missing this data):\n%s',
                error.message,
            );
            return null;
        } else {
            throw error;
        }
    }
}

function makeNativeMeasurements(
    side: BodySide,
    specification: ApiCompleteSpecification,
    { groups }: ApiStudyMeasurements,
): NativeMeasurements {
    const { anteversion, inclination } = nativeAcetabularAngles(
        specification.alignmentMode,
        side,
        groups,
    );
    const result = {
        hipLengthDifference: findDistanceMeasurement(
            groups,
            'pelvis',
            'hip_pelvis_leg_length_difference',
        ).value,
        femoralAnteversion: findAngleMeasurement(
            groups,
            { bone: 'femur', side },
            'hip_femur_anteversion_angle',
        ).value,
        acetabularAnteversion: anteversion,
        acetabularInclination: inclination,
    };

    logValidation(
        [
            'Native measurements:',
            `  hip-length-difference: ${formatFloat(result.hipLengthDifference)} mm`,
            `  femoral-anteversion: ${formatDegrees(result.femoralAnteversion)}`,
            `  acetabular-anteversion: ${formatDegrees(result.acetabularAnteversion)}`,
            `  acetabular-inclination: ${formatDegrees(result.acetabularInclination)}`,
        ].join('\n'),
    );

    return result;
}
