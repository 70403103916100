<template>
    <v-layout>
        <app-sidebar>
            <router-link :to="{ name: ROUTES.NEW_CASE }">
                <app-button-cta width="180" prepend-icon="mdi-plus">Create new Case</app-button-cta>
            </router-link>
        </app-sidebar>

        <app-main-view>
            <div class="case-list-header">
                <h2>
                    Your Formus Cases
                    <span class="app-case-count" v-if="store.result.count"
                        >({{ store.result.count }})</span
                    >
                </h2>

                <div class="case-list-search">
                    <app-text-input
                        width="500"
                        density="comfortable"
                        rounded
                        class="px-0"
                        append-inner-icon="mdi-magnify"
                        placeholder="Search Cases"
                        bg-color="white"
                        clearable
                        v-model="store.searchText"
                        @update:model-value="debounceLoad()"
                    />
                </div>
            </div>

            <div class="case-list justify-start" v-if="store.result.count > 0">
                <case-card
                    v-for="item in store.result.cases"
                    :key="item.id"
                    :id="item.id"
                    :title="item.name"
                    :description="item.description"
                    :reference="item.id"
                    :patient="item.patient"
                    :surgeon="item.surgeon"
                    :owner="item.owner"
                    :createdAt="item.createdAt"
                    :status="item.status ?? { state: 'unknown', failed: false }"
                />
                <div class="case-pagination">
                    <a v-if="store.result.pages.previous" @click="store.previousPage">previous</a>
                    <span>{{ store.result.pages.current }}</span>
                    <a v-if="store.result.pages.next" @click="store.nextPage">next</a>
                </div>
            </div>
            <div v-else-if="store.isReady && store.result.count === 0">
                We're sorry, we couldn't find any Cases including "{{ store.searchText }}". Try
                changing your search terms, or clear the search to return to your Cases.
            </div>
        </app-main-view>
    </v-layout>
</template>

<script setup lang="ts">
    import { ROUTES } from '@/router';
    import { useDashboardStore } from '@/dashboard/dashboardStore';
    import { onUnmounted } from 'vue';
    import { debounce } from 'lodash';
    import CaseCard from '@/dashboard/CaseCard.vue';

    const store = useDashboardStore();

    const DEBOUNCE_SEARCH_IN_MILLISECONDS = 150;
    const debounceLoad = debounce(() => store.load(), DEBOUNCE_SEARCH_IN_MILLISECONDS);

    // initial page load without search query or pagination
    await store.load();

    // Cleanup on unmount
    onUnmounted(() => {
        store.stop();
    });
</script>


<!--suppress CssUnusedSymbol -->
<style scoped>
    .case-list {
        max-width: 1020px;
    }
    .case-list-header {
        display: flex;
        justify-content: space-between;
        max-width: 1020px;
    }

    .case-list-search {
        /* flex-basis: 344px; */

        :deep(input) {
            padding-left: 30px;
        }

        :deep(.v-field__append-inner) {
            padding-right: 20px;
        }
    }

    .case-list-header h2 {
        color: var(--planner-black);
        font-size: 1.75rem;
        line-height: 2.875rem;
        font-weight: 400;
        margin: 0 0 2.5rem;
        letter-spacing: 0.0125em;
    }

    .app-case-count {
        color: var(--planner-grey) !important;
    }

    .case-pagination {
        display: flex;
        justify-content: center;
        max-width: 1020px;
        margin: 20px;
    }

    .case-pagination a {
        font-size: 12px;
        margin-left: 10px;
        cursor: pointer;
        user-select: none;
    }

    .case-pagination span {
        font-size: 12px;
        margin-left: 10px;
    }
</style>
