import { Vector3, type Vector3Like, type Vector3Tuple } from 'three';
import { DEFAULT_TOLERANCE } from '@/formus/geometry/approxEquals';
import { hasNumberProperties, hasAnyNumberProperties } from '@/formus/geometry/vector';

/**
 * A *Partial* set of xyz values
 */
export type PartialXYZ = {
    readonly x?: number;
    readonly y?: number;
    readonly z?: number;
};

/**
 * The type of single arguments that can be converted to a Vector3
 */
export type AnyVector3 = Vector3 | PartialXYZ | number[];

/** Constructs a new {@link Vector3} from its argument(s)
 *
 * If a Vector3 is passed **it will be cloned**
 */
export function vector3(value?: AnyVector3 | number, y?: number, z?: number): Vector3 {
    if (value === undefined) {
        return new Vector3();
    }
    if (typeof value === 'number') {
        if (y === undefined || z === undefined) {
            throw new Error(`Invalid y (${y}) or z (${z}) parameter for creating Vector3`);
        }
        return new Vector3(value, y, z);
    }
    if (isVector3Tuple(value)) {
        return new Vector3(...value);
    }
    if (isVector3(value)) {
        return value.clone();
    }
    if (isPartialXYZ(value)) {
        return new Vector3(value.x ?? 0, value.y ?? 0, value.z ?? 0);
    }
    throw new Error(`Invalid argument for creating Vector3: ${value}`);
}

/**
 * The zero Vector3
 */
export function zero3(): Vector3 {
    return new Vector3();
}

/**
 * Type-guard for single value that can construct a Vector2
 */
export function isAnyVector3(value: unknown): value is AnyVector3 {
    return isVector3(value) || isVector3Tuple(value) || isPartialXYZ(value);
}

/**
 * Type-guard for a three-js {@link Vector3}
 */
export function isVector3(value: unknown): value is Vector3 {
    return value instanceof Vector3;
}

/**
 * Type-guard for a three-js {@link Vector3Like}
 */
export function isVector3Like(value: unknown): value is Vector3Like {
    return hasNumberProperties(value, 'x', 'y', 'z');
}

/**
 * Type-guard for a three-js {@link Vector3Tuple}
 */
export function isVector3Tuple(value: unknown): value is Vector3Tuple {
    return Array.isArray(value) && value.length === 3;
}

/**
 * Type-guard for {@link PartialXYZ}
 */
export function isPartialXYZ(value: unknown): value is PartialXYZ {
    return hasAnyNumberProperties(value, 'x', 'y', 'z');
}

/** @returns a new vector multiplied by the scalar */
export function multiplyScalar(vector: AnyVector3, scalar: number): Vector3 {
    return vector3(vector).multiplyScalar(scalar);
}

/**
 * @returns a new vector with the result of summing the given vectors.
 */
export function addVectors(...vectors: AnyVector3[]): Vector3 {
    if (vectors.length === 0) {
        return new Vector3();
    } else {
        const [head, ...tail] = vectors;
        return vector3(head).add(addVectors(...tail));
    }
}

/**
 * Create a unit-vector (a vector of length 1) with the same direction as the given vector
 */
export function normalize(vector: Vector3): Vector3 {
    if (vector.length() < DEFAULT_TOLERANCE) {
        throw Error('Attempting to normalize zero vector');
    }
    return vector.clone().normalize();
}

/**
 * Check whether the given vectors lie on the same line, meaning they have either exactly
 * the same or exactly opposite directions
 */
export function areCollinear(vector1: Vector3, vector2: Vector3): boolean {
    return vector1.clone().cross(vector2).length() < DEFAULT_TOLERANCE;
}

/**
 * Create a unit vector (a vector of length 1) that is orthogonal to both the given vectors.
 */
export function orthogonalUnit(vector1: Vector3, vector2: Vector3): Vector3 {
    return normalize(vector1.clone().cross(vector2));
}

/**
 * Return true if the angle between vector1 and vector2 is acute (less than 90 degrees)
 */
export function areAcute(vector1: Vector3, vector2: Vector3): boolean {
    return vector1.dot(vector2) > 0;
}

/**
 * A more convenient way to create a vector that is the cross-product of two others
 * */
export function cross(a: Vector3, b: Vector3): Vector3 {
    return new Vector3().crossVectors(a, b);
}
