const colors_v1_8 = {
    'planner-background': '#262626',
    'planner-black': '#3a3e42',
    'planner-black-1': '#5b636a',
    'planner-black-2': '#0009',
    'planner-black-rich': '#2c2f33',
    'planner-grey': '#7c8893',
    'planner-grey-rich': '#4c545c',
    'planner-grey-1': '#99a4ac',
    'planner-grey-2': '#b5c0c6',
    'planner-grey-light': '#d2dcdf',
    'planner-grey-light-1': '#dfe6e8',
    'planner-grey-light-2': '#edf0f1',
    'planner-grey-body': '#fafafa',
    'planner-grey-inverted': '#9e9e9e',
    'planner-white': '#fff',
    'planner-blue': '#0054a6',
    'planner-blue-rich': '#003471',
    'planner-blue-vibrant': '#20c4f4',
    'planner-blue-button': '#108ccd',
    'planner-other-blue': '#1a65af',
    'planner-green': '#00b695',
    'planner-green-rich': '#0d9174',
    'planner-yellow': '#ffd059',
    'planner-highlight-yellow': '#ffe13a',
    'planner-orange': '#f47920',
    'planner-orange-rich': '#ea6301',
    'planner-red': '#c9252c',
    'planner-red-rich': '#ad040c',
    'planner-red-other': '#8a030a',

    'input-blue': '#369eff',
    'formus-outline': '#d2dcdf',
    'increment-grey': '#f0f2f5',
} as const;

export default colors_v1_8;
