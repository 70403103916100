<template>
    <div class="form-group">
        <div class="form-group-label">
            <label :for="inputId">{{ label }}</label>
            <div v-if="optional" class="form-group-optional">({{ optionalText }})</div>
        </div>
        <div class="form-group-content w-100">
            <slot></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
    export interface Props {
        label: string;
        optional?: boolean;
        optionalText?: string;
        size?: string;
        inputId?: string;
    }
    const props = withDefaults(defineProps<Props>(), {
        optional: false,
        optionalText: 'optional',
        size: 'fixed',
    });

    /**
     * inputs in modals need to be a bit smaller than inputs in forms
     */
    const inputMinWidth = props.size === 'fluid' ? '320px' : '380px';
    const inputWidth = props.size === 'fluid' ? '100%' : 'inherit';
    const inputCursor = props.inputId ? 'pointer' : 'inherit';
</script>

<style scoped>
    .form-group {
        display: flex;
        margin: 20px 0;
    }
    .form-group-label {
        font-size: 14px;
        padding-top: 10px;
        line-height: 1.45;
        color: var(--planner-grey);
        min-width: 160px;

        label {
            cursor: v-bind('inputCursor');
        }
    }
    .form-group-optional {
        color:rgb(var(--v-theme-primary));
        font-weight: 600;
        font-size: 14px;
        line-height: 1.45;
    }

    .form-group-content {
        min-width: v-bind('inputMinWidth');
        width: v-bind('inputWidth');
    }
</style>
