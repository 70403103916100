<template>
    <div class="app-notification rounded-lg" ref="notification">
        <h4>{{ title }}</h4>
        <p v-show="message" class="mb-0">{{ message }}</p>
        <div class="app-notification-triangle" />
    </div>
</template>

<script setup lang="ts">
    import { toRefs, computed, ref, onMounted } from 'vue';

    export interface Props {
        title: string;
        variant: 'success' | 'error' | 'info';
        message?: string;
        /**
         * If true, the notification will scroll into view on mount
         */
        animateTo?: boolean;
    }

    const props = defineProps<Props>();
    const { variant } = toRefs(props);

    const notification = ref<HTMLElement | null>(null);

    onMounted(() => {
        if (props.animateTo) {
            notification.value?.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }
    });

    const backgroundColour = computed(() => {
        switch (variant.value) {
            case 'success':
                return 'green';
            case 'error':
                return 'red';
            case 'info':
                return 'blue-button';
            default:
                return 'green';
        }
    });
    const background = computed(() => `var(--planner-${backgroundColour.value})`);
    const borderTop = computed(() => `12px solid var(--planner-${backgroundColour.value})`);
</script>

<style scoped>
    h4 {
        font-size: 21px;
        line-height: 34px;
        font-weight: 400;
    }

    p {
        color: #fff;
        font-size: 14px;
        line-height: 23px;
        margin: 0 0 40px;
    }

    .app-notification {
        width: 100%;
        padding: 20px;
        background: v-bind('background');
        color: #fff;
        border-radius: 2px;
        position: relative;
        box-shadow: 0 10px 0 rgba(0, 0, 0, 0.02);
        margin-bottom: 40px;
    }

    .app-notification-triangle {
        width: 0;
        position: absolute;
        bottom: -10px;
        left: 49%;
        border-top: v-bind('borderTop');
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
    }
</style>
