<template>
    <v-tooltip content-class="app-tooltip bg-white text-on-surface pa-5 elevation-3">
        <slot></slot>
    </v-tooltip>
</template>

<script setup lang="ts">
</script>

<style lang="scss">
    .app-tooltip :deep(.v-tooltip__content) {
        border-radius: 4px;
        box-shadow: 0px 2px 5.2px 0px rgba(0, 0, 0, 0.10) !important;
    }
</style>
