<template>
    <div class="border-t pa-2 pl-0 mr-4">
        <div class="text-secondary text-paragraph-sm-caps">{{ props.label }}</div>

        <slot v-if="hasSlot('default')"></slot>
        <div v-else class="text-secondary-text text-subtitle-1">{{ props.value }}</div>
    </div>
</template>

<script setup lang="ts">
    import { useSlots } from 'vue';

    export interface Props {
        label: string;
        value?: string;
    }

    const props = defineProps<Props>();

    const slots = useSlots();
    const hasSlot = (name: string) => {
        return !!slots[name];
    };
</script>

<style scoped>
    .border-t {
        border-color: rgb(var(--v-theme-surface-light)) !important;
    }
</style>
