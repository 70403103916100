import type { ThemeDefinition } from 'vuetify';

export const formusLightTheme: ThemeDefinition = {
    dark: false,
    colors: {
        background: '#F0F2F5',

        surface: '#ffffff',
        'on-surface': '#262626',
        'surface-light': '#F8F8F8',

        primary: '#369eff',
        secondary: '#7C8893',

        error: '#C9252C',
        info: '#369eff',
        success: '#4caf50',
        warning: '#fb8c00',

        'dark-blue': '#152130',

        green: '#00B695',
        'green-hover': '#0D9174',

        'grey-light': '#D9D9D9',
        'grey-light-1': '#d2dcdf',
        'planner-surface': '#E0E0E0',
        'on-planner-surface': '#152130',
        'grey-border': '#C1C7CE',
        'on-increment-grey': '#d2dcdf',

        orange: '#F47920',
        'orange-hover': '#EA6301',

        yellow: '#ffd059',

        red: '#C9252C',
        'red-hover': '#AD040C',

        'secondary-text': '#3D4D5C',
    },
    variables: {
        // We can add CSS variables here (See 'https://developer.mozilla.org/en-US/docs/Web/CSS/--*')
        'v-text-field-border-radius': '22px',
        'v-text-field-border-color': 'rgb(var(--v-theme-grey-light-1))',
    },
} as const;
