import { Vector3 } from 'three';
import { normalize } from '@/formus/geometry/vector3';

export function projectedComponent(vector: Vector3, direction: Vector3): number {
    return normalize(direction).dot(vector);
}

export function projectOrthogonal(vector: Vector3, normal: Vector3): Vector3 {
    normal = normalize(normal);
    return vector.clone().sub(normal.multiplyScalar(projectedComponent(vector, normal)));
}

export function projectOrthogonalUnit(vector: Vector3, normal: Vector3): Vector3 {
    return normalize(projectOrthogonal(vector, normal));
}
