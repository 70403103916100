import { defineStore } from 'pinia';
import { makeDataState, makeDataStateGetters, withStateLoading } from '@/util/dataState';
import type { CurrentUserState } from '@/app/userStore/types';
import { HipRoleNames } from '@/app/userStore/types';
import { getCurrentUser } from '@/api/user/me';
import { taggedLogger } from '@/util';

const logger = taggedLogger('user-store');

export const useUserStore = defineStore('user-store', {
    state: (): CurrentUserState => ({
        user: null,
        ...makeDataState(),
    }),
    getters: {
        ...makeDataStateGetters(),
        userRoles(): HipRoleNames[] {
            return this.user?.roles.map((r) => {return r.name}) ?? [];
        },
        role(): HipRoleNames | null {
            if (this.userRoles.length == 0) {
                return null;
            }
            // Only return the first role the user possess.
            // In theory there should only be 1.
            return this.userRoles[0];
        },
        isAdmin(): boolean {
            return this.userRoles.includes(HipRoleNames.Admin);
        },
        isOrgAdmin(): boolean {
            return this.userRoles.includes(HipRoleNames.OrgAdmin);
        },
        isSurgeon(): boolean {
            return this.userRoles.includes(HipRoleNames.Surgeon);
        },
        isSurgeonAssist(): boolean {
            return this.userRoles.includes(HipRoleNames.SurgeonAssist);
        },
        isSales(): boolean {
            return this.userRoles.includes(HipRoleNames.Sales);
        },
        userId(): string {
            if (this.user !== null) {
                return this.user.id.toString();
            }
            return '';
        }
    },
    actions: {
        async load(): Promise<void> {
            await withStateLoading(this, async () => {
                this.user = await getCurrentUser();
            });
        },
        clear(): void {
            logger.debug('Clearing current user information...')
            this.$reset()
        }
    },
});
