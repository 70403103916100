<template>
    <v-slider
        v-model="model"
        :max="tickCount - 1"
        step=1
        color="primary"
        show-ticks="always"
        tick-size=8
        track-size=1
        track-color="grey-light-1"
        thumb-size=12
        hide-details />
</template>

<!--suppress CssUnusedSymbol -->
<style scoped>
    :deep(.v-slider-track__tick) {
        background-color: var(--v-theme-grey-light-1);
        opacity: 0;
    }

    :deep(.v-slider-thumb__surface),
    :deep(.v-slider-track__tick--filled),
    :deep(.v-slider-track__fill) {
        background-color: var(--input-blue);
        opacity: 1;
    }

    :deep(.v-slider-track__background) {
        background-color: var(--v-theme-grey-light-1);
        opacity: 1;
    }

    :deep(.v-slider-track__tick) {
        border-radius: 4px;
    }

    :deep(.v-slider-track__tick--first) {
        margin-inline-start: 0 !important;
    }

    :deep(.v-slider-track__tick--last) {
        margin-inline-start: 100% !important;
    }
</style>

<script setup lang="ts">
    defineProps<{ tickCount: number }>();
    const model = defineModel<number>();
</script>
