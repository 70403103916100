<template>
    <span class="text-heading-h5 text-secondary-text">
        <slot></slot>
    </span>
</template>

<style scoped>
    label {
        //font-size: 0.75rem !important;
        //letter-spacing: 0.0333333333em !important;
        //line-height: 1.25rem;
        //color: var(--planner-black-2);
    }
</style>
